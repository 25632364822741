import { SwitchIcon } from "./Icons/SwitchIcon"
import type { Token } from "src/types"

export const TokensExchangeRateView = ({
  token0,
  token1,
  price, // @dev assumes price formatted
  useInverse,
  onToggleInverse,
}: {
  token0: Token | null | undefined
  token1: Token | null | undefined
  price: string | null | undefined
  useInverse: boolean
  onToggleInverse: () => void
}) => {
  if (!token0 || !token1 || !price) return null

  return (
    <div
      onClick={onToggleInverse}
      className="flex cursor-pointer items-center space-x-1 text-sm text-white hover:opacity-70"
    >
      <SwitchIcon width={18} height={18} className="text-marginalOrange-500" />
      {!useInverse ? (
        <span>
          1 {token0?.symbol} = {price} {token1?.symbol}
        </span>
      ) : (
        <span>
          1 {token1?.symbol} = {price} {token0?.symbol}
        </span>
      )}
    </div>
  )
}
