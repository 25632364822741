export const InputContainer = ({ id, children }: { id: string; children: any }) => {
  return (
    <div
      id={id}
      className="flex items-center justify-between space-x-2 rounded border border-transparent bg-marginalBlack px-4 py-2 focus-within:border-marginalGray-600"
    >
      {children}
    </div>
  )
}
