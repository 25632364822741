const Bold = ({ children }: { children: React.ReactNode }) => {
  return <b className="text-marginalGray-100">{children}</b>
}

const TermsOfUse = () => {
  return (
    <div className="mx-auto flex w-full max-w-256 flex-col px-2 font-sans font-normal text-marginalGray-200">
      <h1 className="text-2xl font-bold text-marginalGray-100">Terms of Use</h1>
      <p className="mb-4 text-marginalGray-600">Last updated January 27, 2025</p>

      <p className="my-4">
        Marginal Finance Inc. (<Bold>"Marginal"</Bold>, <Bold>"we"</Bold>,{" "}
        <Bold>"us"</Bold> and <Bold>"our"</Bold>) provides the services herein (including
        but not limited to the Marginal Finance App) (the <Bold>"Services"</Bold>) for
        your use subject to the following terms of use (the <Bold>"Terms"</Bold>). We
        reserve the right to modify the Terms from time to time. If we make any changes to
        the Terms, we will change the "UPDATED" date above and will post the updated Terms
        on this page.
      </p>

      <p className="my-4">
        Your continued use of the Services constitutes your acknowledgement of, acceptance
        of, and agreement to the revised Terms. You agree to periodically visit this page
        to review the current Terms so you are aware of any revision to which you are
        bound. We will indicate at the top of this page the date these terms were last
        revised. If you do not agree to abide by these or any future Terms, do not use or
        access (or continue to use or access) the Services.
      </p>

      <p className="my-4">
        Additional terms and conditions may apply to certain Services provided by
        Marginal, and you agree that you shall be subject to any additional terms
        applicable to such Services that may be posted on our websites or otherwise made
        available to you from time to time. All such terms are hereby incorporated by
        reference into these Terms.
      </p>

      <p className="my-4">
        PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND EACH PROVISION.
        THESE TERMS CONTAIN A MANDATORY INDIVIDUAL ARBITRATION AND CLASS ACTION/JURY TRIAL
        WAIVER PROVISION THAT REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO
        RESOLVE DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS. THE SERVICES MAY
        CONTAIN CONTENT THAT IS INACCURATE, OBJECTIONABLE, INAPPROPRIATE FOR CHILDREN, OR
        OTHERWISE UNSUITED TO YOUR PURPOSE, AND YOU AGREE THAT MARGINAL SHALL NOT BE
        LIABLE FOR ANY DAMAGES YOU ALLEGE INCUR AS A RESULT OF ANY EXPOSURE TO SUCH
        CONTENT. YOU USE THE SERVICES AT YOUR OWN RISK.
      </p>

      <p className="my-4">
        USE OF THE SERVICES BY PERSONS WHO ARE CURRENTLY OR ORDINARILY LOCATED OR RESIDENT
        IN THE UNITED STATES OR ON ANY LIST OF PROHIBITED OR RESTRICTED PARTIES (INCLUDING
        BUT NOT LIMITED TO THE LISTS MAINTAINED BY THE UNITED NATIONS SECURITY COUNCIL,
        THE U.S. GOVERNMENT, THE EUROPEAN UNION OR ITS MEMBER STATES, OR OTHER APPLICABLE
        GOVERNMENT AUTHORITY) OR A CITIZEN OR ENTITY ORGANIZED OR RESIDENT IN A COUNTRY OR
        TERRITORY THAT IS THE SUBJECT OF COUNTRY-WIDE OR TERRITORY-WIDE SANCTIONS
        (INCLUDING WITHOUT LIMITATION CUBA, DEMOCRATIC PEOPLE’S REPUBLIC OF KOREA, THE
        CRIMEA, DONETSK, AND LUHANSK REGIONS, IRAN OR SYRIA) IS STRICTLY PROHIBITED,
        REGARDLESS OF THE USER’S IP ADDRESS. UTILIZING A VIRTUAL PRIVATE NETWORK OR OTHER
        METHOD TO CONCEAL A USER’S RESIDENCE IS ALSO STRICTLY PROHIBITED AND MAY RESULT IN
        PERMANENT BLOCKING OF USE OF THE SERVICES IN CONNECTION WITH BLOCKCHAIN ADDRESSES
        SUSPECTED OF BEING TIED TO A RESTRICTED RESIDENCE.
      </p>

      <ol className="list-decimal pl-6">
        <li id="jurisdiction-arbitration" className="mb-2 mt-8 text-xl font-bold">
          <a href="#jurisdiction-arbitration">Jurisdiction; arbitration</a>
        </li>
        <p className="my-4">
          You agree that the laws of Panama, without regard to the principles of conflict
          of laws, govern these Terms. You and we agree that any dispute arising out of or
          related to these Terms or the Services is personal to you and us and that any
          dispute will be resolved solely through individual action, and will not be
          brought as a class arbitration, class action, or any other type of
          representative proceeding. Except for disputes in which you or we seek
          injunctive or other equitable relief for the alleged unlawful use of
          intellectual property, you and we waive all rights to a jury trial and to have
          any dispute arising out of or related to these Terms or the Services resolved in
          court. Instead, either party may submit the dispute to binding arbitration
          administered by the Centro de Conciliación y Arbitraje de Panamá Centre
          ("CeCAP") or, under the limited circumstances set forth above, in court. All
          disputes submitted to the CeCAP will be resolved through confidential, binding
          arbitration before one arbitrator (the "Arbitrator"). The place of arbitration
          shall be Panama unless the parties agree otherwise and shall be conducted under
          CeCAP’s Arbitration Regulation (the "CeCAP Rules"). The language to be used in
          the arbitral proceedings shall be English. The most recent version of the CeCAP
          Rules are available on the CeCAP website and are hereby incorporated by
          reference. You either acknowledge and agree that you have read and understand
          the CeCAP Rules or waive your opportunity to read the CeCAP Rules and waive any
          claim that the CeCAP Rules are unfair or should not apply for any reason. The
          Arbitrator will have exclusive authority to make all procedural and substantive
          decisions regarding any dispute and to grant any remedy that would otherwise be
          available in court, including the power to determine the question of
          arbitrability. The Arbitrator may conduct only an individual arbitration and may
          not consolidate more than one individual’s claims, preside over any type of
          class or representative proceeding or preside over any proceeding involving more
          than one individual. The Arbitrator, Marginal, and you will maintain the
          confidentiality of any arbitration proceedings, judgments and awards, including,
          but not limited to, all information gathered, prepared, and presented for
          purposes of the arbitration or related to the dispute(s) therein. The Arbitrator
          will have the authority to make appropriate rulings to safeguard confidentiality
          unless the law provides to the contrary. The duty of confidentiality does not
          apply to the extent that disclosure is necessary to prepare for or conduct the
          arbitration hearing on the merits, in connection with a court application for a
          preliminary remedy or in connection with a judicial challenge to an arbitration
          award or its enforcement, or to the extent that disclosure is otherwise required
          by law or judicial decision. You and Marginal agree that for any arbitration you
          initiate, you will pay the filing fee and all other CeCAP fees and costs. For
          any arbitration initiated by us, we will pay all CeCAP fees and costs. You and
          we agree that the courts of Panama have exclusive jurisdiction over the
          enforcement of an arbitration award. Any claim arising out of or related to
          these Terms or the Services must be filed within one year after such claim
          arose; otherwise, the claim is permanently barred.
        </p>

        <li id="eligibility" className="mb-2 mt-8 text-xl font-bold">
          <a href="#eligibility">Eligibility</a>
        </li>
        <p className="my-4">
          Age: The Services are intended solely for users of sound mind who are 18 years
          of age or older. Any registration by, use of or access to the Services by anyone
          under 18 is unauthorized and in violation of these Terms. By using the Services,
          you represent and warrant that you are 18 years of age or older.
        </p>
        <p className="my-4">
          Business Entities: If you are a business entity, you are duly organized, validly
          existing, and in good standing in the jurisdiction in which you are organized,
          and additionally have all necessary authorization for a business entity of your
          type to carry on your business as you are so now doing.
        </p>
        <p className="my-4">
          Knowledge: You are knowledgeable, sophisticated, and experienced in using and
          evaluating blockchain-related technologies. You have conducted your own
          independent investigation and analysis of the Marginal ecosystem and the other
          matters contemplated by these Terms, and have not relied upon any statement,
          omission, information, representation or warranty, express or implied, written
          or oral, made by or on behalf of us in connection therewith. The sites are only
          being provided as an aid to your own independent evaluation and research of the
          Marginal ecosystem and no warranty or representation is being made as to the
          accuracy or completeness of information on the sites.
        </p>

        <li
          id="certain-uses-and-risks-of-blockchain-technology"
          className="mb-2 mt-8 text-xl font-bold"
        >
          <a href="#certain-uses-and-risks-of-blockchain-technology">
            Certain uses and risks of blockchain technology
          </a>
        </li>
        <p className="my-4">
          Cryptography: Code cracking or other technical advances may present risks,
          including the theft, loss or inaccessibility of tokens or other cryptographic
          assets.
        </p>
        <p className="my-4">
          Third-Party Software Dependencies: The site may utilize APIs, middleware and
          servers of third parties, and we do not guarantee the continued accuracy,
          maintenance, availability or security of any of the such dependencies.
        </p>
        <p className="my-4">
          Knowledge: You are knowledgeable, sophisticated, and experienced in using and
          evaluating blockchain-related technologies. You have conducted your own
          independent investigation and analysis of the Marginal ecosystem and the other
          matters contemplated by these Terms, and have not relied upon any statement,
          omission, information, representation or warranty, express or implied, written
          or oral, made by or on behalf of us in connection therewith. The sites are only
          being provided as an aid to your own independent evaluation and research of the
          Marginal ecosystem and no warranty or representation is being made as to the
          accuracy or completeness of information on the sites.
        </p>
        <p className="my-4">
          Keys: Users are solely responsible for the safekeeping of their private keys. We
          will not be able to restore or issue any refund in respect of property lost or
          frozen due to loss of private keys or otherwise, as well as accidental
          transactions.
        </p>
        <p className="my-4">
          Regulatory Environment: We may be adversely impacted by one or more regulatory
          or legal claims, actions, inquiries, suits, investigations, fines or judgments,
          which could impede or limit the ability of you to continue to use the Services.
        </p>
        <p className="my-4">
          Experimental Blockchain Technology: We and other third parties utilize
          experimental cryptographic technologies. You acknowledge and agree that such
          technologies are experimental, speculative, and novel, and that therefore there
          is uncertainty regarding the effects, operations, risks thereof and the
          application of existing law thereto.
        </p>
        <p className="my-4">
          Smart Contracts: Smart contracts typically cannot be modified, or can only be
          modified in limited ways. In the event that Marginal ecosystem smart contracts
          or blockchain systems are adversely affected by defects, malfunctions, bugs,
          hacking, theft, attacks, negligent coding or design choices, or changes to the
          applicable protocol rules, you may be exposed to a risk of total loss and/or
          forfeiture of all related digital assets. We assume no liability or
          responsibility for any of the foregoing matters.
        </p>
        <p className="my-4">
          Forks: All tokens may be subject to "forks." We may not be able to anticipate,
          control or influence the occurrence or outcome of forks, and do not assume any
          liability, risk, or obligation in connection therewith. We do not assume any
          responsibility to notify a user of threatened, pending, or completed forks. We
          will respond (or refrain from responding) to any forks in such manner as we
          determine in our sole discretion, and shall not have any duty, liability, or
          obligation to a user if such response (or lack of such response) acts to a
          user's detriment. Each user assumes full responsibility to independently remain
          informed about possible forks, and to manage their own risks and interests in
          connection therewith.
        </p>

        <li id="taxes" className="mb-2 mt-8 text-xl font-bold">
          <a href="#taxes">Taxes</a>
        </li>
        <p className="my-4">
          Cryptocurrency-related tax law may vary by jurisdiction and may be adversarial
          to you. We have undertaken no due diligence or investigation into such tax
          consequences, and assume no liability or obligation to optimize the tax
          consequences to any person. We are not and should not be construed to be
          providing any tax advice.
        </p>

        <li id="liabilities" className="mb-2 mt-8 text-xl font-bold">
          <a href="#liabilities">Liabilities</a>
        </li>

        <h3 id="disclaimer-of-warranties" className="mb-2 mt-4 text-xl font-bold">
          <a href="#disclaimer-of-warranties">5.1 Disclaimer of warranties</a>
        </h3>
        <p className="my-4">
          TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, THE SERVICES, MARGINAL
          MATERIALS AND ANY PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF OF
          MARGINAL ARE OFFERED ON AN "AS IS" AND "AS AVAILABLE" BASIS, AND MARGINAL
          EXPRESSLY DISCLAIMS, AND YOU WAIVE, ANY AND ALL OTHER WARRANTIES OF ANY KIND,
          WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT OR
          WARRANTIES ARISING FROM COURSE OF PERFORMANCE, COURSE OF DEALING OR USAGE IN
          TRADE. WITHOUT LIMITING THE FOREGOING, MARGINAL DOES NOT REPRESENT OR WARRANT
          THAT THE WEBSITES, THE SERVICES OR MARGINAL MATERIALS ARE ACCURATE, COMPLETE,
          RELIABLE, CURRENT, ERROR-FREE, OR FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
          MARGINAL DOES NOT GUARANTEE THAT ANY ACTION WILL BE EXECUTED, ACCEPTED, RECORDED
          OR REMAIN OPEN. EXCEPT FOR THE EXPRESS STATEMENTS, AGREEMENTS AND RULES SET
          FORTH IN THESE TERMS, YOU HEREBY ACKNOWLEDGE AND AGREE THAT YOU HAVE NOT RELIED
          UPON ANY OTHER STATEMENT OR AGREEMENT, WHETHER WRITTEN OR ORAL, WITH RESPECT TO
          YOUR USE AND ACCESS OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, YOU HEREBY
          UNDERSTAND AND AGREE THAT MARGINAL WILL NOT BE LIABLE FOR ANY LOSSES OR DAMAGES
          ARISING OUT OF OR RELATING TO: (A) ANY INACCURACY, DEFECT OR OMISSION OF DIGITAL
          ASSETS PRICE DATA, (B) ANY ERROR OR DELAY IN THE TRANSMISSION OF SUCH DATA, (C)
          INTERRUPTION IN ANY SUCH DATA, (D) REGULAR OR UNSCHEDULED MAINTENANCE CARRIED
          OUT BY MARGINAL AND SERVICE INTERRUPTION AND CHANGE RESULTING FROM SUCH
          MAINTENANCE, (E) ANY DAMAGES INCURRED BY OTHER USERS’ ACTIONS, OMISSIONS OR
          VIOLATION OF THESE TERMS, (F) ANY DAMAGE CAUSED BY ILLEGAL ACTIONS OF OTHER
          THIRD PARTIES OR ACTIONS WITHOUT AUTHORIZATION BY MARGINAL; (G) ANY CHANGE IN
          VALUE OF ANY CRYPTOASSET, (H) ANY CHANGE IN LAW, REGULATION, OR POLICY (I)
          SERVER FAILURE OR DATA LOSS (J) THE MALFUNCTION, UNEXPECTED FUNCTION OR
          UNINTENDED FUNCTION OF THE BLOCKCHAIN, ANY COMPUTER OR CRYPTOASSET NETWORK
          (INCLUDING ANY WALLET PROVIDER), INCLUDING WITHOUT LIMITATION LOSSES ASSOCIATED
          WITH NETWORK FORKS, DOUBLE-SPEND ATTACKS, GOVERNANCE DISPUTES, REPLAY ATTACKS,
          SYBIL ATTACKS, 51% ATTACKS, MINING DIFFICULTY, HACKING, CHANGES IN CRYPTOGRAPHY
          OR CONSENSUS RULES, OR CYBERSECURITY BREACHES; AND (K) OTHER EXEMPTIONS
          MENTIONED IN DISCLAIMERS AND PLATFORM RULES ISSUED BY MARGINAL.
        </p>
        <p className="my-4">
          THE DISCLAIMER OF IMPLIED WARRANTIES CONTAINED HEREIN MAY NOT APPLY IF AND TO
          THE EXTENT IT IS PROHIBITED BY APPLICABLE LAW OF THE JURISDICTION IN WHICH YOU
          RESIDE.
        </p>

        <h3
          id="disclaimer-of-damages-and-limitation-of-liability"
          className="mb-2 mt-4 text-xl font-bold"
        >
          <a href="#disclaimer-of-damages-and-limitation-of-liability">
            5.2 Disclaimer of damages and limitation of liability
          </a>
        </h3>
        <p className="my-4">
          TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL MARGINAL,
          ITS AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS,
          EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS BE
          LIABLE FOR ANY INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, CONSEQUENTIAL OR SIMILAR
          DAMAGES OR LIABILITIES WHATSOEVER (INCLUDING, WITHOUT LIMITATION, DAMAGES FOR
          LOSS OF DATA, INFORMATION, REVENUE, PROFITS OR OTHER BUSINESSES OR FINANCIAL
          BENEFITS) ARISING OUT OF THE SERVICES, ANY PERFORMANCE OR NON-PERFORMANCE OF THE
          SERVICES, OR ANY OTHER PRODUCT, SERVICE OR OTHER ITEM PROVIDED BY OR ON BEHALF
          OF MARGINAL AND ITS AFFILIATES, WHETHER UNDER CONTRACT, STATUTE, STRICT
          LIABILITY OR OTHER THEORY EVEN IF MARGINAL HAS BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES EXCEPT TO THE EXTENT OF A FINAL JUDICIAL DETERMINATION THAT SUCH
          DAMAGES WERE A RESULT OF MARGINAL’S GROSS NEGLIGENCE, FRAUD, WILLFUL MISCONDUCT
          OR INTENTIONAL VIOLATION OF LAW. SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION
          OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION
          MAY NOT APPLY TO YOU.
        </p>
        <p className="my-4">
          NOTWITHSTANDING THE FOREGOING, IN NO EVENT WILL THE LIABILITY OF MARGINAL, ITS
          AFFILIATES AND THEIR RESPECTIVE SHAREHOLDERS, MEMBERS, DIRECTORS, OFFICERS,
          EMPLOYEES, ATTORNEYS, AGENTS, REPRESENTATIVES, SUPPLIERS OR CONTRACTORS ARISING
          OUT OF SERVICES OFFERED BY OR ON BEHALF OF MARGINAL AND ITS AFFILIATES, ANY
          PERFORMANCE OR NON-PERFORMANCE OF THE SERVICES, OR ANY OTHER PRODUCT, SERVICE OR
          OTHER ITEM, WHETHER UNDER CONTRACT, STATUTE, STRICT LIABILITY OR OTHER THEORY,
          EXCEED THE AMOUNT OF US$25.
        </p>

        <h3 id="force-majeure" className="mb-2 mt-4 text-xl font-bold">
          <a href="#force-majeure">5.3 Force majeure</a>
        </h3>
        <p className="my-4">
          We shall not incur any penalty or liability for not performing any act or
          fulfilling any duty or obligation hereunder or in connection with the matters
          contemplated hereby by reason of any occurrence that is not within our control
          (including any provision of any present or future law or regulation or any act
          of any governmental authority, any act of God or war or terrorism, any epidemic
          or pandemic, or the disruption, unavailability, or malfunction of the Internet,
          any other pertinent electronic network, any blockchains or smart contracts, or
          any aspect thereof, or any consensus attack, or hack, or denial-of-service or
          other attack on the foregoing or any aspect thereof, or on the other networks,
          software, and infrastructure that enables us to provide these sites). It is
          understood that we shall use commercially and financially reasonable efforts, to
          resume performance as soon as reasonably practicable under the circumstances.
        </p>

        <h3 id="no-financial-advice" className="mb-2 mt-4 text-xl font-bold">
          <a href="#no-financial-advice">5.4 No financial advice</a>
        </h3>
        <p className="my-4">
          Marginal is not your broker, intermediary, agent, or advisor and has no
          fiduciary relationship or obligation to you in connection with any trades or
          other decisions or activities effected by you using the Services. No
          communication or information provided to you by Marginal is intended as, or
          shall be considered or construed as, investment advice, financial advice,
          trading advice, or any other sort of advice. You are solely responsible for
          determining whether any investment, investment strategy or related transaction
          is appropriate for you according to your personal investment objectives,
          financial circumstances and risk tolerance, and you shall be solely responsible
          for any loss or liability therefrom. You should consult legal or tax
          professionals regarding your specific situation. Marginal does not recommend
          that any Digital Asset should be bought, earned, sold, or held by you. Before
          making the decision to buy, sell or hold any Digital Asset, you should conduct
          your own due diligence and consult your financial advisors prior to making any
          investment decision. Marginal will not be held responsible for the decisions you
          make to buy, sell, or hold Digital Asset based on the information provided by
          Marginal.
        </p>

        <h3 id="indemnification" className="mb-2 mt-4 text-xl font-bold">
          <a href="#indemnification">5.5 Indemnification</a>
        </h3>
        <p className="my-4">
          Marginal is not your broker, intermediary, agent, or advisor and has no
          fiduciary relationship or obligation to you in connection with any trades or
          other decisions or activities effected by you using the Services. No
          communication or information provided to you by Marginal is intended as, or
          shall be considered or construed as, investment advice, financial advice,
          trading advice, or any other sort of advice. You are solely responsible for
          determining whether any investment, investment strategy or related transaction
          is appropriate for you according to your personal investment objectives,
          financial circumstances and risk tolerance, and you shall be solely responsible
          for any loss or liability therefrom. You should consult legal or tax
          professionals regarding your specific situation. Marginal does not recommend
          that any Digital Asset should be bought, earned, sold, or held by you. Before
          making the decision to buy, sell or hold any Digital Asset, you should conduct
          your own due diligence and consult your financial advisors prior to making any
          investment decision. Marginal will not be held responsible for the decisions you
          make to buy, sell, or hold Digital Asset based on the information provided by
          Marginal.
        </p>

        <li id="access-and-use-of-the-services" className="mb-2 mt-8 text-xl font-bold">
          <a href="#access-and-use-of-the-services">Access and use of the services</a>
        </li>
        <p className="my-4">
          Registration is not required to view content on the Services, but even
          unregistered users are bound by these Terms. Subject to the terms and conditions
          of these Terms, you are hereby granted a non-exclusive, limited,
          non-transferable, non-sublicensable freely revocable license to use the Services
          solely for your own internal purposes and in accordance with the documentation
          for the Services. Marginal reserves all rights not expressly granted herein in
          the Services and the Marginal Content (as defined below). Marginal may terminate
          this license at any time for any reason or no reason.
        </p>

        <li id="copyright-repeat-infringement" className="mb-2 mt-8 text-xl font-bold">
          <a href="#copyright-repeat-infringement">Copyright/repeat infringement</a>
        </li>
        <p className="my-4">
          Marginal will respond to reports of copyright infringement, allegations
          concerning the unauthorized use of a copyrighted video, image, or other file
          uploaded through our media hosting services, or pages containing links to
          allegedly infringing materials in its sole discretion.
        </p>
        <p className="my-4">
          You agree not to take any action that infringes or violates someone else’s
          intellectual property rights or otherwise violates applicable laws. If you post
          content that infringes someone else’s intellectual property rights, such as
          copyrights or trademarks, your IP address may be banned and your content removed
          by us. If you believe your IP address was banned by mistake, please contact us.
        </p>

        <li id="modifications-to-services" className="mb-2 mt-8 text-xl font-bold">
          <a href="#modifications-to-services">Modifications to services</a>
        </li>
        <p className="my-4">
          Marginal reserves the right to modify or discontinue, temporarily or
          permanently, the Services (or any part thereof) or any user account with or
          without notice. You agree that Marginal shall not be liable to you or to any
          third party for any modification, suspension or discontinuance of the Services.
        </p>
      </ol>
    </div>
  )
}

export default TermsOfUse
