import { Token } from "src/types"
import { isAddress } from "viem"

export const validateMintQuoteParams = (
  pool: any,
  marginToken: Token | null,
  debtToken: Token | null,
  input: string | undefined,
  leverage: number,
): boolean => {
  if (!pool || !marginToken || !debtToken || !input || !leverage) {
    return false
  }
  if (!input || Number(input) === 0) {
    return false
  }
  if (!pool?.maintenance) {
    return false
  }
  if (!pool?.oracleAddress) {
    return false
  }
  if (!isAddress(pool?.oracleAddress)) {
    return false
  }
  if (!pool?.token0?.address || !pool?.token1?.address) {
    return false
  }
  if (!isAddress(pool?.token0?.address) || !isAddress(pool?.token1?.address)) {
    return false
  }
  if (!marginToken?.address || !debtToken?.address) {
    return false
  }
  if (!isAddress(marginToken?.address) || !isAddress(debtToken?.address)) {
    return false
  }
  return true
}
