import { Token } from "src/types"
import { zeroAddress } from "viem"
import { ChainId } from "./chains/chains"
import WethLogo from "src/assets/logos/weth.svg"
import MarginalLogo from "src/assets/logos/marginal.svg"

export const BERA = {
  chainId: 80094,
  address: zeroAddress,
  name: "Bera",
  symbol: "BERA",
  decimals: 18,
  imgUrl: "https://docs.berachain.com/assets/BERA.png",
  priorityRank: 1,
}

export const WBERA = {
  chainId: 80094,
  address: "0x6969696969696969696969696969696969696969",
  name: "Wrapped Bera",
  symbol: "WBERA",
  decimals: 18,
  imgUrl: "https://static.kodiak.finance/tokens/wbera.png",
  priorityRank: 2,
}

export const YEET = {
  chainId: 80094,
  address: "0x08A38Caa631DE329FF2DAD1656CE789F31AF3142",
  name: "YEET",
  symbol: "YEET",
  decimals: 18,
  imgUrl:
    "https://dd.dexscreener.com/ds-data/tokens/berachain/0x08a38caa631de329ff2dad1656ce789f31af3142.png?size=lg&key=8385e9",
  priorityRank: 3,
}

export const WETH = {
  chainId: 80094,
  name: "WETH",
  symbol: "WETH",
  address: "0x2f6f07cdcf3588944bf4c42ac74ff24bf56e7590",
  decimals: 18,
  imgUrl: WethLogo,
  priorityRank: 3,
}

export const WBTC = {
  chainId: 80094,
  name: "Wrapped BTC",
  symbol: "WBTC",
  address: "0x0555E30da8f98308EdB960aa94C0Db47230d2B9c",
  decimals: 8,
  imgUrl: "https://s2.coinmarketcap.com/static/img/coins/64x64/3717.png",
  priorityRank: 3,
}

export const HONEY = {
  chainId: 80094,
  name: "Honey",
  symbol: "HONEY",
  address: "0xFCBD14DC51f0A4d49d5E53C2E0950e0bC26d0Dce",
  decimals: 18,
  imgUrl: "https://berascan.com/token/images/honeybera_32.png",
  priorityRank: 2,
}

export const MARG = {
  chainId: 80094,
  name: "Marginal DAO Token",
  symbol: "MARG",
  address: "0x0C7809991DcE7F06D4C5E327fC2130b3f40BA114",
  decimals: 18,
  imgUrl: MarginalLogo,
  priorityRank: 2,
}

export const iBGT = {
  chainId: 80094,
  name: "Infrared BGT",
  symbol: "iBGT",
  address: "0xac03caba51e17c86c921e1f6cbfbdc91f8bb2e6b",
  decimals: 18,
  imgUrl: "https://assets.coingecko.com/coins/images/54614/standard/ibgt.jpg?1740718302",
  priorityRank: 5,
}

export const iBERA = {
  chainId: 80094,
  name: "Infrared Bera",
  symbol: "iBERA",
  address: "0x9b6761bf2397Bb5a6624a856cC84A3A14Dcd3fe5",
  decimals: 18,
  imgUrl: "https://assets.coingecko.com/coins/images/54671/standard/ibera.jpg?1740966414",
  priorityRank: 5,
}

export const WRAPPED_GAS_TOKEN_MAP: { [chainId: number]: Token } = {
  [ChainId.BERACHAIN]: WBERA,
  // [ChainId.BASE]: BASE_WETH,
  // [ChainId.SEPOLIA]: SEPOLIA_WETH,
  // [ChainId.BERACHAIN_BARTIO]: BERACHAIN_BARTIO_WBERA,
  // [ChainId.ETH]: MAINNET_WETH,
}

export const GAS_TOKEN_MAP: { [chainId: number]: Token } = {
  [ChainId.BERACHAIN]: BERA,
  // [ChainId.BASE]: ETH,
  // [ChainId.SEPOLIA]: ETH,
  // [ChainId.BERACHAIN_BARTIO]: BERA,
  // [ChainId.ETH]: ETH,
}

export const MARGINAL_DAO_TOKEN = "0x0C7809991DcE7F06D4C5E327fC2130b3f40BA114"
