import _, { isUndefined } from "lodash"
import { toast } from "react-toastify"
import { Toast } from "src/components/ui/Toast"
import { Link } from "react-router-dom"
import { ArrowOutgoingIcon } from "src/components/Icons/ArrowOutgoingIcon"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import { useTransactionsData } from "src/providers/TransactionsProvider"
import { useAppDispatch } from "src/state/hooks"
import { useMemo, useEffect } from "react"
import { InputContainer } from "src/components/ui/InputContainer"
import { SwapInput } from "src/pages/swap/components/SwapInput"
import { useSwapState, useSwapActionHandlers, useSwapStatus } from "src/state/swap/hooks"
import { usePoolsContextData } from "src/providers/PoolsProvider"
import { useDerivePoolsFromTokens } from "src/hooks/useDerivePoolsFromTokens"
import { type PoolData } from "src/types"
import { TokenSelector } from "src/pages/trade/components/TokenSelector"
import { SwapOutput } from "src/pages/swap/components/SwapOutput"
import { useAccount } from "wagmi"
import { maxUint256, zeroAddress, Address } from "viem"
import { useErc20TokenBalances } from "src/hooks/useErc20TokenBalances"
import { validateExactInputSingleParams } from "src/pages/swap/helpers/validateExactInputSingleParams"
import { constructExactInputSingleParams } from "src/pages/swap/helpers/constructExactInputSingleParams"
import { trimTrailingZeroes } from "src/utils/trimTrailingZeroes"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { V1_ROUTER_ADDRESS, V1_QUOTER_ADDRESS } from "src/constants/addresses"
import { isBlank } from "src/utils/isBlank"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { ConfirmSwapButton } from "src/pages/swap/components/ConfirmSwapButton"
import { waitForTransactionReceipt } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { isTransactionReceiptError } from "src/utils/isTransactionReceiptError"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
// import { ConfirmSwapModal } from "src/pages/swap/components/ConfirmSwapModal"
import { useSettingsToggle } from "src/hooks/useSettingsToggle"
import { useSettingsState } from "src/state/settings/hooks"
import { SlippageButton } from "src/components/Settings/SlippageButton"
import { useModalIsOpen, useOpenModal, useCloseModal } from "src/state/application/hooks"
import { ApplicationModal } from "src/state/application/reducer"
import { Token } from "src/types"
import { isWrappedGasToken } from "src/utils/isWrappedGasToken"
import { isGasToken } from "src/utils/isGasToken"
import { BERA, WRAPPED_GAS_TOKEN_MAP } from "src/constants/tokens"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { SwapDetailList } from "src/pages/swap/components/SwapDetailList"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { getValidAddress } from "src/utils/getValidAddress"
import { useInversePrice } from "src/hooks/useInversePrice"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { formatUSDCurrency } from "src/utils/formatUSDCurrency"
import { exactInputSingle } from "src/utils/actions/exactInputSingle"
import { fetchExactInputSingleQuote } from "src/state/swap/hooks"
import { resetSwapQuote } from "src/state/swap/reducer"
import { getPriceImpactIndicatorColor } from "src/pages/trade/helpers/getPriceImpactIndicatorColor"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { resolvePoolTokenPrice } from "src/utils/pools/resolvePoolTokenPrice"
import { deriveSwapQuoteData } from "src/pages/swap/helpers/deriveSwapQuoteData"
import { useTransactionModal } from "src/hooks/useTransactionModal"
import { useMarginalPoolsState } from "src/pages/pools/hooks/useMarginalPoolsState"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { useApplicationState } from "src/state/application/hooks"
import { useTokenList } from "src/hooks/useTokenList"
import { getTokenByAddress } from "src/constants/tokenList"
import { getSwapOutputList } from "src/pages/swap/helpers/getSwapOutputList"
import { TokenSelectorModal } from "src/components/ui/TokenSelectorModal"
import { sortPoolsByLiquidityAvailable } from "src/utils/pools/sortPoolsByLiquidityAvailable"
import { SwitchButton } from "./components/SwitchButton"

export default function Swap() {
  const dispatch = useAppDispatch()
  const { refetch: refetchTransactions } = useTransactionsData()
  const { address } = useAccount()
  const { chainId } = useApplicationState()
  const { inputValue, swapToken, inputToken, outputToken, swapQuote } = useSwapState()
  const {
    onUserInput,
    onSelectSwapToken,
    onSelectInputToken,
    onSelectOutputToken,
    onResetSwapState,
  } = useSwapActionHandlers()
  const { showSettings, onOpenSettings, onCloseSettings } = useSettingsToggle()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { isInputValid, isInputTokenValid, isOutputTokenValid } = useSwapStatus()
  const [useInverse, onToggleInverse] = useInversePrice()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)

  const isInputModalOpen = useModalIsOpen(ApplicationModal.SWAP_INPUT_LIST)
  const isOutputModalOpen = useModalIsOpen(ApplicationModal.SWAP_OUTPUT_LIST)
  const openInputTokenListModal = useOpenModal(ApplicationModal.SWAP_INPUT_LIST)
  const openOutputTokenListModal = useOpenModal(ApplicationModal.SWAP_OUTPUT_LIST)
  const closeInputModal = useCloseModal()
  const closeOutputModal = useCloseModal()

  const WRAPPED_GAS_TOKEN = WRAPPED_GAS_TOKEN_MAP[chainId]
  // const GAS_TOKEN = GAS_TOKEN_MAP[chainId]

  const isInputGasToken = isGasToken(inputToken)

  const { pools, tokensFromPools: tokenOptions } = usePoolsContextData()
  const tokenList = useTokenList(chainId, tokenOptions)
  const tokenListWithBera = [BERA, ...tokenList]
  const outputList = getSwapOutputList(pools, swapToken, tokenListWithBera)
  const derivedPools: PoolData[] = useDerivePoolsFromTokens(swapToken, outputToken, pools)
  const { poolStates } = useMarginalPoolsState(derivedPools, chainId)
  const rankedPools = sortPoolsByLiquidityAvailable(Object.values(poolStates))
  const selectedPool = rankedPools?.[0]?.pool
  const leverageCap = convertMaintenanceToLeverage(selectedPool?.maintenance)

  const token0 =
    getTokenByAddress(selectedPool?.token0?.address, chainId) ?? selectedPool?.token0
  const token1 =
    getTokenByAddress(selectedPool?.token1?.address, chainId) ?? selectedPool?.token1
  const zeroForOne =
    getValidAddress(swapToken?.address) === getValidAddress(selectedPool?.token0?.address)

  const { quoteToken } = extrapolateTokenPair(
    selectedPool?.token0,
    selectedPool?.token1,
    chainId,
  )
  const { sqrtPriceX96, poolPrice, token0PriceInUSD, token1PriceInUSD } =
    useLivePoolPrices({
      chainId,
      selectedPool,
      zeroForOne,
      useInverse,
      quoteToken,
    })

  const inputTokenUSDPrice = resolvePoolTokenPrice(
    inputToken,
    selectedPool,
    token0PriceInUSD,
    token1PriceInUSD,
  )
  const outputTokenUSDPrice = resolvePoolTokenPrice(
    outputToken,
    selectedPool,
    token0PriceInUSD,
    token1PriceInUSD,
  )

  const { allowance, fetchAllowance } = useErc20TokenAllowance(
    inputToken?.address as Address,
    Number(inputToken?.decimals),
    address,
    V1_ROUTER_ADDRESS[chainId],
    chainId,
  )

  useEffect(() => {
    if (inputToken && address) {
      ;(async () => {
        await fetchAllowance()
      })()
    }
  }, [inputToken, address, fetchAllowance])

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, inputToken?.decimals)
    : 0n

  const { balances: tokenBalances, refetch: fetchTokenBalances } = useErc20TokenBalances(
    [inputToken, outputToken],
    address,
  )

  const { balance: inputTokenBalance, parsedBalance: parsedInputTokenBalance } =
    _.find(tokenBalances, { token: inputToken }) || {}

  const { parsedBalance: parsedOutputTokenBalance } =
    _.find(tokenBalances, { token: outputToken }) || {}

  const isBalanceSufficient = useMemo(() => {
    if (!isUndefined(inputTokenBalance) && !isUndefined(formattedInput)) {
      return inputTokenBalance >= formattedInput
    } else {
      return true
    }
  }, [inputTokenBalance, formattedInput])

  const showInputBalance = address && inputToken
  const showOutputBalance = address && outputToken

  const isApproved = useMemo(() => {
    if (_.isUndefined(allowance) || _.isUndefined(formattedInput)) return false
    if (inputToken && isGasToken(inputToken)) return true
    return allowance >= formattedInput
  }, [allowance, formattedInput, inputToken])

  const quoteExactInputSingleParams = validateExactInputSingleParams(
    swapToken,
    outputToken,
    selectedPool?.maintenance,
    selectedPool?.oracleAddress,
    inputValue,
  )
    ? constructExactInputSingleParams(
        swapToken as Token,
        outputToken as Token,
        selectedPool?.maintenance,
        selectedPool?.oracleAddress,
        address ?? zeroAddress,
        inputValue,
        currentBlockTimestamp,
        transactionDeadline,
      )
    : null

  // Reset swap state only when chainId changes, not on initial mount
  useEffect(() => {
    // If chainId changes, reset state
    if (chainId) {
      onResetSwapState()
    }
    
    // Return cleanup function that runs when component unmounts
    return () => {
      // Reset state when navigating away from the Swap page
      onResetSwapState()
      dispatch(resetSwapQuote())
    }
  }, [chainId, onResetSwapState, dispatch])

  const fetchAndSetSwapQuote = async () => {
    try {
      if (quoteExactInputSingleParams) {
        dispatch(
          fetchExactInputSingleQuote({
            chainId,
            quoterAddress: V1_QUOTER_ADDRESS[chainId],
            params: quoteExactInputSingleParams,
          }),
        )
      }
    } catch (error) {
      console.error("Error fetching swap quote:", error)
    }
  }

  useEffect(() => {
    if (inputToken && outputToken && inputValue && selectedPool) {
      fetchAndSetSwapQuote()
    } else {
      dispatch(resetSwapQuote())
    }
  }, [inputValue, chainId, inputToken, outputToken, selectedPool]) // eslint-disable-line react-hooks/exhaustive-deps

  const { priceImpact, derivedSwapParamsWithSlippage, swapQuoteError } =
    deriveSwapQuoteData(
      swapQuote,
      sqrtPriceX96,
      zeroForOne,
      maxSlippage,
      quoteExactInputSingleParams,
    )

  const priceImpactIndicator = getPriceImpactIndicatorColor(priceImpact)

  const quotedOutput = useMemo(() => {
    if (inputValue && swapQuote?.amountOut) {
      return trimTrailingZeroes(
        formatBigIntToString(
          BigInt(swapQuote?.amountOut.toString()),
          outputToken?.decimals,
        ),
      )
    } else {
      return ""
    }
  }, [inputToken, outputToken, swapQuote, inputValue]) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    state: {
      // showConfirm,
      isPendingWallet,
      isPendingApprove,
      isPendingTx,
      // isTxSubmitted,
      // txHash,
      // txError,
    },
    // openConfirmModal,
    // closeConfirmModal,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
    resetState: resetTransactionState,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    onResetSwapState()
    onUserInput("")
  }

  const executeSwap = async () => {
    try {
      if (!isApproved) {
        return new Error("Not approved")
      }
      setPendingWallet(true)
      const transaction = await exactInputSingle(
        chainId,
        inputValue,
        derivedSwapParamsWithSlippage,
        V1_ROUTER_ADDRESS[chainId],
        isInputGasToken,
      )
      await fetchTokenBalances()
      setTxSubmitted(true, transaction.transactionHash)

      const formattedOutputAmount = trimTrailingZeroes(
        formatBigIntToString(swapQuote.amountOut, outputToken?.decimals),
      )

      toast(
        <Toast type="success" header="Swap Success">
          <div className="text-sm text-marginalGray-600">
            Swapped {inputValue} {inputToken?.symbol} for {formattedOutputAmount}{" "}
            {outputToken?.symbol}
          </div>
          <Link
            className="flex w-fit items-center justify-center text-sm text-marginalGray-200 hover:underline focus:outline-none"
            to={getExplorerLink(
              chainId,
              transaction.transactionHash,
              ExplorerDataType.TRANSACTION,
            )}
            target="_blank"
          >
            View on explorer
            <ArrowOutgoingIcon className="ml-2" />
          </Link>
        </Toast>,
        {
          autoClose: 10000,
        },
      )

      onSuccessReset()

      // Wait for 3 seconds to allow blockchain state to update
      await new Promise((resolve) => setTimeout(resolve, 3000))

      // Refetch transactions data to update the transactions table
      await refetchTransactions()

      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing swap: ", error)
      toast(
        <Toast type="error" header="Transaction Error">
          <p className="text-sm text-marginalGray-600">
            There was an issue with your transaction.
          </p>
        </Toast>,
      )
    } finally {
      resetTransactionState()
    }
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }
    try {
      setPendingApprove(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })

      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })

      toast(
        <Toast type="success" header={`Approved ${inputToken?.symbol}`}>
          <Link
            className="flex w-fit items-center justify-center text-sm text-marginalGray-200 hover:underline focus:outline-none"
            to={getExplorerLink(chainId, txHash, ExplorerDataType.TRANSACTION)}
            target="_blank"
          >
            View on explorer
            <ArrowOutgoingIcon className="ml-2" />
          </Link>
        </Toast>,
        {
          autoClose: 10000,
        },
      )

      await fetchAllowance()
    } catch (error) {
      console.error("Error approving token: ", error)
      if (isTransactionReceiptError(error)) {
        setPendingApprove(false)
        fetchAllowance()
      } else {
        toast(
          <Toast type="error" header="Transaction Error">
            <p className="text-sm text-marginalGray-600">
              There was an issue with your transaction.
            </p>
          </Toast>,
        )
      }
    } finally {
      setPendingApprove(false)
      setPendingWallet(false)
    }
  }

  const switchTokens = (prevInputToken: any, prevOutputToken: any) => {
    // inputToken
    if (isGasToken(prevOutputToken)) {
      onSelectInputToken(prevOutputToken)
      onSelectSwapToken(WRAPPED_GAS_TOKEN)

      if (isWrappedGasToken(outputToken, chainId)) {
        onSelectOutputToken(null)
      }
    } else {
      onSelectInputToken(prevOutputToken)
      onSelectSwapToken(prevOutputToken)

      if (outputToken === prevOutputToken) {
        onSelectOutputToken(null)
      }
    }
    // outputToken
    if (isWrappedGasToken(prevInputToken, chainId) && isGasToken(inputToken)) {
      onSelectInputToken(null)
    }
    if (isGasToken(prevInputToken)) {
      // onSelectOutputToken(wethToken)
    } else {
      onSelectOutputToken(prevInputToken)
    }

    // Set inputValue to quotedOutput
    if (quotedOutput) {
      onUserInput(quotedOutput)
    }
  }

  const selectInputToken = (token: any) => {
    if (isGasToken(token)) {
      onSelectInputToken(token)
      onSelectSwapToken(WRAPPED_GAS_TOKEN)

      if (isWrappedGasToken(outputToken, chainId)) {
        onSelectOutputToken(null)
      }
    } else {
      onSelectInputToken(token)
      onSelectSwapToken(token)

      // Check if output token exists and if it's not in the valid output list for the new input token
      if (outputToken) {
        const validOutputs = getSwapOutputList(pools, token)
        const isOutputValid = validOutputs.some(
          (t) => getValidAddress(t.address) === getValidAddress(outputToken.address),
        )
        if (!isOutputValid) {
          onSelectOutputToken(null)
        }
      }
    }

    closeInputModal()
  }

  const selectOutputToken = (token: any) => {
    if (isWrappedGasToken(token, chainId) && isGasToken(inputToken)) {
      onSelectInputToken(null)
    }
    onSelectOutputToken(token)
    closeOutputModal()
  }

  return (
    <>
      <div className="origin-top">
        <div className="drop-shadow-black relative mx-auto max-w-md rounded-xl border border-marginalGray-800 bg-marginalGray-900">
          <header className="flex items-center justify-between p-4">
            <h1 className="relative text-lg">Swap</h1>
            <SlippageButton
              maxSlippage={maxSlippage}
              showSettings={showSettings}
              onClose={onCloseSettings}
              onOpen={onOpenSettings}
            />
          </header>

          <div className="h-px bg-marginalGray-800" />

          <section className="space-y-1 p-2 md:p-4">
            <div>
              <InputContainer id="swap-input-container">
                <div className="flex w-full flex-col">
                  <SwapInput
                    title="You Pay"
                    inputValue={inputValue}
                    onChange={onUserInput}
                  />
                  {inputValue && inputTokenUSDPrice && (
                    <div className="mt-1 flex items-center justify-between">
                      <div className="text-xs text-marginalGray-400">
                        {formatUSDCurrency(inputTokenUSDPrice * parseFloat(inputValue))}
                      </div>
                    </div>
                  )}
                </div>

                <div className="flex flex-col items-end justify-center">
                  <TokenSelector
                    onClick={openInputTokenListModal}
                    selectedToken={inputToken}
                    showSwapStyles={true}
                  />
                  <TokenSelectorModal
                    isOpen={isInputModalOpen}
                    selectedToken={swapToken}
                    tokenOptions={tokenListWithBera}
                    onSelect={selectInputToken}
                    onClose={closeInputModal}
                  />
                  {showInputBalance && (
                    <div className="mt-3 flex space-x-2 text-xs">
                      <div className="whitespace-nowrap text-marginalGray-600">
                        balance:{" "}
                        {parsedInputTokenBalance &&
                        parseFloat(parsedInputTokenBalance) > 0
                          ? formatNumberAmount(parsedInputTokenBalance)
                          : "0.0"}
                      </div>
                      {parsedInputTokenBalance &&
                        parseFloat(parsedInputTokenBalance) > 0 && (
                          <div
                            className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                            onClick={() => {
                              onUserInput(
                                parsedInputTokenBalance ? parsedInputTokenBalance : "",
                              )
                            }}
                          >
                            Max
                          </div>
                        )}
                    </div>
                  )}
                </div>
              </InputContainer>
              <SwitchButton
                onClick={() => {
                  switchTokens(inputToken, outputToken)
                }}
                disabled={!inputToken && !outputToken}
              />
            </div>

            <InputContainer id="swap-output-container">
              <div className="flex w-full flex-col">
                <SwapOutput title="You Receive" outputValue={quotedOutput} />
                {quotedOutput && outputTokenUSDPrice && (
                  <div className="mt-1 flex items-center justify-between">
                    <div className="text-xs text-marginalGray-400">
                      {formatUSDCurrency(outputTokenUSDPrice * parseFloat(quotedOutput))}
                    </div>
                  </div>
                )}

                {priceImpact && (
                  <div className="mt-1 flex items-center justify-between">
                    <div className={`text-xs ${priceImpactIndicator}`}>
                      impact: (-{priceImpact?.toFixed(2)}%)
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col items-end justify-center">
                <TokenSelector
                  onClick={openOutputTokenListModal}
                  selectedToken={outputToken}
                  showSwapStyles={true}
                />
                <TokenSelectorModal
                  isOpen={isOutputModalOpen}
                  selectedToken={outputToken}
                  tokenOptions={inputToken ? outputList : tokenListWithBera}
                  onSelect={selectOutputToken}
                  onClose={closeOutputModal}
                />

                {showOutputBalance && (
                  <div className="mt-3 flex space-x-2 text-xs">
                    <div className="whitespace-nowrap text-marginalGray-600">
                      balance:{" "}
                      {parsedOutputTokenBalance &&
                      parseFloat(parsedOutputTokenBalance) > 0
                        ? formatNumberAmount(parsedOutputTokenBalance)
                        : "0.0"}
                    </div>
                  </div>
                )}
              </div>
            </InputContainer>
            <div className="pt-1">
              <ConfirmSwapButton
                chainId={chainId}
                inputToken={inputToken}
                isInputValid={isInputValid}
                isTokensValid={isInputTokenValid && isOutputTokenValid}
                isApproved={isApproved}
                isBalanceSufficient={isBalanceSufficient}
                isPendingWallet={isPendingWallet}
                isPendingTx={isPendingTx}
                isPendingApprove={isPendingApprove}
                onApprove={() =>
                  approveToken(
                    formattedInput,
                    V1_ROUTER_ADDRESS[chainId],
                    inputToken?.address as Address,
                  )
                }
                onConfirm={executeSwap}
                swapCallback={executeSwap}
                error={swapQuoteError}
              />
            </div>
          </section>
        </div>

        <div className="mx-auto mt-4 w-full max-w-md">
          <SwapDetailList
            pool={selectedPool}
            token0={token0}
            token1={token1}
            poolPrice={formatNumberAmount(poolPrice, true)}
            maxSlippage={maxSlippage}
            leverageTier={leverageCap}
            useInverse={useInverse}
            onToggleInverse={onToggleInverse}
          />
        </div>
      </div>
      {/* <ConfirmSwapModal
        chainId={chainId}
        pool={selectedPool}
        open={showConfirm}
        onOpen={openConfirmModal}
        onClose={closeConfirmModal}
        onReset={onSuccessReset}
        quotedInput={inputValue}
        quotedOutput={quotedOutput}
        inputToken={inputToken}
        outputToken={outputToken}
        swapQuote={swapQuote}
        swapCallback={executeSwap}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        poolPrice={formatNumberAmount(poolPrice, true)}
        maxSlippage={maxSlippage}
        useInverse={useInverse}
        onToggleInverse={onToggleInverse}
      /> */}
    </>
  )
}
