import { useMemo } from "react"
import { useReadContracts } from "wagmi"
import { Address } from "viem"
import { V1_APR_ADDRESS } from "../constants/addresses"
import marginalV1APRAbi from "../constants/abis/MarginalV1APR.json"
import { formatBigIntToString } from "src/utils/formatBigIntToString"

interface RewardTokenConfig {
  rewardToken: Address
  rewardsPoolWithWETH9: Address
}

interface APRQuoteResult {
  aprPercentages: { [key: string]: number | undefined }
  totalAprPercentage: number | undefined
  isError: boolean
  isLoading: boolean
}

/**
 * Custom hook to fetch and calculate APR quote percentage rates for multiple reward tokens
 * @param stakingToken The token being staked
 * @param rewardConfigs Array of reward token configurations containing reward token address and its corresponding pool
 * @param duration Duration in seconds for APR calculation
 * @param chainId Chain ID for the network
 * @returns Object containing the calculated APR percentages and status flags
 */
export const useMultiplePoolRewardsAPR = (
  stakingToken: Address,
  rewardConfigs: RewardTokenConfig[],
  duration: number,
  chainId: number,
): APRQuoteResult => {
  // Create contract configurations for each reward token
  const contracts = useMemo(() => {
    if (
      !stakingToken ||
      !rewardConfigs.length ||
      !duration ||
      !chainId
    ) {
      return []
    }

    return rewardConfigs.map(({ rewardToken, rewardsPoolWithWETH9 }) => ({
      address: V1_APR_ADDRESS[chainId],
      abi: marginalV1APRAbi as any,
      functionName: "quotePercentageRate",
      args: [stakingToken, rewardToken, rewardsPoolWithWETH9, duration],
      chainId,
    }))
  }, [stakingToken, rewardConfigs, duration, chainId])

  // Fetch APR data for all reward tokens
  const { data, isError, isLoading } = useReadContracts({
    contracts,
    query: {
      enabled: contracts.length > 0,
    },
  })

  // Calculate APR percentages for each reward token and total APR
  const { aprPercentages, totalAprPercentage } = useMemo(() => {
    if (!data || !rewardConfigs.length) return { aprPercentages: {}, totalAprPercentage: undefined }

    const percentages = rewardConfigs.reduce((acc, { rewardToken }, index) => {
      const rawRate = data[index]?.result as bigint | undefined

      if (!rawRate) {
        acc[rewardToken] = undefined
        return acc
      }

      const formattedRate = formatBigIntToString(rawRate, 18)
      if (!formattedRate) {
        acc[rewardToken] = undefined
        return acc
      }

      acc[rewardToken] = parseFloat(formattedRate) * 100
      return acc
    }, {} as { [key: string]: number | undefined })

    // Calculate total APR by summing up all valid APR values
    const total = Object.values(percentages)
      .filter((apr): apr is number => apr !== undefined)
      .reduce((sum, apr) => sum + apr, 0)

    return {
      aprPercentages: percentages,
      totalAprPercentage: total || undefined
    }
  }, [data, rewardConfigs])

  return {
    aprPercentages,
    totalAprPercentage,
    isError,
    isLoading,
  }
}
