import { configureStore, Middleware } from "@reduxjs/toolkit"

import { api } from "src/state/api/api"
import { api as apiLb } from "src/state/api/api-lb"
import application from "src/state/application/reducer"
import trade from "src/state/trade/reducer"
import transaction from "src/state/transaction/reducer"
import swap from "src/state/swap/reducer"
import createPool from "src/state/createPool/reducer"
import addLiquidity from "src/state/addLiquidity/reducer"
import stake from "src/state/stake/reducer"
import unstake from "src/state/unstake/reducer"
import removeLiquidity from "src/state/removeLiquidity/reducer"
import addMargin from "src/state/addMargin/reducer"
import settings from "src/state/settings/reducer"
import prices from "src/state/prices/reducer"
import lbp from "src/state/lbp/reducer"

const store = configureStore({
  reducer: {
    application,
    trade,
    transaction,
    swap,
    createPool,
    addLiquidity,
    stake,
    unstake,
    removeLiquidity,
    addMargin,
    settings,
    prices,
    lbp,
    [api.reducerPath]: api.reducer,
    // [apiLb.reducerPath]: apiLb.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(api.middleware as Middleware)
      .concat(apiLb.middleware as Middleware),
})

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
