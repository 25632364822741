import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { ExactInputSingleQuote, Token } from "src/types"
import { fetchExactInputSingleQuote } from "./hooks"

export enum FetchState {
  IDLE = "idle",
  LOADING = "loading",
  VALID = "valid",
  INVALID = "invalid",
}

export const EMPTY_SWAP_QUOTE: ExactInputSingleQuote = {
  amountOut: 0n,
  liquidityAfter: 0n,
  sqrtPriceX96After: 0n,
}

/** @dev swapToken is the token used for swaps, inputToken allows for ETH token selection */
export interface SwapState {
  inputValue: string
  swapToken: Token | null
  inputToken: Token | null
  outputToken: Token | null
  swapQuote: ExactInputSingleQuote
  status: FetchState
  error: any
}

export const initialState: SwapState = {
  inputValue: "",
  swapToken: null,
  inputToken: null,
  outputToken: null,
  swapQuote: EMPTY_SWAP_QUOTE,
  status: FetchState.IDLE,
  error: null,
}

export const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {
    setInputValue: (state: SwapState, action: PayloadAction<string>) => {
      state.inputValue = action.payload
    },
    setSwapToken: (state: SwapState, action: PayloadAction<Token | null>) => {
      state.swapToken = action.payload
    },
    setInputToken: (state: SwapState, action: PayloadAction<Token | null>) => {
      state.inputToken = action.payload
    },
    setOutputToken: (state: SwapState, action: PayloadAction<Token | null>) => {
      state.outputToken = action.payload
    },
    resetSwapQuote: (state) => {
      state.swapQuote = EMPTY_SWAP_QUOTE
      state.status = FetchState.IDLE
      state.error = null
    },
    resetSwapState: (state) => {
      state.inputValue = ""
      state.swapQuote = EMPTY_SWAP_QUOTE
      state.status = FetchState.IDLE
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExactInputSingleQuote.pending, (state) => {
        state.status = FetchState.LOADING
      })
      .addCase(
        fetchExactInputSingleQuote.fulfilled,
        (state, action: PayloadAction<ExactInputSingleQuote>) => {
          state.swapQuote = action.payload
          state.status = FetchState.VALID
          state.error = null
        },
      )
      .addCase(fetchExactInputSingleQuote.rejected, (state, action) => {
        state.swapQuote = EMPTY_SWAP_QUOTE
        state.status = FetchState.INVALID
        state.error = action.payload
      })
  },
})

export const {
  setInputValue,
  setSwapToken,
  setInputToken,
  setOutputToken,
  resetSwapQuote,
  resetSwapState,
} = swapSlice.actions

export default swapSlice.reducer
