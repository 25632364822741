import { type Token } from "src/types"
import { BERA, WBERA, WETH, WBTC, HONEY, MARG, YEET, iBGT, iBERA } from "./tokens"

export const tokenList: Token[] = [
  BERA,
  WBERA,
  WETH,
  WBTC,
  HONEY,
  MARG,
  YEET,
  iBGT,
  iBERA,
]

type TokensByChain = {
  [chainId: number]: Token[]
}

export const getRankedTokenListByChain = (tokens: Token[]): TokensByChain => {
  const groupedTokens = tokens.reduce<TokensByChain>((acc, token) => {
    if (!acc[token.chainId]) {
      acc[token.chainId] = []
    }
    acc[token.chainId].push(token)
    return acc
  }, {})

  // Sort tokens by priority rank, using 100 as default for tokens without rank
  Object.keys(groupedTokens).forEach((chainId) => {
    groupedTokens[Number(chainId)].sort(
      (a, b) => (a.priorityRank ?? 100) - (b.priorityRank ?? 100),
    )
  })

  return groupedTokens
}

export const TOKENS_BY_CHAIN = getRankedTokenListByChain(tokenList)

export const getTokenByAddress = (
  address: string | undefined,
  chainId: number,
): Token | undefined => {
  const tokens = TOKENS_BY_CHAIN[chainId]
  if (!tokens || !address) return undefined

  return tokens.find((token) => token.address?.toLowerCase() === address?.toLowerCase())
}
