import { berachain } from "viem/chains"
import { CHAINS_BY_ID } from "./chains"

export const supportedChains = [berachain].map((chain) => {
  return {
    ...chain,
    configRpc: CHAINS_BY_ID[chain.id]?.rpcUrls.primary,
    fallbackRpc: CHAINS_BY_ID[chain.id]?.rpcUrls.fallback,
    iconUrl: CHAINS_BY_ID[chain.id]?.chainImg.src,
  }
})
