import * as all from "./chainList"
import { Chain } from "./chainList"

export const CHAINS_ARR = Object.values(all).sort(
  (a, b) => b.priorityRank - a.priorityRank,
)

export type ChainsByChainID = {
  [cID: number]: Chain
}

const getChainsByID = (): ChainsByChainID => {
  const outObj: ChainsByChainID = {}
  CHAINS_ARR.forEach((chain) => {
    outObj[chain.id] = chain
  })
  return outObj
}

export const CHAINS_BY_ID = getChainsByID()

export const ChainId = {
  BERACHAIN: 80094,
  // ETH: 1,
  // BASE: 8453,
  // SEPOLIA: 11155111,
  // BERACHAIN_BARTIO: 80084,
}

export const SupportedChainIds = Object.values(ChainId)
export const DEFAULT_CHAIN_ID = ChainId.BERACHAIN

export const isSupportedChain = (chainId: number): boolean => {
  return SupportedChainIds.includes(chainId)
}
