import { useConnectModal } from "@rainbow-me/rainbowkit"
import { useEffect, useState, useCallback } from "react"
import { shortenAddress } from "src/utils/shortenAddress"
import { useAccount, useDisconnect, useEnsName } from "wagmi"
import { DotIcon } from "src/components/Icons/DotIcon"
import { LogOutIcon } from "../Icons/LogOutIcon"
import { LogInIcon } from "../Icons/LogInIcon"

export const ConnectWalletBtn = () => {
  const { isConnected, isConnecting, isReconnecting, isDisconnected, address, chainId } =
    useAccount()
  const { disconnect } = useDisconnect()
  const isLoading = isConnecting || isReconnecting

  const [displayName, setDisplayName] = useState<string>()
  const { refetch: getEnsName } = useEnsName()

  const { openConnectModal } = useConnectModal()

  const updateDisplayName = useCallback(
    async (address: `0x${string}` | undefined) => {
      // Set display name to address
      const addressFormatted = shortenAddress(address)
      setDisplayName(addressFormatted)

      // Set display name to ENS name (if available)
      const { data: ensName } = await getEnsName()
      if (ensName) setDisplayName(ensName)
    },
    [getEnsName],
  )

  // Update display name on wallet connect or chain switch
  useEffect(() => {
    if (isConnected || chainId) updateDisplayName(address)
  }, [isConnected, chainId, address, updateDisplayName])

  return (
    <div className="flex h-full items-center">
      <div className="flex h-full items-center gap-2 pr-4">
        <DotIcon
          className={`${isConnected ? "text-success-500" : "text-marginalGray-600"} h-2 w-2 transition-all delay-200 duration-500`}
        />
        <p
          className={`${isConnected ? "text-white" : "text-marginalGray-200"} min-w-32 rounded bg-black/30 px-3 py-1 text-center text-sm transition-colors delay-200 duration-500`}
        >
          {isLoading ? "Loading" : isConnected ? displayName : "Unconnected"}
        </p>
      </div>
      <button
        onClick={() => {
          if (isConnected) disconnect()
          else if (isDisconnected && openConnectModal) openConnectModal()
        }}
        className="focus-vistext-marginalOrange-500 flex h-full items-center border-l border-marginalGray-200/20 px-4 py-2 transition-all hover:bg-marginalOrange-800 hover:text-marginalOrange-500 focus-visible:bg-marginalOrange-800"
      >
        {isConnected ? (
          <LogOutIcon className="h-4 w-4" />
        ) : (
          <LogInIcon className="h-4 w-4" />
        )}
      </button>
    </div>
  )
}
