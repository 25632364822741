import { useRef, useState } from "react"
import { useSettingsActionHandlers, useSettingsState } from "src/state/settings/hooks"
import useCloseOnExternalClick from "src/hooks/useCloseOnExternalClick"
import { SlippageInput } from "./SlippageInput"
import { RotatingActiveArrow } from "../ui/RotatingActiveArrow"
import { TransactionDeadlineInput } from "./TransactionDeadlineInput"
import { InfoTip } from "src/components/ui/MouseoverTooltip"
import { XMarkIcon } from "@heroicons/react/24/solid"
import { SwitchButton } from "src/components/ui/SwitchButton"
import { DEFAULT_SLIPPAGE } from "src/constants"

export const SettingsModal = ({
  showSettings,
  onCloseSettings,
}: {
  showSettings: boolean
  onCloseSettings: () => void
}) => {
  const settingsRef = useRef(null)
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { onSetMaxSlippage, onSetTransactionDeadline } = useSettingsActionHandlers()
  const [showInput, setShowInput] = useState(true)
  const [showTransactionDeadline, setShowTransactionDeadline] = useState(true)
  const [isAutoSlippage, setIsAutoSlippage] = useState(true)

  const resetDefaults = (): void => {
    if (Number(maxSlippage) === 0 || Number(maxSlippage) > 50) {
      onSetMaxSlippage(DEFAULT_SLIPPAGE)
    }
    if (Number(transactionDeadline) === 0 || Number(transactionDeadline) > 999) {
      onSetTransactionDeadline("10")
    }
    onCloseSettings()
  }

  useCloseOnExternalClick(settingsRef, showSettings ? resetDefaults : () => null)

  return (
    <dialog
      open={showSettings}
      ref={settingsRef}
      className="mr-0 mt-2 transform-gpu cursor-default space-y-6 whitespace-nowrap border border-marginalOrange-500 bg-marginalBlack p-4"
    >
      <header className="flex items-center justify-between">
        <h1 className="text-marginalGray-200">Settings</h1>
        <XMarkIcon
          onClick={onCloseSettings}
          className="right-3 my-auto h-4 w-4 cursor-pointer stroke-marginalGray-200 stroke-2 hover:opacity-80"
        />
      </header>

      <section className="flex items-center justify-between text-sm text-marginalGray-200">
        <div className="flex gap-1">
          <span>Max slippage</span>
          <InfoTip width={12} height={12}>
            Your transaction will revert if the price changes unfavorably by more than
            this percentage.
          </InfoTip>
        </div>
        <div
          className="flex cursor-pointer items-center space-x-1 hover:opacity-60"
          onClick={() => setShowInput(!showInput)}
        >
          <span>{maxSlippage}%</span>
          <RotatingActiveArrow isActive={showInput} />
        </div>
      </section>

      <div
        className={`mb-3 flex transform-gpu items-center justify-between overflow-y-hidden transition-max-height duration-300 ease-in-out ${showInput ? "max-h-8" : "max-h-0"} gap-4`}
      >
        <div className="h-8 w-full min-w-48">
          <SwitchButton
            view={isAutoSlippage ? "Auto" : "Custom"}
            onLeftButtonClick={() => setIsAutoSlippage(true)}
            onRightButtonClick={() => setIsAutoSlippage(false)}
            leftButtonText="Auto"
            rightButtonText="Custom"
          />
        </div>
        <SlippageInput
          inputValue={maxSlippage}
          onChange={onSetMaxSlippage}
          isAuto={isAutoSlippage}
          onSetAuto={setIsAutoSlippage}
        />
      </div>

      <div className="mb-2 flex items-center justify-between py-1 text-sm text-marginalGray-200">
        <span className="flex gap-0.5">
          Transaction deadline
          <InfoTip width={14} height={14}>
            Your transaction will revert if it is pending for more than this period of
            time.
          </InfoTip>
        </span>
        <div
          className="flex cursor-pointer items-center space-x-1 hover:opacity-60"
          onClick={() => setShowTransactionDeadline(!showTransactionDeadline)}
        >
          <div className="">{transactionDeadline}m</div>
          <RotatingActiveArrow isActive={showTransactionDeadline} />
        </div>
      </div>
      <div
        className={`flex transform-gpu items-center justify-between overflow-y-hidden transition-max-height duration-300 ease-in-out ${showTransactionDeadline ? "max-h-[30px]" : "max-h-0"} `}
      >
        <TransactionDeadlineInput
          inputValue={transactionDeadline}
          onChange={onSetTransactionDeadline}
        />
      </div>

      {/* TODO: Add functionality to Default Trade Options */}
      {/* <div className="my-4 h-px bg-marginalGray-800" />

        <div id="default-trade-options" className="flex justify-between">
          <div className="flex flex-col space-y-2">
            <span className="text-sm text-marginalGray-200">
              Default Trade Options
            </span>
            <span className="text-sm text-marginalGray-600">
              The Marginal client selects the cheapest trade option factoring price and
              network costs.
            </span>
          </div>

          <ToggleButton
            isToggled={isDefaultOptions}
            handleClick={() => setIsDefaultOptions(!isDefaultOptions)}
          />
        </div> */}
    </dialog>
  )
}

// const ToggleButton = ({
//   isToggled,
//   handleClick,
//   isSmall = false,
// }: {
//   isToggled: boolean
//   handleClick: Function
//   isSmall?: boolean
// }) => {
//   return (
//     <div className="relative">
//       {/* Switch */}
//       <button
//         className={`${isSmall ? "w-12 h-7" : "w-14 h-8"} flex items-center rounded-full ${isSmall ? "p-1" : "p-1"} cursor-pointer border border-transparent focus:ring-2 focus:ring-marginalOrange-300
//           ${
//             isToggled
//               ? "bg-marginalOrange-800 hover:border-marginalOrange-600"
//               : "bg-marginalGray-800 hover:border-marginalGray-600"
//           }
//         `}
//         onClick={() => handleClick()}
//       >
//         {/* Handle */}
//         <div
//           className={`relative ${isSmall ? "w-5 h-5" : "w-6 h-6"} z-40 rounded-[40px]
//             ${
//               isToggled
//                 ? "transition-all translate-x-full bg-marginalOrange-500"
//                 : "transition-all -translate-x-1/5 bg-marginalGray-400"
//             }
//           `}
//         />
//       </button>
//     </div>
//   )
// }
