import { Header } from "./components/Header/HeaderV2"
import { Footer } from "src/components/Footer/FooterV2"
import { MobileHeader } from "src/components/Header/MobileHeaderV2"
import { MobileFooter } from "./components/Footer/MobileFooter"

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="flex min-h-dvh flex-col">
      <Header />
      <MobileHeader />

      <main
        id="body-wrapper"
        className="flex w-full flex-grow flex-col p-4 pb-8 md:pt-12"
      >
        {children}
      </main>

      <Footer />
      <MobileFooter />
    </div>
  )
}
