import { type Token } from "src/types"
import { isWeth, isWrappedGasToken } from "src/utils/isWrappedGasToken"

interface TokenPair {
  baseToken: Token | null
  quoteToken: Token | null
}

// TODO: Account for multiple wrapped gas tokens
export const extrapolateTokenPair = (
  token0: Token | null,
  token1: Token | null,
  chainId: number,
): TokenPair => {
  if (!token0 || !token1) {
    return {
      baseToken: null,
      quoteToken: null,
    }
  }

  if (
    !isWrappedGasToken(token0, chainId) &&
    !isWrappedGasToken(token1, chainId) &&
    !isWeth(token0) &&
    !isWeth(token1)
  ) {
    console.error(
      "Wrapped gas token not detected in either tokens to construct base/quote.",
    )
    return {
      baseToken: null,
      quoteToken: null,
    }
  }

  if (isWrappedGasToken(token0, chainId) || isWeth(token0)) {
    return {
      baseToken: token1,
      quoteToken: token0,
    }
  }

  return {
    baseToken: token0,
    quoteToken: token1,
  }
}
