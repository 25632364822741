export const LeverageSlider = ({
  selectedLeverage,
  maxLeverage,
  onSelect,
  healthFactor,
}: {
  selectedLeverage: number
  maxLeverage: number | undefined | null
  onSelect: (value: number) => void
  healthFactor: number | null
}) => {
  const handleLeverageChange = (event: any) => {
    const newLeverage = parseFloat(event.target.value)
    onSelect(newLeverage)
  }

  return (
    <div
      id="leverage-slider"
      className="flex flex-col items-start rounded bg-marginalBlack p-4 text-marginalGray-600"
    >
      <div className="text-md mb-2 text-center">Leverage: {selectedLeverage}x</div>
      <input
        type="range"
        min={1.1}
        max={maxLeverage ? maxLeverage - 0.1 : 4.9}
        step={0.1}
        value={selectedLeverage}
        onChange={handleLeverageChange}
        className="w-full cursor-pointer bg-marginalGray-600 accent-marginalOrange-500"
      />
      <div className="mt-1 flex w-full justify-between text-sm">
        <div>1.1x</div>
        <div>5x</div>
      </div>

      {/* {healthFactor && (
        <>
          <div className="my-4 w-full h-px bg-marginalGray-200/20" />
          <div className="flex justify-between w-full text-sm">
            <div className="">Health Factor</div>

            <div className="flex items-center space-x-1">
              <HealthFactorIcon />
              <div className={`${healthFactorIndicator}`}>{healthFactor}</div>
            </div>
          </div>
        </>
      )} */}
    </div>
  )
}
