import { Address, zeroAddress } from "viem"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { calculatePercentageOfTotal } from "src/utils/conversions/calculatePercentageOfTotal"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { PoolRowProps } from "src/pages/pools/components/PoolRow"
import { TableCell } from "src/pages/pools/components/TableComponents"
import { CircularProgressBar } from "src/pages/pools/components/CircularProgressBar"
import { getTokenByAddress } from "src/constants/tokenList"
import { DoubleTokenLogo, TokenBadge } from "src/components/TokenBadge"
import { type Token } from "src/types"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { useMemo } from "react"
import { useMultiplePoolRewardsAPR } from "src/hooks/useMultiplePoolRewardsAPR"
import { WBERA } from "src/constants/tokens"

export const PoolRowContent = ({
  chainId,
  pool,
  liquidityLocked,
  userPoolBalance,
  poolTotalSupply,
  poolState,
  poolTokenBalances,
}: PoolRowProps) => {
  const { onNavigateToPool } = useNavigateRoutes()
  const handleNavigateToPool = () => onNavigateToPool(pool.poolAddress)
  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const { quoteToken } = extrapolateTokenPair(token0 ?? null, token1 ?? null, chainId)

  const userSharePercentageOfTotal = calculatePercentageOfTotal({
    total: parseFloat(poolTotalSupply?.parsedTotalSupply ?? "0"),
    partial: parseFloat(userPoolBalance?.parsedBalance ?? "0"),
  })
  const poolUtilization = calculatePercentageOfTotal({
    total: parseFloat(poolTotalSupply?.parsedTotalSupply ?? "0"),
    partial: parseFloat(liquidityLocked?.parsedLockedLiquidity ?? "0"),
  })

  // Get token prices for TVL calculation
  const { token0PriceInUSD, token1PriceInUSD } = useLivePoolPrices({
    chainId,
    selectedPool: pool,
    zeroForOne: true,
    useInverse: true,
    quoteToken,
    position: undefined,
  })

  // Calculate TVL
  const tvl = useMemo(() => {
    if (!poolTokenBalances || !token0PriceInUSD || !token1PriceInUSD) return 0

    return poolTokenBalances.reduce((acc, balance, index) => {
      const price = index === 0 ? token0PriceInUSD : token1PriceInUSD
      const tokenValue = parseFloat(balance?.parsedBalance ?? "0") * price
      return acc + tokenValue
    }, 0)
  }, [poolTokenBalances, token0PriceInUSD, token1PriceInUSD])

  // Calculate rewards APR if reward tokens exist
  const durationOneYearInSeconds = 86400 * 365
  const rewardConfigs = useMemo(() => {
    if (!pool?.rewardTokens) return []
    return pool.rewardTokens.map((rewardToken) => ({
      rewardToken,
      rewardsPoolWithWETH9:
        rewardToken === WBERA.address ? zeroAddress : (pool.poolAddress as Address),
    }))
  }, [pool])

  const { totalAprPercentage } = useMultiplePoolRewardsAPR(
    pool.poolAddress as Address,
    rewardConfigs,
    durationOneYearInSeconds,
    chainId,
  )

  return (
    <tr
      onClick={handleNavigateToPool}
      className="hidden w-full cursor-pointer bg-marginalBlack hover:bg-marginalGray-950 md:table-row"
    >
      <TableCell>
        <div className="flex items-center gap-1.5">
          <DoubleTokenLogo token0={token0 as Token} token1={token1 as Token} size={6} />
          <span>
            {token0?.symbol} / {token1?.symbol}
          </span>
        </div>
      </TableCell>
      <TableCell>${formatNumberAmount(tvl.toString())}</TableCell>
      <TableCell>
        {totalAprPercentage
          ? `${formatNumberAmount(totalAprPercentage.toString())}%`
          : "-"}
      </TableCell>
      <TableCell>
        <div className="flex flex-col items-end space-y-1">
          {poolTokenBalances?.map((balance, index) => (
            <div key={index} className="flex items-center gap-1.5">
              {formatNumberAmount(balance?.parsedBalance ?? "-")}
              <span className="flex items-center gap-1.5">
                <TokenBadge token={index === 0 ? token0 : token1} size={4} />
              </span>
            </div>
          ))}
        </div>
      </TableCell>
      <TableCell>
        <div className="flex items-center justify-end gap-1.5">
          <CircularProgressBar
            sqSize={16}
            strokeWidth={2}
            percentage={poolUtilization ?? 0}
          />
          {formatNumberAmount(poolUtilization?.toString() ?? "0")}%
        </div>
      </TableCell>
      <TableCell>{formatNumberAmount(userSharePercentageOfTotal?.toString())}%</TableCell>
    </tr>
  )
}
