import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react"
import { NavLink } from "react-router-dom"

interface FooterPopoverProps {
  width?: number // in pixels
  links: Record<string, string>
  children: React.ReactNode
}

export const FooterPopover = ({ width = 0, links, children }: FooterPopoverProps) => {
  return (
    <Popover className="relative flex">
      <PopoverButton
        className="flex h-full cursor-pointer items-center gap-2 border-l border-marginalGray-200/20 stroke-marginalGray-200 p-3 font-normal transition-all hover:bg-marginalGray-900 hover:stroke-white hover:text-white focus-visible:bg-marginalGray-900 focus-visible:stroke-white focus-visible:text-white"
        style={{
          width: width > 0 ? `${width}px` : "min-content",
        }}
      >
        {children}
      </PopoverButton>
      <PopoverPanel
        anchor={{ to: "top start" }}
        className="z-50 flex flex-col border border-marginalGray-200/20 bg-marginalGray-950 backdrop-blur-lg"
        style={{
          width: width > 0 ? `${width}px` : "min-content",
        }}
      >
        {Object.entries(links).map(([label, href], i) =>
          href ? (
            <NavLink
              key={i}
              to={href}
              target="_blank"
              rel="noreferrer"
              className="flex w-full text-nowrap px-4 py-1 text-xs outline-none transition-colors hover:bg-marginalGray-900 hover:text-white focus-visible:bg-marginalGray-900 focus-visible:text-white"
            >
              {label}
            </NavLink>
          ) : (
            <p
              key={`disabled-${i}`}
              className="flex w-full cursor-not-allowed text-nowrap px-4 py-1 text-xs text-marginalGray-600"
            >
              {label}
            </p>
          ),
        )}
      </PopoverPanel>
    </Popover>
  )
}
