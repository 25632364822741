import { useState, useEffect, useMemo } from "react"
import { useAccount } from "wagmi"
import { Address } from "viem"
import { maxUint256, zeroAddress } from "viem"
import {
  useTradeActionHandlers,
  useTradeState,
  useTradeStatus,
} from "src/state/trade/hooks"
import _ from "lodash"
import { TokenSelector } from "src/pages/trade/components/TokenSelector"
import { TradeInput } from "src/pages/trade/components/TradeInput"
import { TradeOutput } from "src/pages/trade/components/TradeOutput"
import { ConfirmTradeButton } from "src/pages/trade/components/ConfirmTradeButton"
import { TradeDetailList } from "src/pages/trade/components/TradeDetailList"
import { usePoolsContextData } from "src/providers/PoolsProvider"
import { PoolData } from "src/types"
import { useDerivePoolsFromToken } from "src/hooks/useDerivePoolsFromTokens"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import {
  V1_NFT_POSITION_MANAGER_ADDRESS,
  V1_QUOTER_ADDRESS,
} from "src/constants/addresses"
import { waitForTransactionReceipt } from "@wagmi/core"
import { filterTokensFromList } from "src/utils/filterTokenList"
import { isBlank } from "src/utils/isBlank"
import { useErc20TokenBalances } from "src/hooks/useErc20TokenBalances"
import { LeverageSlider } from "src/pages/trade/components/LeverageSlider"
import { InputContainer } from "src/components/ui/InputContainer"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { isTransactionReceiptError } from "src/utils/isTransactionReceiptError"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { getValidAddress } from "src/utils/getValidAddress"
import { useSettingsToggle } from "src/hooks/useSettingsToggle"
import { useSettingsState } from "src/state/settings/hooks"
import { SlippageButton } from "src/components/Settings/SlippageButton"
import { useOpenModal, useModalIsOpen, useCloseModal } from "src/state/application/hooks"
import { ApplicationModal } from "src/state/application/reducer"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { GasCurrencySelect } from "src/components/GasCurrencySelect"
import { GAS_TOKEN_MAP, WRAPPED_GAS_TOKEN_MAP } from "src/constants/tokens"
import { useNativeTokenBalance } from "src/hooks/useNativeTokenBalance"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { useInversePrice } from "src/hooks/useInversePrice"
import { formatUSDCurrency } from "src/utils/formatUSDCurrency"
import { wagmiConfig } from "src/wagmiConfig"
import { mint } from "src/utils/actions/mint"
import { validateMintQuoteParams } from "src/pages/trade/helpers/validateMintQuoteParams"
import { constructMintQuoteParams } from "src/pages/trade/helpers/constructMintQuoteParams"
import { useAppDispatch } from "src/state/hooks"
import { fetchMintQuote } from "src/state/trade/hooks"
import { resetMintQuote } from "src/state/trade/reducer"
import { useTradeFees } from "src/pages/trade/hooks/useTradeFees"
import { deriveMintQuoteData } from "src/pages/trade/helpers/deriveMintQuoteData"
import { useTransactionModal } from "src/hooks/useTransactionModal"
import { calculateMaxBalanceInput } from "src/pages/trade/helpers/calculateMaxBalanceInput"
import { checkBalanceSufficient } from "src/pages/trade/helpers/checkBalanceSufficient"
import { getPriceImpactIndicatorColor } from "src/pages/trade/helpers/getPriceImpactIndicatorColor"
import { useMarginalPoolsState } from "src/pages/pools/hooks/useMarginalPoolsState"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { useTokenList } from "src/hooks/useTokenList"
import { TokenBadge } from "src/components/TokenBadge"
import { type Token } from "src/types"
import { TokenSelectorModal } from "src/components/ui/TokenSelectorModal"
import { resolvePoolTokenPrice } from "src/utils/pools/resolvePoolTokenPrice"
import { sortPoolsByLiquidityAvailable } from "src/utils/pools/sortPoolsByLiquidityAvailable"
import { SwitchButton } from "src/components/ui/SwitchButton"
import { Toast } from "src/components/ui/Toast"
import { toast } from "react-toastify"
import { Link } from "react-router-dom"
import { ArrowOutgoingIcon } from "src/components/Icons/ArrowOutgoingIcon"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import { usePositionsData } from "src/providers/PositionsProvider"
import { useTransactionsData } from "src/providers/TransactionsProvider"
// import { TokenCandlestickChart } from "src/components/TokenCandlestickChart"

export default function Trade() {
  const dispatch = useAppDispatch()
  const { refetch: refetchPositions } = usePositionsData()
  const { refetch: refetchTransactions } = useTransactionsData()
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { inputValue, leverage, tradeToken, marginToken, debtToken, isLong, mintQuote } =
    useTradeState()
  const {
    onSelectLeverage,
    onUserInput,
    onSelectTradeToken,
    onSelectMarginToken,
    onSelectDebtToken,
    onSetIsLong,
    onResetTradeState,
  } = useTradeActionHandlers()
  const { showSettings, onOpenSettings, onCloseSettings } = useSettingsToggle()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { isInputValid, isMarginTokenValid, isDebtTokenValid } = useTradeStatus()
  const { fees, feesRaw, escrowRewardsWithBuffer } = useTradeFees(mintQuote)
  const [useInverse, onToggleInverse] = useInversePrice()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)

  const isTradeTokenListOpen = useModalIsOpen(ApplicationModal.TRADE_TOKEN_LIST)
  const openTradeTokenListModal = useOpenModal(ApplicationModal.TRADE_TOKEN_LIST)

  const closeModal = useCloseModal()

  const GAS_TOKEN = GAS_TOKEN_MAP[chainId]
  const WRAPPED_GAS_TOKEN = WRAPPED_GAS_TOKEN_MAP[chainId]
  const [useGasToken, setUseGasToken] = useState<boolean>(true)
  const displayGasToken: boolean = !isLong && useGasToken
  const showMarginTokenBalance = !address || !marginToken ? false : true

  const { pools, tokensFromPools: tokenOptions } = usePoolsContextData()
  const tokenList = useTokenList(chainId, tokenOptions)
  const derivedPools: PoolData[] = useDerivePoolsFromToken(tradeToken, pools)
  const { poolStates } = useMarginalPoolsState(derivedPools, chainId)
  const rankedPools = sortPoolsByLiquidityAvailable(Object.values(poolStates))
  const selectedPool = rankedPools?.[0]?.pool

  const poolLeverageMax = convertMaintenanceToLeverage(selectedPool?.maintenance)
  const token0 =
    getTokenByAddress(selectedPool?.token0?.address, chainId) ?? selectedPool?.token0
  const token1 =
    getTokenByAddress(selectedPool?.token1?.address, chainId) ?? selectedPool?.token1
  const { baseToken, quoteToken } = extrapolateTokenPair(token0, token1, chainId)

  const zeroForOne =
    getValidAddress(marginToken?.address) ===
    getValidAddress(selectedPool?.token1?.address)

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, marginToken?.decimals)
    : 0n

  const { allowance, fetchAllowance } = useErc20TokenAllowance(
    marginToken?.address as Address,
    Number(marginToken?.decimals),
    address,
    V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
    chainId,
  )

  useEffect(() => {
    if (marginToken && address) {
      if (!isOnlyZeroes(inputValue)) {
        ;(async () => {
          await fetchAllowance()
        })()
      }
    }
  }, [chainId, marginToken, address, fetchAllowance, inputValue])

  useEffect(() => {
    if (selectedPool && tradeToken && baseToken && quoteToken) {
      if (isLong) {
        if (marginToken !== baseToken) {
          onSelectMarginToken(baseToken)
        }
        if (debtToken !== quoteToken) {
          onSelectDebtToken(quoteToken)
        }
      } else {
        if (marginToken !== quoteToken) {
          onSelectMarginToken(quoteToken)
        }
        if (debtToken !== baseToken) {
          onSelectDebtToken(baseToken)
        }
      }
    }
  }, [selectedPool, isLong, tradeToken, baseToken, quoteToken]) // eslint-disable-line react-hooks/exhaustive-deps

  const { balance: gasBalance, shortenedParsedBalance: shortenedParsedGasBalance } =
    useNativeTokenBalance(address, chainId)
  const { balances: tokenBalances, refetch: fetchTokenBalances } = useErc20TokenBalances(
    [marginToken, debtToken, GAS_TOKEN],
    address,
  )

  const {
    balance: marginTokenBalance,
    shortenedParsedBalance: shortenedParsedMarginTokenBalance,
  } = _.find(tokenBalances, { token: marginToken }) || {}

  const isApproved = useMemo(() => {
    if (displayGasToken) return true
    if (!allowance || !formattedInput) return false
    // Add feesRaw to formattedInput to ensure allowance covers both amount and fees
    return allowance >= formattedInput + (feesRaw || 0n)
  }, [allowance, formattedInput, displayGasToken, feesRaw])

  const isBalanceSufficient = useMemo(() => {
    if (escrowRewardsWithBuffer) {
      return checkBalanceSufficient(
        displayGasToken,
        gasBalance,
        marginTokenBalance,
        formatStringToBigInt(
          (parseFloat(inputValue) + parseFloat(escrowRewardsWithBuffer)).toString(),
          marginToken?.decimals,
        ),
      )
    } else {
      return checkBalanceSufficient(
        displayGasToken,
        gasBalance,
        marginTokenBalance,
        formattedInput,
      )
    }
  }, [
    displayGasToken,
    gasBalance,
    marginTokenBalance,
    inputValue,
    formattedInput,
    escrowRewardsWithBuffer,
    marginToken?.decimals,
  ])

  const mintQuoteParams = validateMintQuoteParams(
    selectedPool,
    marginToken,
    debtToken,
    inputValue,
    leverage,
  )
    ? constructMintQuoteParams(
        selectedPool,
        marginToken as Token,
        debtToken as Token,
        inputValue,
        leverage,
        address ?? zeroAddress,
        transactionDeadline,
        currentBlockTimestamp,
      )
    : null

  // Reset trade state only when chainId changes, not on initial mount
  useEffect(() => {
    // If chainId changes, reset state
    if (chainId) {
      onResetTradeState()
    }
    
    // Return cleanup function that runs when component unmounts
    return () => {
      // Reset state when navigating away from the Trade page
      onResetTradeState()
      dispatch(resetMintQuote())
    }
  }, [chainId, onResetTradeState, dispatch])

  const fetchAndSetMintQuote = async () => {
    try {
      if (mintQuoteParams) {
        dispatch(
          fetchMintQuote({
            chainId,
            quoterAddress: V1_QUOTER_ADDRESS[chainId],
            params: mintQuoteParams,
          }),
        )
      }
    } catch (error) {
      console.error("Error fetching mint quote:", error)
    }
  }

  useEffect(() => {
    if (inputValue && marginToken && debtToken && leverage) {
      fetchAndSetMintQuote()
    } else {
      dispatch(resetMintQuote())
    }
  }, [dispatch, chainId, inputValue, marginToken, debtToken, leverage, address]) // eslint-disable-line react-hooks/exhaustive-deps

  const {
    sqrtPriceX96,
    oraclePrice,
    poolPrice,
    liquidationPrice,
    fundingRate,
    token0PriceInUSD,
    token1PriceInUSD,
  } = useLivePoolPrices({
    chainId,
    selectedPool,
    zeroForOne,
    useInverse,
    quoteToken,
    position: mintQuote,
  })

  const {
    // margin,
    debt,
    // size,
    totalSize,
    // fees: quotedFees,
    healthFactor,
    priceImpact,
    derivedMintParamsWithSlippage,
    mintQuoteError,
  } = deriveMintQuoteData(
    mintQuote,
    marginToken,
    debtToken,
    fees,
    sqrtPriceX96,
    zeroForOne,
    maxSlippage,
    mintQuoteParams,
  )

  const priceImpactIndicator = getPriceImpactIndicatorColor(priceImpact)
  const marginTokenPriceInUSD = resolvePoolTokenPrice(
    marginToken,
    selectedPool,
    token0PriceInUSD,
    token1PriceInUSD,
  )

  const {
    state: {
      // showConfirm,
      isPendingWallet,
      isPendingApprove,
      // isPendingTx,
      // isTxSubmitted,
      // txHash,
      // txError,
    },
    // openConfirmModal,
    closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
    setTxError,
  } = useTransactionModal()

  const onSuccessReset = () => {
    closeConfirmModal()
    resetTransactionState()
    onResetTradeState()
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }
    try {
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      setPendingWallet(false)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      await fetchAllowance()
      fetchAndSetMintQuote()
      toast(
        <Toast type="success" header={`Approved ${marginToken?.symbol}`}>
          <Link
            className="flex w-fit items-center justify-center text-sm text-marginalGray-200 hover:underline focus:outline-none"
            to={getExplorerLink(chainId, txHash, ExplorerDataType.TRANSACTION)}
            target="_blank"
          >
            View on explorer
            <ArrowOutgoingIcon className="ml-2" />
          </Link>
        </Toast>,
        {
          autoClose: 10000,
        },
      )
    } catch (error) {
      console.error("Error approving token: ", error)
      if (isTransactionReceiptError(error)) {
        resetTransactionState()
        fetchAllowance()
      } else {
        toast(
          <Toast type="error" header="Transaction Error">
            <p className="text-sm text-marginalGray-600">
              There was an issue with your transaction.
            </p>
          </Toast>,
        )
      }
    } finally {
      setPendingApprove(false)
      setPendingWallet(false)
    }
  }

  const executeMint = async () => {
    try {
      setTxError(null)
      if (!isApproved) {
        return new Error("Not approved")
      }
      setPendingWallet(true)
      const transaction = await mint(
        derivedMintParamsWithSlippage,
        escrowRewardsWithBuffer,
        V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
        chainId,
      )
      await fetchTokenBalances()

      setTxSubmitted(true, transaction.transactionHash)

      const pool = selectedPool?.tokenPair

      toast(
        <Toast type="success" header="Open Position">
          <p className="text-sm text-marginalGray-600">
            Opened {totalSize.formatted} {marginToken?.symbol} [{leverage}x] position on{" "}
            {pool}.
          </p>
          <Link
            className="flex w-fit items-center justify-center text-sm text-marginalGray-200 hover:underline focus:outline-none"
            to={getExplorerLink(
              chainId,
              transaction.transactionHash,
              ExplorerDataType.TRANSACTION,
            )}
            target="_blank"
          >
            View on explorer
            <ArrowOutgoingIcon className="ml-2" />
          </Link>
        </Toast>,
        {
          autoClose: 10000,
        },
      )
      onSuccessReset()

      // Wait for 5 seconds to allow blockchain state to update
      await new Promise((resolve) => setTimeout(resolve, 5000))

      // Refetch data to update the positions and transactions tables
      await Promise.all([refetchPositions(), refetchTransactions()])

      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing mint: ", error)
      setTxError(error)

      toast(
        <Toast type="error" header="Transaction Error">
          <p className="text-sm text-marginalGray-600">
            There was an issue with your transaction.
          </p>
        </Toast>,
      )
    }
  }

  return (
    <>
      {/* <div className="flex flex-wrap-reverse gap-4 justify-center w-full lg:flex-nowrap"> */}
      {/* <TokenCandlestickChart token={tradeToken ?? WBERA} /> */}
      <div className="flex min-w-96 origin-top flex-wrap justify-center gap-4">
        <div className="relative h-fit max-w-md rounded-xl border border-marginalGray-800 bg-marginalGray-900">
          <header className="flex items-center justify-between p-4">
            <h1 className="relative text-lg font-bold text-marginalGray-200">Trade</h1>
            <SlippageButton
              maxSlippage={maxSlippage}
              showSettings={showSettings}
              onClose={onCloseSettings}
              onOpen={onOpenSettings}
            />
          </header>

          <div className="flex flex-col gap-2 space-y-1 px-2 pb-2 pt-0 md:px-4 md:pb-4">
            <div className="w-full rounded-lg bg-marginalBlack px-4 py-2.5">
              <TokenSelector
                onClick={openTradeTokenListModal}
                selectedToken={tradeToken}
                showFullTokenName={true}
              />
            </div>
            <div className="h-10 w-full">
              <SwitchButton
                view={isLong ? "Long" : "Short"}
                onLeftButtonClick={() => onSetIsLong(true)}
                onRightButtonClick={() => onSetIsLong(false)}
                leftButtonText="Long"
                rightButtonText="Short"
              />
            </div>
          </div>

          <div className="h-px bg-marginalGray-800" />

          <div className="space-y-2 p-2 md:p-4">
            <div>
              <InputContainer id="trade-input-container">
                <div className="flex w-full flex-col">
                  <TradeInput
                    title="Collateral"
                    value={inputValue}
                    onChange={onUserInput}
                  />

                  {inputValue && marginTokenPriceInUSD && (
                    <div className="mt-1 flex items-center justify-between">
                      <div className={`text-xs text-marginalGray-400`}>
                        {formatUSDCurrency(
                          marginTokenPriceInUSD * parseFloat(inputValue),
                        )}
                      </div>
                    </div>
                  )}
                </div>
                <div className="flex flex-col items-end justify-center">
                  {marginToken ? (
                    <>
                      <div className="mb-1.5 flex items-center space-x-1 text-xl font-bold">
                        <TokenBadge token={displayGasToken ? GAS_TOKEN : marginToken} />
                        {address && !isLong && (
                          <>
                            <GasCurrencySelect
                              useGas={useGasToken}
                              setUseGas={setUseGasToken}
                              chainId={chainId}
                            />
                          </>
                        )}
                      </div>
                      {showMarginTokenBalance &&
                        (displayGasToken ? (
                          <div className="flex space-x-2 text-xs">
                            <div className="whitespace-nowrap text-marginalGray-600">
                              balance: {shortenedParsedGasBalance}
                            </div>
                            <div
                              className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                              onClick={() => {
                                onUserInput(
                                  calculateMaxBalanceInput(
                                    gasBalance,
                                    poolLeverageMax as number,
                                    marginToken,
                                  ),
                                )
                              }}
                            >
                              Max
                            </div>
                          </div>
                        ) : (
                          <div className="flex space-x-2 text-xs">
                            <div className="whitespace-nowrap text-marginalGray-600">
                              balance: {shortenedParsedMarginTokenBalance}
                            </div>
                            <div
                              className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                              onClick={() => {
                                onUserInput(
                                  calculateMaxBalanceInput(
                                    marginTokenBalance,
                                    poolLeverageMax as number,
                                    marginToken,
                                  ),
                                )
                              }}
                            >
                              Max
                            </div>
                          </div>
                        ))}
                    </>
                  ) : null}
                </div>
              </InputContainer>
            </div>

            <div className="flex items-center justify-between space-x-2 rounded-lg border border-transparent bg-marginalGray-950 px-4 py-3">
              <div className="flex w-full flex-col">
                <TradeOutput
                  title={`Size ${marginToken?.symbol ? `(in ${marginToken.symbol})` : ""}`}
                  outputValue={totalSize.formatted ? totalSize.formatted : "0"}
                />

                {totalSize.formatted && marginTokenPriceInUSD && (
                  <div className="mt-1 flex items-center justify-between">
                    <div className="text-xs text-marginalGray-400">
                      {formatUSDCurrency(
                        marginTokenPriceInUSD * parseFloat(totalSize.formatted),
                      )}
                    </div>
                  </div>
                )}

                {priceImpact && priceImpact > 0 && (
                  <div className="mt-1 flex items-center justify-between">
                    <div className={`text-xs ${priceImpactIndicator}`}>
                      impact: (-{priceImpact?.toFixed(2)}%)
                    </div>
                    {debt.formatted && (
                      <div className="flex items-center space-x-1 text-xs text-marginalGray-400">
                        <div>debt: </div>
                        <div>{debt.formatted}</div>
                        <TokenBadge token={debtToken as Token} size={4} />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <LeverageSlider
              selectedLeverage={leverage}
              maxLeverage={poolLeverageMax}
              onSelect={onSelectLeverage}
              healthFactor={healthFactor}
            />
          </div>

          <div className="px-2 pb-2 md:px-4 md:pb-4">
            <ConfirmTradeButton
              chainId={chainId}
              marginToken={marginToken}
              isInputValid={isInputValid}
              isTokensValid={isMarginTokenValid && isDebtTokenValid}
              isApproved={isApproved}
              isBalanceSufficient={isBalanceSufficient}
              isPendingWallet={isPendingWallet}
              isPendingApprove={isPendingApprove}
              onApprove={() =>
                approveToken(
                  formattedInput,
                  V1_NFT_POSITION_MANAGER_ADDRESS[chainId],
                  marginToken?.address as Address,
                )
              }
              onConfirm={executeMint}
              mintQuote={mintQuote}
              mintCallback={executeMint}
              error={mintQuoteError}
            />
          </div>
        </div>

        <div className="w-full max-w-md">
          <TradeDetailList
            pool={selectedPool}
            token0={token0}
            token1={token1}
            marginToken={marginToken}
            debtToken={debtToken}
            poolMaxLeverage={poolLeverageMax}
            leverage={leverage}
            quotedDebt={debt.formatted}
            fundingRate={fundingRate}
            liquidationPrice={formatNumberAmount(liquidationPrice, true)}
            oraclePrice={formatNumberAmount(oraclePrice, true)}
            poolPrice={formatNumberAmount(poolPrice, true)}
            priceImpact={priceImpact}
            maxSlippage={maxSlippage}
            escrowRewards={formatNumberAmount(escrowRewardsWithBuffer, true)}
            healthFactor={healthFactor}
            useInverse={useInverse}
            onToggleInverse={onToggleInverse}
          />
        </div>
      </div>
      {/* </div> */}

      <TokenSelectorModal
        isOpen={isTradeTokenListOpen}
        selectedToken={tradeToken}
        tokenOptions={filterTokensFromList(tokenList, [WRAPPED_GAS_TOKEN, GAS_TOKEN])}
        onSelect={onSelectTradeToken}
        onClose={closeModal}
      />
      {/* <ConfirmTradeModal
        chainId={chainId}
        pool={selectedPool}
        leverageMax={poolLeverageMax}
        useGas={displayGasToken}
        gasToken={GAS_TOKEN}
        token0={token0}
        token1={token1}
        open={showConfirm}
        onOpen={openConfirmModal}
        onClose={closeConfirmModal}
        onReset={onSuccessReset}
        quotedMargin={margin.formatted}
        quotedDebt={debt.formatted}
        quotedSize={size.formatted}
        quotedFees={quotedFees.formatted}
        marginToken={marginToken}
        debtToken={debtToken}
        leverage={leverage}
        mintQuote={mintQuote}
        mintCallback={executeMint}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        fundingRate={fundingRate}
        liquidationPrice={formatNumberAmount(liquidationPrice, true)}
        oraclePrice={formatNumberAmount(oraclePrice, true)}
        poolPrice={formatNumberAmount(poolPrice, true)}
        priceImpact={priceImpact}
        healthFactor={healthFactor}
        escrowRewards={escrowRewardsWithBuffer}
        useInverse={useInverse}
        onToggleInverse={onToggleInverse}
      /> */}
    </>
  )
}
