import { isUndefined } from "lodash"
import { estimateFeesPerGas, type EstimateFeesPerGasReturnType } from "@wagmi/core"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { useEffect, useState, useCallback } from "react"
import { MintQuote } from "src/types"
import { ChainId } from "src/constants/chains/chains"
import { wagmiConfig } from "src/wagmiConfig"
import { useApplicationState } from "src/state/application/hooks"

interface TradeFees {
  escrowRewards: string | undefined | null
  fees: string | undefined
  feesRaw: bigint | undefined
  escrowRewardsWithBuffer: string | undefined | null
}

const POLLING_INTERVAL = 30_000 // 30 seconds

interface UseEscrowRewardsResult {
  escrowRewards: bigint | null
  fetchEscrowRewards: () => Promise<void>
  error: Error | null
}

export const useTradeFees = (mintQuote: MintQuote | null): TradeFees => {
  const { chainId } = useApplicationState()
  const { escrowRewards } = useEscrowRewards(chainId)

  const feesParsed = formatBigIntToString(mintQuote?.fees, 18)
  const escrowRewardsParsed = formatBigIntToString(escrowRewards, 18)

  // Calculate escrow rewards with 1bps buffer
  const escrowRewardsWithBuffer =
    escrowRewardsParsed && (parseFloat(escrowRewardsParsed) * 1.001).toString()

  return {
    escrowRewards: escrowRewardsParsed,
    fees: feesParsed,
    feesRaw: mintQuote?.fees,
    escrowRewardsWithBuffer,
  }
}

export const useEscrowRewards = (chainId: number): UseEscrowRewardsResult => {
  const [escrowRewards, setEscrowRewards] = useState<bigint | null>(null)
  const [error, setError] = useState<Error | null>(null)

  const fetchEscrowRewards = useCallback(async () => {
    try {
      setError(null)
      const rewards = await getEscrowRewards(chainId)
      setEscrowRewards(rewards)
    } catch (err) {
      setError(err instanceof Error ? err : new Error("Failed to fetch escrow rewards"))
      setEscrowRewards(null)
    }
  }, [chainId])

  useEffect(() => {
    fetchEscrowRewards()

    const interval = setInterval(fetchEscrowRewards, POLLING_INTERVAL)

    return () => {
      clearInterval(interval)
      setEscrowRewards(null)
      setError(null)
    }
  }, [fetchEscrowRewards, chainId])

  return { escrowRewards, fetchEscrowRewards, error }
}

const BLOCK_FEES: { [chainId: number]: string } = {
  [ChainId.BERACHAIN]: "4000000000",
  // [ChainId.SEPOLIA]: "40000000000",
  // [ChainId.BASE]: "4000000000",
  // [ChainId.BERACHAIN_BARTIO]: "4000000000",
  // [ChainId.ETH]: "40000000000",
}

// @dev rewards formula: rewards = 150000 * 2 * max(block.basefee, 40 * 1e9)
export const getEscrowRewards = async (chainId: number): Promise<bigint | null> => {
  const { maxFeePerGas }: EstimateFeesPerGasReturnType = await estimateFeesPerGas(
    wagmiConfig,
    {
      chainId: chainId,
    },
  )

  const gasLiquidate = 150000n

  const blockBaseFee = maxFeePerGas ?? undefined
  const blockBaseFeeMin = BigInt(BLOCK_FEES[chainId])

  let baseFee

  if (isUndefined(blockBaseFee)) return null

  if (blockBaseFeeMin < blockBaseFee) {
    baseFee = blockBaseFee
  } else {
    baseFee = blockBaseFeeMin
  }

  const rewards = gasLiquidate * baseFee
  const rewardsWithPremium = rewards * 2n

  return rewardsWithPremium
}
