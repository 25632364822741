import { type Token } from "src/types"
import { RotatingActiveArrow } from "src/components/ui/RotatingActiveArrow"
import { TokenBadge } from "src/components/TokenBadge"

export const TokenSelector = ({
  onClick,
  selectedToken,
  showFullTokenName = false,
  showSwapStyles = false,
  showArrow = true,
}: {
  onClick: () => void
  selectedToken: Token | null
  showFullTokenName?: boolean
  showSwapStyles?: boolean
  showArrow?: boolean
}) => {
  return (
    <div
      id="token-selector"
      className={`flex min-w-fit cursor-pointer items-center justify-between space-x-1 hover:opacity-60 ${showSwapStyles && !selectedToken ? "rounded-lg bg-marginalGray-800 px-2.5 py-2 text-marginalGray-200" : ""}`}
      onClick={onClick}
    >
      {selectedToken ? (
        <TokenBadge token={selectedToken} />
      ) : (
        <span className="whitespace-nowrap font-bold text-marginalGray-400">
          Select Token
        </span>
      )}
      {showArrow && (
        <div className="mt-0.5">
          <RotatingActiveArrow isActive={false} />
        </div>
      )}
    </div>
  )
}
