import { MarginalIcon } from "src/components/Icons/MarginalIcon"
import { MenuIcon } from "../Icons/MenuIcon"
import { useDisclosure } from "src/hooks/useDisclosure"
import { MobileMenu } from "./MobileMenuV2"
import { NAV_LINKS } from "src/constants/links"

export const MobileHeader = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <div className="z-50 flex h-[50px] w-full items-center justify-between border-b border-marginalGray-200/20 bg-marginalGray-950 lg:hidden">
      <a
        href="https://marginal.network/"
        className="flex h-full items-center gap-2 border-r border-marginalGray-200/20 fill-marginalGray-200 px-6 py-3 outline-none hover:bg-marginalGray-900 hover:fill-white hover:text-white focus-visible:bg-marginalGray-900 focus-visible:fill-white focus-visible:text-white"
      >
        <MarginalIcon className="w-8 fill-inherit transition-all" />
        <h1 className="relative -top-[1px] text-lg font-bold leading-none transition-all">
          Marginal
        </h1>
      </a>

      <button
        onClick={onOpen}
        className="flex h-full items-center border-l border-marginalGray-200/20 p-4 transition-all hover:bg-marginalGray-900 focus-visible:bg-marginalGray-900"
      >
        <MenuIcon />
      </button>

      <MobileMenu
        isOpen={isOpen}
        onClose={onClose}
        heading="Marginal"
        position="top"
        links={NAV_LINKS}
      />
    </div>
  )
}
