import { ComponentProps } from "react"

export const DocsIcon = (props: ComponentProps<"svg">) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    fill="none"
    {...props}
  >
    <path
      d="M2.6665 13V2.99998C2.6665 2.55795 2.8421 2.13403 3.15466 1.82147C3.46722 1.50891 3.89114 1.33331 4.33317 1.33331H12.6665C12.8433 1.33331 13.0129 1.40355 13.1379 1.52858C13.2629 1.6536 13.3332 1.82317 13.3332 1.99998V14C13.3332 14.1768 13.2629 14.3464 13.1379 14.4714C13.0129 14.5964 12.8433 14.6666 12.6665 14.6666H4.33317C3.89114 14.6666 3.46722 14.4911 3.15466 14.1785C2.8421 13.8659 2.6665 13.442 2.6665 13ZM2.6665 13C2.6665 12.558 2.8421 12.134 3.15466 11.8215C3.46722 11.5089 3.89114 11.3333 4.33317 11.3333H13.3332M5.33317 7.33331H10.6665M5.33317 4.66665H9.33317"
      strokeWidth="0.931"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
