import { BrowserRouter as Router } from "react-router-dom"
import AppRoutes from "./AppRoutes"
import { ToastProvider } from "src/providers/ToastProvider"
import { PositionsProvider } from "src/providers/PositionsProvider"
import { PoolsProvider } from "src/providers/PoolsProvider"
import { TransactionsProvider } from "src/providers/TransactionsProvider"

function App() {
  return (
    <Router>
      <ToastProvider>
        <PositionsProvider>
          <PoolsProvider>
            <TransactionsProvider>
              <AppRoutes />
            </TransactionsProvider>
          </PoolsProvider>
        </PositionsProvider>
      </ToastProvider>
    </Router>
  )
}

export default App
