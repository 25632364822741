const Bold = ({ children }: { children: React.ReactNode }) => {
  return <b className="text-marginalGray-100">{children}</b>
}

const MarginalEmail = () => {
  const MARGINAL_EMAIL = "legal@marginal.finance"
  return (
    <a href={`mailto:${MARGINAL_EMAIL}`} className="font-bold text-marginalGray-100">
      {MARGINAL_EMAIL}
    </a>
  )
}

const PrivacyPolicy = () => {
  return (
    <div className="mx-auto flex w-full max-w-256 flex-col px-2 font-sans font-normal text-marginalGray-200">
      <h1 className="text-2xl font-bold text-marginalGray-100">Privacy Policy</h1>
      <p className="mb-4 text-marginalGray-600">Last updated January 27, 2025</p>

      <p className="my-4">
        This privacy policy (the <Bold>“Privacy Policy”</Bold>) explains how Marginal
        Finance Inc. (<Bold>“we”</Bold>, <Bold>“us”</Bold> and <Bold>“our”</Bold>)
        collects, uses, and discloses information about you or the person or entity you
        represent (<Bold>“you”</Bold>
        or <Bold>“your”</Bold>) through the websites, including without limitation
        marginal.network and all of its subdomains (collectively, the <Bold>“Site”</Bold>
        ), web applications, and other online products and services (collectively, the{" "}
        <Bold>“Services”</Bold>) or when you otherwise interact with us. We may change
        this Privacy Policy from time to time. If we make changes, we will notify you by
        revising the “Last Updated” date at the top of the Privacy Policy and, in some
        cases, we may provide you with additional notice (such as adding a statement to
        our homepage or sending you a notification). We encourage you to review the
        Privacy Policy whenever you access the Services or otherwise interact with us to
        stay informed about our information practices and the choices available to you.
      </p>

      <p className="my-4">
        By using the Site or the Services, you accept the terms of this Privacy Policy and
        our terms of use (the <Bold>“Terms”</Bold>), and consent to our collection, use,
        disclosure and retention of your information as described in this Privacy Policy.
        If you have not done so already, please also review our Terms. If you do not agree
        with any part of this Privacy Policy or our Terms, then you may not use the Site
        or any of the Services.
      </p>

      <ol className="list-decimal pl-6">
        <li id="information-we-collect" className="mb-2 mt-8 text-xl font-bold">
          <a href="#information-we-collect">Information we collect</a>
        </li>
        <ol className="list-[lower-alpha] space-y-2 pl-8">
          <li>
            <i>Information you provide to us</i>
          </li>
          <p>
            We collect information you provide directly to us. For example, we collect
            information when you access or use the Services, fill out a form, engage in a
            transaction, request customer support or otherwise communicate with us. The
            types of information we may collect include your email address and any other
            information you choose to provide.
          </p>

          <li>
            <i>Automatically collected information</i>
          </li>
          <p>
            When you access or use the Services, we automatically collect information
            about you, which may include the following:
          </p>
          <ol className="list-[lower-roman] space-y-2 pl-8">
            <li>
              <u>Contact information:</u> This may include your name, email address,
              physical address and country information.
            </li>
            <li>
              <u>Financial information:</u> This may include your blockchain protocol
              network address, cryptocurrency wallet information, transaction history,
              trading data and associated fees paid.
            </li>
            <li>
              <u>Transaction information:</u> This may include information about the
              transactions you make using the Services, such as the type of transaction,
              transaction amount and timestamp.
            </li>
            <li>
              <u>Correspondence:</u> This may include your feedback, questionnaire and
              other survey responses and information you provide to our support teams,
              including via our help chat or social media messaging channels.
            </li>
            <li>
              <u>Online identifiers log information:</u> We collect identifier and log
              information about your use of the Services, including username, geolocation
              or tracking details, the type of browser you use, access times, pages
              viewed, your IP address, and the page you visited before navigating to our
              Services.
            </li>
            <li>
              <u>Usage data:</u> This may include conversion events, user preferences,
              crash logs and other data collected via cookies and similar technologies.
            </li>
            <li>
              <u>Device information:</u> We collect information about the computer or
              mobile device you use to access our Services, including the hardware model,
              operating system and version, unique device identifiers, and mobile network
              information.
            </li>
            <li>
              <u>Information collected by cookies and other tracking technologies:</u> We
              and third parties we authorize may use various technologies to collect
              information, including cookies and web beacons. Cookies are small data files
              stored on your hard drive or in device memory that help us improve our
              Services and your experience, see which areas and features of our Services
              are popular, and count visits. Web beacons are electronic images that may be
              used in our Services or emails and help deliver cookies, count visits, and
              understand usage and campaign effectiveness. For more information about
              cookies and how to disable them, please see Section 11 below.
            </li>
          </ol>

          <li>
            <i>Information we collect from other sources</i>
          </li>
          <p>
            We may obtain information from other sources, including third parties, and
            combine that with information we collect through our Services.
          </p>

          <li>
            <i>Information we will never collect</i>
          </li>
          <p>
            We will never ask you to share your private keys or wallet seed. Never trust
            anyone or any site that asks you to enter your private keys or wallet seed.
          </p>
        </ol>

        <li id="how-we-use-information" className="mb-2 mt-8 text-xl font-bold">
          <a href="#how-we-use-information">How we use information</a>
        </li>
        <p className="my-4">
          We use the information we collect to provide, maintain, and improve the
          Services, including as described in the Terms. We may also use the information
          we collect to:
        </p>
        <ol className="list-[lower-alpha] space-y-2 pl-8">
          <li>
            Send you technical notices, updates, security alerts and support and
            administrative messages, and to respond to your comments, questions and
            customer service requests.
          </li>
          <li>
            Communicate with you about products, services, offers, and events offered by
            us and others, and provide news and information we think will be of interest
            to you, which may be provided by any means, including by e-mail, in-app
            notifications, push notifications and display advertising.
          </li>
          <li>
            Personalize your experience when you visit or use the Site or our Services.
          </li>
          <li>Administer contests, promotions, surveys and other Service features.</li>
          <li>
            Monitor and analyze trends, usage, and activities in connection with the
            Services.
          </li>
          <li>
            Comply with applicable laws, lawful requests and legal processes, including
            responding to court orders or requests from regulatory authorities.
          </li>
          <li>
            Generate aggregate or de-identified data and use such data for any lawful
            purpose, including research and analytics.
          </li>
          <li>
            Testing, research, analysis, product development and improve your experience.
          </li>
          <li>
            Detect, investigate and prevent fraudulent transactions and other illegal
            activities and protect our rights and property and the rights and property of
            others.
          </li>
          <li>
            Monitor and verify identity or service access, combat spam, malware or
            security risks.
          </li>
          <li>Investigate and address user concerns.</li>
          <li>Monitor and improve customer support responses and processes.</li>
          <li>
            Perform internal operations necessary to provide the Services, including to
            troubleshoot software bugs and operational problems.
          </li>
          <li>
            Enforce our agreements with third parties and address violations of our Terms
            or agreements for other products or services.
          </li>
        </ol>

        <li id="sharing-of-information" className="mb-2 mt-8 text-xl font-bold">
          <a href="#sharing-of-information">Sharing of information</a>
        </li>
        <p className="my-4">
          We do not share or sell the personal information that you provide us with other
          organizations without your express consent, except as described in this Privacy
          Policy. We may share information about you as follows or as otherwise described
          in this Privacy Policy:
        </p>
        <ol className="list-[lower-alpha] space-y-2 pl-8">
          <li>
            With vendors, consultants and other service providers who need access to such
            information to carry out work on our behalf, including hosting, email and
            database services.
          </li>
          <li>
            In response to a request for information if we believe disclosure is in
            accordance with, or required by, any applicable law, regulation, or legal
            process.
          </li>
          <li>
            If we believe your actions are inconsistent with our user agreements or
            policies, or to protect our rights and property and the rights and property of
            others and ensure our safety and the safety of others.
          </li>
          <li>
            If we believe in good faith that the disclosure of personal information is
            necessary to prevent harm to another person.
          </li>
          <li>To report suspected illegal activity.</li>
          <li>
            To investigate violations of our Terms, agrees for other products or services,
            or any other applicable policies.
          </li>
          <li>
            In connection with, or during negotiations of, any merger, sale of company
            assets, financing, or acquisition of all or a portion of our business by
            another company or bankruptcy transaction or proceeding.
          </li>
          <li>
            Between and among us and our current and future parents, affiliates,
            subsidiaries, and other companies under common control and ownership.
          </li>
          <li>With your consent or at your direction.</li>
        </ol>

        <li
          id="advertising-and-analytics-services-provided-by-others"
          className="mb-2 mt-8 text-xl font-bold"
        >
          <a href="#advertising-and-analytics-services-provided-by-others">
            Advertising and analytics services provided by others
          </a>
        </li>
        <p className="my-4">
          We may allow others to provide analytics services and serve advertisements on
          our behalf across the Internet and in applications. These entities may use
          cookies, web beacons, device identifiers, and other technologies to collect
          information about your use of the Services and other websites and applications,
          including your IP address, web browser, mobile network information, pages
          viewed, time spent on pages or in apps, links clicked, and conversion
          information. This information may be used by us and others to, among other
          things, analyze and track data, determine the popularity of certain content,
          deliver advertising and content targeted to your interests on the Services and
          other websites, and better understand your online activity. For more information
          about interest-based ads, or to opt out of having your web browsing information
          used for behavioral advertising purposes, please visit{" "}
          <a
            href="https://optout.aboutads.info/?c=2&lang=EN"
            className="text-marginalGray-100 underline"
          >
            aboutads.info/choices
          </a>{" "}
          (or{" "}
          <a
            href="http://www.youronlinechoices.eu"
            className="text-marginalGray-100 underline"
          >
            http://www.youronlinechoices.eu
          </a>{" "}
          if you are a resident of the European Economia Area).
        </p>

        <li id="retention-of-data" className="mb-2 mt-8 text-xl font-bold">
          <a href="#retention-of-data">Retention of data</a>
        </li>
        <p className="my-4">
          We store the information we collect about you for as long as is necessary for
          the purposes for which we originally collected it, or for other legitimate
          business purposes, including to meet our legal or other regulatory obligations,
          prevent fraud, resolve disputes, troubleshoot problems, assist with any
          investigation, enforce our Terms, and other actions permitted by law. There is
          no single retention period applicable to the various types of personal
          information collected. Please contact us if you would like to delete any
          personal information we hold about you. We also reserve the right to continue to
          hold personal information about you to the extent it is required to be held by
          us by law, rule, or regulation.
        </p>

        <li id="security-measures" className="mb-2 mt-8 text-xl font-bold">
          <a href="#security-measures">Security measures</a>
        </li>
        <p className="my-4">
          We use commercially reasonable physical, managerial, and technical safeguards to
          preserve the integrity of, and to help protect information about you from, loss,
          theft, misuse and unauthorized access, disclosure, alteration and destruction.
          However, we cannot guarantee that unauthorized third parties will never be able
          to defeat our security measures or use your personal information for improper
          purposes. You acknowledge that you provide your personal information at your own
          risk.
        </p>
        <li
          id="disclaimer-about-sharing-personal-information-online"
          className="mb-2 mt-8 text-xl font-bold"
        >
          <a href="#disclaimer-about-sharing-personal-information-online">
            Disclaimer about sharing personal information online
          </a>
        </li>
        <p className="my-4">
          You acknowledge that when sharing personal information online, there is always a
          risk of data breaches, including data breaches in which third parties unlawfully
          access our systems or the systems of our third-party providers, which store
          personal information.
        </p>

        <li id="limitation-on-liability" className="mb-2 mt-8 text-xl font-bold">
          <a href="#limitation-on-liability">Limitation on liability</a>
        </li>
        <p className="my-4">
          While we take measures to protect personal information, you agree that in no
          event will we, our affiliates, and each of our and their respective
          shareholders, members, directors, officers, managers, employees, lawyers,
          agents, accountants, advisors, representatives, suppliers, licensors, dealers
          and contractors (collectively, the <Bold>“Company Parties”</Bold>) be liable to
          you or any other person in any way in contract, tort (including negligence),
          civil liability or otherwise for any claims, damages, obligations, losses,
          liabilities, costs, debts or expenses (including but not limited to lawyer’s
          fees and disbursements), whether direct, indirect, special, economic,
          incidental, consequential, punitive or exemplary, including without limitation
          loss of revenue, data, anticipated profits or lost business, howsoever caused,
          including by way of negligence, arising from, related to or connect with the
          loss or theft of your personal information. You agree that if, notwithstanding
          the other provisions of this Privacy Policy, a Company Party is found to be
          liable for any claims, proceedings, liabilities, obligations, damages, losses or
          costs, such Company Party’s liability shall in no event exceed the greater of
          (a) US$100 and (b) the amount you paid to us hereunder during the one-month
          period prior to the date on which such claim arose, if any.
        </p>

        <li id="transfer-of-information-to-other-countries" className="mb-2 mt-8 text-xl font-bold">
          <a href="#transfer-of-information-to-other-countries">Transfer of information to other countries</a>
        </li>
        <p className="my-4">
          We are based in Panama. However, we may transfer personal information to outside
          agents or service providers (including our affiliates acting in this capacity)
          that perform services on our behalf, such as customer service and support,
          marketing and analytics, data hosting or processing services, or similar
          services. Some of these service providers may be located outside of Panama,
          including the United States, and as a result your personal information may be
          processed in the United States or elsewhere outside of Panama, where local laws
          may permit foreign government and national security authorities to access
          personal information in certain circumstances.
        </p>

        <li id="residents-of-the-european-economic-area" className="mb-2 mt-8 text-xl font-bold">
          <a href="#residents-of-the-european-economic-area">Residents of the European Economic Area</a>
        </li>
        <p className="my-4">
          If you are a resident of the European Economic Area (<Bold>“EEA”</Bold>), you
          have certain rights and protections under the law regarding the processing of
          your personal data. Any reference to “personal information” in this Privacy
          Policy should be understood as referring to “personal data”, defined under the
          General Data Protection Regulation (<Bold>“GDPR”</Bold>) as “any information
          relating to an identified or identifiable natural person (‘data subject’); an
          identifiable natural person is one who can be identified, directly or
          indirectly, in particular by reference to an identifier such as a name, an
          identification number, location data, an online identifier or to one or more
          factors specific to the physical, physiological, genetic, mental, economic,
          cultural or social identity of that natural person”.
        </p>
        <ol className="list-[lower-alpha] space-y-2 pl-8">
          <li>
            <i>Legal basis for processing</i>
            <p>
              If you are a resident of the EEA, when we process your personal data we will
              only do so where we need to use your personal data to perform our
              responsibilities under our agreement with you (e.g., providing the Services
              you have requested). We have a legitimate interest in processing your
              personal data. For example, we may process your personal data to send you
              marketing communications, to communicate with you about changes to the
              Services, and to provide, secure, and improve the Services. You have
              consented to the processing of your personal data for one or more specific
              purposes. We consider that our legitimate interests are in compliance with
              the GDPR and your legal rights and freedoms. You have the right to object to
              any of this processing and, if you wish, please contact us at the contact
              details indicated below.
            </p>
          </li>

          <li>
            <i>Data subject requests</i>
            <p>
              If you are a resident of the EEA, you have the right to access personal data
              we hold about you and to ask that your personal data be corrected, erased,
              or transferred. You may also have the right to object to, or request that we
              restrict, certain processing. If you would like to exercise any of these
              rights, you can contact us as indicated below. Please note that the
              limitation or deletion of your personal data may mean we will be unable to
              provide our Services to you. You also have the right to receive your
              personal data in a machine-readable format and have the data transferred to
              another party responsible for data processing.
            </p>
          </li>

          <li>
            <i>Questions or complaints</i>
            <p>
              If you are a resident of the EEA and have a concern about our processing of
              personal data that we are not able to resolve, you have the right to lodge a
              complaint with the data privacy authority where you reside. For contact
              details of your local Data Protection Authority, please see:{" "}
              <a
                href="http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-
              authorities/index_en.htm"
                className="text-marginalGray-100 underline"
              >
                http://ec.europa.eu/justice/data-protection/article-29/structure/data-protection-
                authorities/index_en.htm
              </a>
              .
            </p>
          </li>
        </ol>

        <li id="your-choices" className="mb-2 mt-8 text-xl font-bold">
          <a href="#your-choices">Your choices</a>
        </li>
        <ol className="list-[lower-alpha] space-y-2 pl-8">
          <li>
            <i>Account information</i>
            <p>
              You may update, correct, or delete information about you at any time by
              emailing us at <MarginalEmail />. Please note that we may retain cached or
              archived copies of information about you for a certain period of time.
            </p>
          </li>

          <li>
            <i>Cookies</i>
            <p>
              Most web browsers are set to accept cookies by default. If you prefer, you
              can usually choose to set your browser to remove or reject browser cookies.
              Please note that if you choose to remove or reject cookies, this could
              affect the availability and functionality of the Services.
            </p>
          </li>

          <li>
            <i>Promotional communications</i>
            <p>
              You may opt out of receiving promotional communications from us by following
              the instructions in those communications or by emailing us at{" "}
              <MarginalEmail />. If you opt out, we may still send you non-promotional
              emails, such as those about your account or our ongoing business relations.
            </p>
          </li>

          <li>
            <i>Mobile push notifications/alerts</i>
            <p>
              With your consent, we may send promotional and non-promotional push
              notifications or alerts to your mobile device. You can deactivate these
              messages at any time by changing the notification settings on your mobile
              device.
            </p>
          </li>
        </ol>

        <li id="contact" className="mb-2 mt-8 text-xl font-bold">
          <a href="#contact">Contact</a>
        </li>
        <p>
          If you have any questions about this Privacy Policy, please contact us at{" "}
          <MarginalEmail />.
        </p>
      </ol>
    </div>
  )
}

export default PrivacyPolicy
