import { CONTACT_LINKS, RESOURCES_LINKS } from "src/constants/links"
import { ContactIcon } from "src/components/Icons/ContactIcon"
import { DocsIcon } from "src/components/Icons/DocsIcon"
import { FooterPopover } from "src/components/Footer/FooterPopover"

export const Footer = () => {
  return (
    <footer className="hidden h-9 w-full items-center border-t border-marginalGray-200/20 bg-marginalGray-950 lg:flex">
      <div className="flex h-full items-center border-r border-marginalGray-200/20">
        <small className="text-nowrap px-6 text-xs font-normal leading-none">
          Marginal © 2025
        </small>
      </div>

      <div className="hidden h-full lg:flex">
        <a
          href="/terms-of-use"
          className="flex h-full w-min cursor-pointer items-center gap-2 text-nowrap border-r border-marginalGray-200/20 stroke-marginalGray-200 p-3 text-sm font-normal outline-none transition-all hover:bg-marginalGray-900 hover:text-white focus-visible:bg-marginalGray-900 focus-visible:text-white"
        >
          <span className="text-xs leading-none">Terms of Use</span>
        </a>
        <a
          href="/privacy-policy"
          className="flex h-full w-min cursor-pointer items-center gap-2 text-nowrap border-r border-marginalGray-200/20 stroke-marginalGray-200 p-3 text-sm font-normal outline-none transition-all hover:bg-marginalGray-900 hover:text-white focus-visible:bg-marginalGray-900 focus-visible:text-white"
        >
          <span className="text-xs leading-none">Privacy Policy</span>
        </a>
      </div>

      <div className="ml-auto flex h-full -space-x-[1px]">
        <FooterPopover width={110} links={RESOURCES_LINKS}>
          <DocsIcon className="h-3 w-3" />
          <span className="text-xs leading-none">Resources</span>
        </FooterPopover>

        <FooterPopover width={97} links={CONTACT_LINKS}>
          <ContactIcon className="h-3 w-3" />
          <span className="text-xs leading-none">Contact</span>
        </FooterPopover>
      </div>
    </footer>
  )
}
