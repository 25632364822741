import _ from "lodash"
import { useMemo, useEffect, useState } from "react"
import { useAccount } from "wagmi"
import { Address, zeroAddress, maxUint256 } from "viem"
import { useParams } from "react-router-dom"
import { derivePoolLiquidity } from "src/pages/pool/helpers/derivePoolLiquidity"
import { waitForTransactionReceipt } from "@wagmi/core"
import { usePoolsContextData, getPoolDataByAddress } from "src/providers/PoolsProvider"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { InputContainer } from "src/components/ui/InputContainer"
import { V1_ROUTER_ADDRESS, V1_QUOTER_ADDRESS } from "src/constants/addresses"
import { useRemoveLiquidityState } from "src/state/removeLiquidity/hooks"
import { useErc20TokenBalances } from "src/hooks/useErc20TokenBalances"
import { Token, PoolData } from "src/types"
import { RemoveLiquidityInput } from "src/pages/removeLiquidity/components/RemoveLiquidityInput"
import { toast } from "react-toastify"
import { Toast } from "src/components/ui/Toast"
import { Link } from "react-router-dom"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import { ArrowOutgoingIcon } from "src/components/Icons/ArrowOutgoingIcon"
import {
  useRemoveLiquidityActionHandlers,
  useRemoveLiquidityStatus,
} from "src/state/removeLiquidity/hooks"
import { validateRemoveLiquidityParams } from "src/pages/removeLiquidity/helpers/validateRemoveLiquidityParams"
import { deriveRemoveLiquidityQuoteData } from "src/pages/removeLiquidity/helpers/deriveRemoveLiquidityQuoteData"
import { constructRemoveLiquidityParams } from "src/pages/removeLiquidity/helpers/constructRemoveLiquidityParams"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { wagmiConfig } from "src/wagmiConfig"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { getTransactionError } from "src/utils/getTransactionError"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { isBlank } from "src/utils/isBlank"
import { ListRow } from "src/components/ui/ListRow"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { ConfirmRemoveLiquidityButton } from "src/pages/removeLiquidity/components/ConfirmRemoveLiquidityButton"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { useSettingsToggle } from "src/hooks/useSettingsToggle"
import { useSettingsState } from "src/state/settings/hooks"
import { SlippageButton } from "src/components/Settings/SlippageButton"
import { PercentRangeSlider } from "src/components/PercentRangeSlider"
import { useGetCurrentBlockTimestamp } from "src/hooks/useGetCurrentBlockTimestamp"
import { Card } from "src/components/ui/Card"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { removeLiquidity } from "src/utils/actions/removeLiquidity"
import { useAppDispatch } from "src/state/hooks"
import {
  fetchRemoveLiquidityQuote,
  resetRemoveLiquidityQuote,
  resetRemoveLiquidityState,
} from "src/state/removeLiquidity/reducer"
import { useTransactionModal } from "src/hooks/useTransactionModal"
import { useTotalSupplyInPools } from "src/pages/pools/hooks/useTotalSupplyInPools"
import { useLiquidityLockedInPools } from "src/pages/pools/hooks/useLiquidityLockedInPools"
import { useUserPoolBalance } from "src/pages/pool/hooks/useUserPoolBalance"
import { useUserStakedPoolBalance } from "src/pages/pool/hooks/useUserStakedPoolBalance"
import { calculatePercentageOfTotal } from "src/utils/conversions/calculatePercentageOfTotal"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { useCurrentPoolsState } from "src/pages/pools/hooks/useCurrentPoolsState"
import { TokenLogo } from "src/components/TokenBadge"
import { isTransactionReceiptError } from "src/utils/isTransactionReceiptError"

const RemoveLiquidity = () => {
  const dispatch = useAppDispatch()
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { poolAddress } = useParams()
  const { poolsDataByAddress } = usePoolsContextData()
  const {
    tokenA,
    tokenB,
    inputValue,
    quote: removeLiquidityQuote,
    error: removeLiquidityError,
  } = useRemoveLiquidityState()
  const { onUserInput, onSelectTokenA, onSelectTokenB, onResetRemoveLiquidityInput } =
    useRemoveLiquidityActionHandlers()
  const { showSettings, onOpenSettings, onCloseSettings } = useSettingsToggle()
  const { maxSlippage, transactionDeadline } = useSettingsState()
  const { isTokensValid, isInputValid } = useRemoveLiquidityStatus()
  const currentBlockTimestamp = useGetCurrentBlockTimestamp(chainId)
  const { onNavigateToPools } = useNavigateRoutes()
  useNetworkChangeRedirect(onNavigateToPools)
  const lpTokenSymbol = useErc20TokenSymbol(poolAddress as Address)
  const pool = getPoolDataByAddress(poolAddress as string, poolsDataByAddress)
  const { poolStates } = useCurrentPoolsState([pool?.poolAddress as Address])

  const poolsTotalSupply = useTotalSupplyInPools([pool])
  const liquidityLockedInPools = useLiquidityLockedInPools([pool])
  const { balances } = useUserPoolBalance([pool], address)
  const { balances: userStakedBalance } = useUserStakedPoolBalance(
    [pool as PoolData],
    address,
  )

  const userLpBalance = balances?.[0]
  const userStakedLpBalance = userStakedBalance?.[0]
  const userTotalLpBalance = (
    parseFloat(userLpBalance?.parsedBalance ?? "0") +
    parseFloat(userStakedLpBalance?.parsedBalance ?? "0")
  ).toString()

  const poolLiquidityLocked = liquidityLockedInPools?.[0]
  const poolLiquidityTotal = poolsTotalSupply?.[0]

  const userSharePercentageOfTotal = calculatePercentageOfTotal({
    total: parseFloat(poolLiquidityTotal?.parsedTotalSupply ?? "0"),
    partial: parseFloat(userTotalLpBalance ?? "0"),
  })

  const { balances: tokenBalances, refetch: fetchTokenBalances } = useErc20TokenBalances(
    [
      {
        address: poolAddress,
        name: "Marginal V1 LP Token",
        symbol: lpTokenSymbol ?? "MARGV1-LP",
        decimals: pool?.decimals ?? 18,
      },
    ] as Token[],
    address,
  )

  const { balance: lpTokenBalance, parsedBalance: parsedLpTokenBalance } =
    tokenBalances?.[0] || {}

  const [percentage, setPercentage] = useState<number>(0)
  const [percentError, setPercentError] = useState(false)

  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1

  // Derive pool liquidity data for token amounts calculation
  const poolState = poolStates[pool?.poolAddress as Address]

  const { total } = useMemo(
    () =>
      derivePoolLiquidity({
        token0: token0 as Token | null,
        token1: token1 as Token | null,
        sqrtPriceX96: poolState?.sqrtPriceX96,
        liquidityAvailable: poolState?.liquidity,
        liquidityLocked: poolLiquidityLocked?.liquidityLocked,
        liquidityTotal: poolLiquidityTotal?.totalSupply,
      }),
    [token0, token1, poolState, poolLiquidityLocked, poolLiquidityTotal],
  )

  useEffect(() => {
    if (token0 && tokenA !== token0) {
      onSelectTokenA(token0)
    }
    if (token1 && tokenB !== token1) {
      onSelectTokenB(token1)
    }
  }, [token0, token1, tokenA, tokenB, onSelectTokenA, onSelectTokenB])

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, pool?.decimals)
    : 0n

  const { parsedAllowance: parsedLpTokenAllowance, fetchAllowance } =
    useErc20TokenAllowance(
      poolAddress as Address,
      Number(pool?.decimals),
      address,
      V1_ROUTER_ADDRESS[chainId],
      chainId,
    )

  useEffect(() => {
    if (poolAddress && address) {
      ;(async () => {
        await fetchAllowance()
      })()
    }
  }, [chainId, poolAddress, address, fetchAllowance])

  const isLpTokenApproved = useMemo(() => {
    if (_.isUndefined(parsedLpTokenAllowance)) return false
    return parseFloat(inputValue) <= parseFloat(parsedLpTokenAllowance)
  }, [parsedLpTokenAllowance, inputValue])

  const selectedPool = {
    token0: pool?.token0 as Token,
    token1: pool?.token1 as Token,
    maintenance: pool?.maintenance,
    oracleAddress: pool?.oracleAddress,
  }

  const removeLiquidityParams = validateRemoveLiquidityParams(
    selectedPool,
    tokenA,
    tokenB,
    pool?.decimals,
    inputValue,
    address ?? zeroAddress,
  )
    ? constructRemoveLiquidityParams(
        inputValue,
        tokenA as Token,
        tokenB as Token,
        pool?.decimals as string,
        pool?.maintenance as bigint,
        pool?.oracleAddress as string,
        address ?? zeroAddress,
        transactionDeadline,
        currentBlockTimestamp,
      )
    : null

  useEffect(() => {
    // Return cleanup function that runs when component unmounts
    return () => {
      // Reset state when navigating away from the RemoveLiquidity page
      onResetRemoveLiquidityInput()
      dispatch(resetRemoveLiquidityQuote())
    }
  }, [chainId, dispatch, onResetRemoveLiquidityInput])

  // Fetch the remove liquidity quote when input and pool changes
  useEffect(() => {
    if (
      !removeLiquidityParams ||
      !inputValue ||
      !parsedLpTokenBalance ||
      parseFloat(parsedLpTokenBalance) <= 0
    ) {
      dispatch(resetRemoveLiquidityQuote())
      return
    }

    dispatch(
      fetchRemoveLiquidityQuote({
        chainId,
        quoterAddress: V1_QUOTER_ADDRESS[chainId],
        params: removeLiquidityParams,
      }),
    )
  }, [dispatch, chainId, inputValue, parsedLpTokenBalance, removeLiquidityParams]) // eslint-disable-line react-hooks/exhaustive-deps

  const { derivedRemoveLiquidityParamsWithSlippage, token0Amount, token1Amount } =
    deriveRemoveLiquidityQuoteData(
      removeLiquidityQuote,
      token0,
      token1,
      pool?.decimals?.toString(),
      maxSlippage,
      removeLiquidityParams,
    )

  const {
    state: {
      // showConfirm,
      isPendingWallet,
      isPendingApprove,
      // isPendingTx,
      // isTxSubmitted,
      // txHash,
    },
    openConfirmModal,
    // closeConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    dispatch(resetRemoveLiquidityState())
    setPercentage(0)
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }
    try {
      openConfirmModal()
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      setPendingApprove(false)
      setTxSubmitted(true, txHash)

      toast(
        <Toast type="success" header={`Approved ${lpTokenSymbol}`}>
          <Link
            className="flex w-fit items-center justify-center text-sm text-marginalGray-200 hover:underline focus:outline-none"
            to={getExplorerLink(chainId, txHash, ExplorerDataType.TRANSACTION)}
            target="_blank"
          >
            View on explorer
            <ArrowOutgoingIcon className="ml-2" />
          </Link>
        </Toast>,
        {
          autoClose: 10000,
        },
      )
      fetchAllowance()
    } catch (error) {
      console.error("Error approving token: ", error)
      if (isTransactionReceiptError(error)) {
        resetTransactionState()
        fetchAllowance()
      } else {
        toast(
          <Toast type="error" header="Transaction Error">
            <p className="text-sm text-marginalGray-600">
              There was an issue with your transaction.
            </p>
          </Toast>,
        )
      }
    }
  }

  const handleRemoveLiquidityCallback = async () => {
    try {
      if (!derivedRemoveLiquidityParamsWithSlippage) {
        return new Error("Missing required parameters for remove liquidity")
      }
      openConfirmModal()
      setPendingWallet(true)
      const transaction = await removeLiquidity(
        derivedRemoveLiquidityParamsWithSlippage,
        chainId,
      )
      setPendingWallet(false)
      setTxSubmitted(true, transaction.transactionHash)
      fetchTokenBalances()

      toast(
        <Toast type="success" header="Removed Liquidity">
          <p className="text-sm text-marginalGray-600">
            Removed {token0Amount.formatted} {token0?.symbol} and {token1Amount.formatted}{" "}
            {token1?.symbol}
          </p>
          <Link
            className="flex w-fit items-center justify-center text-sm text-marginalGray-200 hover:underline focus:outline-none"
            to={getExplorerLink(
              chainId,
              transaction.transactionHash,
              ExplorerDataType.TRANSACTION,
            )}
            target="_blank"
          >
            View on explorer
            <ArrowOutgoingIcon className="ml-2" />
          </Link>
        </Toast>,
        {
          autoClose: 10000,
        },
      )
      onSuccessReset()
      return transaction.transactionHash
    } catch (error) {
      if (getTransactionError(error) === "Wallet rejected transaction.") {
        resetTransactionState()
      } else {
        console.error("Error executing remove liquidity: ", error)
      }

      toast(
        <Toast type="error" header="Transaction Error">
          <p className="text-sm text-marginalGray-600">
            There was an issue with your transaction
          </p>
        </Toast>,
      )
    }
  }

  return (
    <Card>
      {/* <div className="flex justify-between">
        <div
          onClick={() => {
            setPercentage(0)
            onUserInput("")
            onNavigateToPool(poolAddress as string)
          }}
          className="flex justify-start items-center space-x-1 cursor-pointer text-marginalGray-200"
        >
          <CaretLeftIcon />
          <span className="text-sm text-marginalGray-200">Back to Pool</span>
        </div>
      </div> */}

      <div className="relative w-full space-y-2 rounded bg-marginalGray-900">
        <div className="relative">
          <div className="space-y-2 border-marginalGray-800 bg-marginalGray-900">
            <InputContainer id="remove-liquidity-input-container">
              <div>
                <RemoveLiquidityInput
                  setPercentage={setPercentage}
                  inputValue={inputValue}
                  totalLiquidity={parsedLpTokenBalance}
                  onChange={onUserInput}
                  setPercentError={setPercentError}
                />
                <div className="flex space-x-2 text-xs">
                  <div className="whitespace-nowrap text-marginalGray-600">
                    balance:{" "}
                    {parseFloat(parsedLpTokenBalance || "0") > 0
                      ? formatNumberAmount(parsedLpTokenBalance)
                      : "0.0"}
                  </div>
                  {parsedLpTokenBalance && parseFloat(parsedLpTokenBalance) > 0 && (
                    <div
                      className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                      onClick={() => {
                        onUserInput(parsedLpTokenBalance)
                        setPercentage(100)
                      }}
                    >
                      Max
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="whitespace-nowrap text-white">{lpTokenSymbol}</div>

                {/* <div className="flex space-x-2 text-sm">
                  <div className="whitespace-nowrap text-marginalGray-600">
                    balance: {parseFloat(parsedLpTokenBalance || "0") > 0 ? trimTrailingZeroes(shortenedParsedLpTokenBalance) : "0.0"}
                  </div>
                  <div
                    className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                    onClick={() => {
                      if (Number(parsedLpTokenBalance) > 0) {
                        onUserInput(parsedLpTokenBalance ? parsedLpTokenBalance : "")
                        setPercentage(100)
                      }
                    }}
                  >
                    max
                  </div>
                </div> */}
              </div>
            </InputContainer>

            <PercentRangeSlider
              userInput={inputValue}
              isInputValid={isInputValid}
              formattedBalance={parsedLpTokenBalance}
              margin={lpTokenBalance}
              decimals={Number(pool?.decimals)}
              onUserInput={onUserInput}
              selectedPercentage={percentage}
              setPercentage={setPercentage}
              setPercentError={setPercentError}
              percentError={percentError}
            />

            <div className="flex justify-end">
              <SlippageButton
                maxSlippage={maxSlippage}
                showSettings={showSettings}
                onClose={onCloseSettings}
                onOpen={onOpenSettings}
              />
            </div>

            <div className="space-y-2 py-2 text-sm text-marginalGray-600">
              <ListRow
                item={`Current ${token0?.symbol}`}
                value={
                  <div className="flex items-center space-x-1 text-white">
                    <span>
                      {total?.token0?.parsed && userSharePercentageOfTotal
                        ? `${formatNumberAmount(
                            parseFloat(total.token0.parsed).toString(),
                            true,
                          )}`
                        : token0Amount.formatted
                          ? `${token0Amount.formatted}`
                          : "-"}
                    </span>
                    {token0 && (
                      <>
                        <span className="font-bold">{token0?.symbol}</span>
                        <TokenLogo
                          symbol={token0?.symbol}
                          imgUrl={token0?.imgUrl}
                          size={4}
                        />
                      </>
                    )}
                  </div>
                }
              />
              <ListRow
                item={`Current ${token1?.symbol}`}
                value={
                  <div className="flex items-center space-x-1 text-white">
                    <span>
                      {total?.token1?.parsed && userSharePercentageOfTotal
                        ? `${formatNumberAmount(
                            parseFloat(total.token1.parsed).toString(),
                            true,
                          )}`
                        : token1Amount.formatted
                          ? `${token1Amount.formatted}`
                          : "-"}
                    </span>
                    {token1 && (
                      <>
                        <span className="font-bold">{token1?.symbol}</span>
                        <TokenLogo
                          symbol={token1?.symbol}
                          imgUrl={token1?.imgUrl}
                          size={4}
                        />
                      </>
                    )}
                  </div>
                }
              />
              {userLpBalance?.parsedBalance && (
                <ListRow
                  item="Current shares"
                  value={
                    <div className="text-white">
                      {userLpBalance.parsedBalance} {lpTokenSymbol}
                    </div>
                  }
                />
              )}

              {Boolean(token0Amount.raw && token0Amount.raw > 0n) && percentage > 0 && (
                <ListRow
                  item={`Remove ${token0?.symbol}`}
                  value={
                    <div className="flex items-center space-x-1 text-red-500">
                      <span>
                        {token0Amount.formatted ? `- ${token0Amount.formatted}` : "-"}
                      </span>
                      {token0 && (
                        <>
                          <span className="font-bold">{token0?.symbol}</span>
                          <TokenLogo
                            symbol={token0?.symbol}
                            imgUrl={token0?.imgUrl}
                            size={4}
                          />
                        </>
                      )}
                    </div>
                  }
                />
              )}
              {Boolean(token1Amount.raw && token1Amount.raw > 0n) && percentage > 0 && (
                <ListRow
                  item={`Remove ${token1?.symbol}`}
                  value={
                    <div className="flex items-center space-x-1 text-red-500">
                      <span>
                        {token1Amount.formatted ? `- ${token1Amount.formatted}` : "-"}
                      </span>
                      {token1 && (
                        <>
                          <span className="font-bold">{token1?.symbol}</span>
                          <TokenLogo
                            symbol={token1?.symbol}
                            imgUrl={token1?.imgUrl}
                            size={4}
                          />
                        </>
                      )}
                    </div>
                  }
                />
              )}
              {userLpBalance?.parsedBalance && percentage > 0 && (
                <ListRow
                  item="Remove shares"
                  value={
                    <div className="text-red-500">
                      -{" "}
                      {formatNumberAmount(
                        (
                          parseFloat(userLpBalance.parsedBalance) *
                          (percentage / 100)
                        ).toString(),
                        true,
                      )}{" "}
                      {lpTokenSymbol}
                    </div>
                  }
                />
              )}
              {userLpBalance?.parsedBalance && percentage > 0 && (
                <ListRow
                  item="New total shares"
                  value={
                    <div className="text-white">
                      {(
                        parseFloat(userLpBalance.parsedBalance) *
                        (1 - percentage / 100)
                      ).toString()}{" "}
                      {lpTokenSymbol}
                    </div>
                  }
                />
              )}
            </div>

            <ConfirmRemoveLiquidityButton
              chainId={chainId}
              isInputValid={isInputValid}
              isTokensValid={isTokensValid}
              isTokenApproved={isLpTokenApproved}
              isPendingWallet={isPendingWallet}
              isPendingApprove={isPendingApprove}
              onApprove={() =>
                approveToken(
                  formattedInput,
                  V1_ROUTER_ADDRESS[chainId],
                  poolAddress as Address,
                )
              }
              tokenA={tokenA}
              tokenB={tokenB}
              onConfirm={openConfirmModal}
              removeLiquidityCallback={handleRemoveLiquidityCallback}
              error={removeLiquidityError}
              totalLiquidity={parsedLpTokenBalance}
            />
          </div>
        </div>

        {/* <TransactionProgressModal
          chainId={chainId}
          open={showConfirm}
          onOpen={openConfirmModal}
          onClose={closeConfirmModal}
          onReset={onSuccessReset}
          onCallback={handleRemoveLiquidityCallback}
          isPendingWallet={isPendingWallet}
          isPendingApprove={isPendingApprove}
          isPendingTx={isPendingTx}
          isTxSubmitted={isTxSubmitted}
          txHash={txHash}
          // txError={txError}
          hasConfirmModal={false}
          onSuccessText="Transaction Success"
        /> */}
      </div>
    </Card>
  )
}

export default RemoveLiquidity
