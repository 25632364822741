import { NavLink } from "react-router-dom"
import { MarginalIcon } from "src/components/Icons/MarginalIcon"
import { ConnectWalletBtn } from "src/components/Header/ConnectWalletButton"
import { NAV_LINKS } from "src/constants/links"

export const Header = () => {
  return (
    <header className="hidden h-[50px] w-full items-center bg-marginalGray-950 lg:flex">
      <a
        href="https://marginal.network/"
        className="flex h-full items-center gap-2 border-b border-r border-marginalGray-200/20 fill-marginalGray-200 px-6 py-3 outline-none transition-all hover:bg-marginalGray-900 hover:fill-white hover:text-white focus-visible:bg-marginalGray-950 focus-visible:fill-white focus-visible:text-white"
      >
        <MarginalIcon className="w-8 fill-inherit" />
        <h1 className="relative -top-[1px] text-lg font-bold leading-none">Marginal</h1>
      </a>

      <div className="flex h-full">
        {Object.entries(NAV_LINKS).map(([label, href], i) => {
          return (
            <div className="box-border flex border-r border-marginalGray-200/20">
              <NavLink
                key={i}
                to={href}
                className={({ isActive }) =>
                  `${isActive ? "border-b-2 border-b-marginalOrange-500 bg-marginalOrange-800 text-marginalOrange-500 hover:brightness-110 focus-visible:brightness-110" : "border-b border-b-marginalGray-200/20 bg-transparent hover:bg-marginalGray-900 hover:text-white focus-visible:bg-marginalGray-900 focus-visible:text-white"} box-border flex w-min cursor-pointer items-center gap-2 text-nowrap px-6 py-3 text-sm outline-none transition-all`
                }
              >
                {label}
              </NavLink>
            </div>
          )
        })}
      </div>

      <div className="flex h-full w-full border-b border-marginalGray-200/20"></div>

      <div className="ml-auto flex h-full border-b border-marginalGray-200/20 pl-4">
        <ConnectWalletBtn />
      </div>
    </header>
  )
}
