export const LANDING_URL = "https://marginal.network"
export const DOCS_URL = "https://marginal.gitbook.io/docs"

export const MARGINAL_URLS = {
  Audits: "https://github.com/MarginalProtocol/v1-core/tree/main/audits",
  // Discord: "",
  Docs: "https://marginal.gitbook.io/docs",
  GitHub: "https://github.com/MarginalProtocol",
  Landing: "https://marginal.network",
  Telegram: "t.me/marginal_dex",
  Twitter: "https://x.com/MarginalDEX",
  Whitepaper: "https://github.com/MarginalProtocol/v1-core/blob/main/wp/v1.pdf",
}

export const NAV_LINKS = {
  Trade: "/",
  Swap: "/swap",
  Liquidity: "/pools",
  Positions: "/positions",
  // Transactions: "/transactions",
}

export const LEGAL_LINKS = {
  Terms: "/terms-of-use",
  Privacy: "/privacy-policy",
}

export const RESOURCES_LINKS = {
  Audits: MARGINAL_URLS.Audits,
  Docs: MARGINAL_URLS.Docs,
  GitHub: MARGINAL_URLS.GitHub,
  Whitepaper: MARGINAL_URLS.Whitepaper,
}

export const CONTACT_LINKS = {
  Telegram: MARGINAL_URLS.Telegram,
  Twitter: MARGINAL_URLS.Twitter,
}
