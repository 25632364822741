import _ from "lodash"
import { useReadContracts } from "wagmi"
import { Address } from "viem"
import { PoolData } from "src/types"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import MarginalV1PoolAbi from "src/constants/abis/MarginalV1Pool.json"

export const useUserPoolBalance = (
  pools: (PoolData | undefined | null)[],
  owner?: string,
) => {
  const contracts = createContractReads(pools, owner)
  const isEnabled = contracts.length > 0 && Boolean(owner)

  const { data, refetch, isError, isLoading } = useReadContracts({
    contracts,
    query: {
      enabled: isEnabled,
    },
  })

  const balances = data?.map((fetchedBalance, index) =>
    formatBalance(fetchedBalance?.result as bigint, pools[index]?.decimals, pools[index]),
  )

  return {
    balances,
    refetch,
    isError,
    isLoading,
  }
}

interface ContractReadConfig {
  address: Address
  abi: any
  functionName: "balanceOf"
  args: [Address]
}

const createContractRead = (
  poolAddress: Address,
  ownerAddress: Address,
): ContractReadConfig => ({
  address: poolAddress,
  abi: MarginalV1PoolAbi,
  functionName: "balanceOf",
  args: [ownerAddress],
})

const createContractReads = (
  pools: (PoolData | undefined | null)[],
  ownerAddress?: string,
): ContractReadConfig[] => {
  if (!ownerAddress) return []

  return pools
    .filter((pool): pool is PoolData => !_.isEmpty(pool))
    .map((pool) =>
      createContractRead(pool.poolAddress as Address, ownerAddress as Address),
    )
}

export interface PoolBalance {
  pool: PoolData | undefined | null
  balance: bigint
  parsedBalance?: string
}

const formatBalance = (
  rawBalance: bigint,
  decimals?: string,
  pool?: PoolData | null,
): PoolBalance => {
  const parsedBalance = formatBigIntToString(rawBalance, decimals, 18)

  return {
    pool,
    balance: rawBalance,
    parsedBalance,
  }
}
