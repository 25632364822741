import { useAccount } from "wagmi"
import { switchChain } from "@wagmi/core"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import { isSupportedChain, DEFAULT_CHAIN_ID } from "src/constants/chains/chains"
import { ActionButton } from "src/components/ui/ActionButton"
import { wagmiConfig } from "src/wagmiConfig"

interface Props {
  chainId: number
  isInputValid: boolean
  isTokenValid: boolean
  isTokenApproved: boolean
  isBalanceSufficient: boolean | undefined
  isPendingWallet: boolean
  isPendingApprove: boolean
  tokenSymbol: string | undefined
  onApproveToken: () => Promise<void>
  onConfirm: () => void
  stakeCallback: (() => Promise<any>) | undefined
  error: any
}

export const ConfirmStakeButton = ({
  chainId,
  isInputValid,
  isTokenValid,
  isTokenApproved,
  isBalanceSufficient,
  isPendingWallet,
  isPendingApprove,
  tokenSymbol,
  onApproveToken,
  onConfirm,
  stakeCallback,
  error,
}: Props) => {
  const { address } = useAccount()
  const { openConnectModal } = useConnectModal()
  const isValidNetwork = isSupportedChain(chainId)

  const executeNetworkSwitch = () => {
    switchChain(wagmiConfig, { chainId: DEFAULT_CHAIN_ID })
  }

  if (!address) {
    return <ActionButton action="Connect wallet" onClick={openConnectModal} />
  }
  if (!isValidNetwork) {
    return <ActionButton action="Switch network" onClick={executeNetworkSwitch} />
  }
  if (isPendingWallet) {
    return <ActionButton action="Confirm in wallet" disabled />
  }

  if (isPendingApprove) {
    return <ActionButton action="Approving..." disabled={true} />
  }

  if (error) {
    return <ActionButton action={error} disabled={true} />
  }

  if (!isInputValid) {
    return <ActionButton action="Enter amount" disabled={true} />
  }

  if (!isTokenValid) {
    return <ActionButton action="Select token" disabled={true} />
  }

  if (!isBalanceSufficient) {
    return <ActionButton action="Insufficient balance" disabled={true} />
  }

  if (!isTokenApproved) {
    return (
      <ActionButton
        action={`Approve ${tokenSymbol} token`}
        onClick={onApproveToken && (() => onApproveToken())}
        disabled={!onApproveToken}
      />
    )
  }

  return (
    <ActionButton
      action="Stake"
      onClick={stakeCallback && (() => stakeCallback())}
      disabled={!stakeCallback}
    />
  )
}
