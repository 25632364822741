import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline"
// import { memo, useId } from "react"
import { CircleInfoIcon } from "src/components/Icons/CircleInfoIcon"
import { ToastContentProps } from "react-toastify"

interface ToastProps extends Partial<ToastContentProps> {
  header: string
  type?: "info" | "success" | "error"
  children?: React.ReactNode
}

export const Toast = ({
  header,
  type = "info",
  closeToast,
  toastProps,
  children,
}: ToastProps) => {
  // const startTime = Date.now()
  // const duration = Number(toastProps?.autoClose || 0) / 1000 // Convert ms to seconds

  return (
    <div className="flex max-w-72 flex-col items-start space-y-3 break-words bg-marginalBlack">
      <div className="flex w-full justify-between gap-8">
        <div className="flex items-center gap-1.5">
          {type === "info" && (
            <CircleInfoIcon className="h-5 w-5 stroke-marginalGray-600" />
          )}

          {type === "success" && <CheckCircleIcon className="h-5 w-5 stroke-green-500" />}

          {type === "error" && (
            <ExclamationCircleIcon className="h-5 w-5 stroke-red-500" />
          )}
          <h1 className="tracking-wide text-white">{header}</h1>
        </div>

        <div className="flex items-center space-x-2">
          {/* {type == "success" && <CircularProgress duration={duration} />} */}
          <button
            onClick={closeToast}
            className="stroke-marginalGray-600 transition-all hover:stroke-white"
          >
            <XMarkIcon className="h-5 w-5 stroke-inherit" />
          </button>
        </div>
      </div>

      {children}
    </div>
  )
}

// const CircularProgress = memo(
//   ({ duration, size = 14 }: { duration: number; size?: number }) => {
//     const uniqueId = useId()
//     const strokeWidth = 2
//     const radius = (size - strokeWidth) / 2
//     const circumference = 2 * Math.PI * radius

//     return (
//       <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} className="flex">
//         <defs>
//           <clipPath id={`${uniqueId}-clip`}>
//             <circle cx={size / 2} cy={size / 2} r={radius} />
//           </clipPath>
//         </defs>

//         {/* Background Circle */}
//         <circle
//           cx={size / 2}
//           cy={size / 2}
//           r={radius}
//           strokeWidth={strokeWidth}
//           fill="none"
//           className="stroke-marginalGray-600"
//         />

//         {/* Progress Circle */}
//         <circle
//           cx={size / 2}
//           cy={size / 2}
//           r={radius}
//           strokeWidth={strokeWidth}
//           fill="none"
//           className="stroke-marginalGray-600"
//           strokeDasharray={circumference}
//           strokeLinecap="round"
//           transform={`rotate(-90 ${size / 2} ${size / 2})`}
//           clipPath={`url(#${uniqueId}-clip)`}
//         >
//           <animate
//             id={`${uniqueId}-animate`}
//             attributeName="stroke-dashoffset"
//             from={circumference}
//             to="0"
//             dur={`${duration}s`}
//             fill="freeze"
//             calcMode="linear"
//           />
//         </circle>
//       </svg>
//     )
//   },
// )
