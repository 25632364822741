import { ComponentProps } from "react"

export const CircleInfoIcon = (props: ComponentProps<"svg">) => (
  <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_104_2977)">
      <path
        d="M7.00014 9.33348V7.00014M7.00014 4.66681H7.00598M12.8335 7.00014C12.8335 10.2218 10.2218 12.8335 7.00014 12.8335C3.77848 12.8335 1.16681 10.2218 1.16681 7.00014C1.16681 3.77848 3.77848 1.16681 7.00014 1.16681C10.2218 1.16681 12.8335 3.77848 12.8335 7.00014Z"
        strokeWidth="1.16375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_104_2977">
        <rect width="14" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
