// import { useState } from "react"
import { isUndefined, isNull } from "lodash"
import { type PoolData, type Token } from "src/types"
import { ListRow } from "src/components/ui/ListRow"
import { TokenBadge, DoubleTokenLogo } from "src/components/TokenBadge"
import { getPriceImpactIndicatorColor } from "src/pages/trade/helpers/getPriceImpactIndicatorColor"
// import { RotatingActiveArrow } from "src/components/ui/RotatingActiveArrow"
import { TokensExchangeRateView } from "src/components/TokensExchangeRateView"
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"
import { HealthFactorIcon } from "src/components/Icons/HealthFactorIcon"
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline"

interface Props {
  pool: PoolData | null
  token0: Token | null
  token1: Token | null
  marginToken: Token | null
  debtToken: Token | null
  poolMaxLeverage: number | undefined | null
  leverage: number

  quotedDebt: string | null | undefined
  poolPrice: string | null | undefined
  oraclePrice: string | null | undefined
  liquidationPrice: string | null | undefined

  fundingRate: string | number | null | undefined
  escrowRewards: string | null | undefined
  maxSlippage: string
  priceImpact: number | null
  healthFactor: number | null

  useInverse: boolean
  onToggleInverse: () => void
}

export const TradeDetailList = ({
  pool,
  token0,
  token1,
  marginToken,
  debtToken,
  poolMaxLeverage,
  leverage,

  quotedDebt,
  poolPrice,
  oraclePrice,
  liquidationPrice,

  fundingRate,
  escrowRewards,
  maxSlippage,
  priceImpact,
  healthFactor,

  useInverse,
  onToggleInverse,
}: Props) => {
  // const [show, setShow] = useState(true)
  const show = true

  const priceImpactIndicator = getPriceImpactIndicatorColor(priceImpact)

  const fundingRateIndicator =
    Math.abs(Number(fundingRate)) > 1
      ? "text-warning-500"
      : Math.abs(Number(fundingRate)) > 5
        ? "text-error-500"
        : "text-marginalGray-200"

  // if (!pool) return null

  let healthFactorIndicator

  if (isNull(healthFactor)) {
    healthFactorIndicator = "text-white"
  } else if (healthFactor <= 1.25) {
    healthFactorIndicator = "text-error-500"
  } else if (healthFactor <= 1.5) {
    healthFactorIndicator = "text-warning-500"
  } else if (1.5 < healthFactor) {
    healthFactorIndicator = "text-success-500"
  }

  return (
    <div id="trade-detail-list" className="duration-175 transform-gpu rounded-lg p-4">
      <section className="space-y-3">
        <header className="flex items-center justify-between">
          <h1 className="whitespace-nowrap rounded-lg bg-marginalGray-950 px-2 py-1.5">
            Execution details
          </h1>
          {/* <button
            onClick={() => setShow(!show)}
            className="flex gap-1 justify-end items-center w-full cursor-pointer hover:opacity-60"
          >
            <span className="text-sm">{show ? "hide" : "show"}</span>
            <RotatingActiveArrow isActive={show} />
          </button> */}
        </header>
        <div className="flex items-center justify-between">
          <TokensExchangeRateView
            token0={token0}
            token1={token1}
            price={poolPrice}
            useInverse={useInverse}
            onToggleInverse={onToggleInverse}
          />
        </div>
      </section>

      <div
        className={`transform-gpu overflow-y-hidden text-sm text-marginalGray-200 transition-max-height duration-500 ease-in-out ${show ? "max-h-fit" : "max-h-0"} `}
      >
        <div className="space-y-2 py-4 text-marginalGray-400">
          <ListRow
            item="Pool"
            value={
              pool && (
                <div className="flex items-center space-x-1">
                  {token0 && token1 && (
                    <DoubleTokenLogo token0={token0} token1={token1} size={4} />
                  )}
                  <div className="flex flex-wrap items-baseline space-x-2">
                    <div className="flex items-center space-x-1 text-marginalGray-200">
                      <span>{token0?.symbol}</span>
                      <div className="my-auto">/</div>
                      <span>{token1?.symbol}</span>
                    </div>
                  </div>
                </div>
              )
            }
          />
          <ListRow
            item="Max leverage"
            value={
              poolMaxLeverage && (
                <div className="text-marginalGray-200">{poolMaxLeverage + "x"}</div>
              )
            }
          />
          {pool && (
            // <div className="flex gap-1 justify-end items-center my-2 text-xs cursor-pointer hover:text-marginalOrange-500">
            //   <a
            //     href={`https://v3.info.kodiak.finance/#/berachain_mainnet/pools/${pool?.oracleAddress}`}
            //     target="_blank"
            //     rel="noreferrer"
            //   >
            //     Kodiak V3 Liquidity
            //   </a>
            //   <ArrowTopRightOnSquareIcon height={12} />
            // </div>
            <section className="space-y-3 bg-marginalBlack py-2">
              <div className="flex gap-2">
                <ExclamationTriangleIcon height={20} />
                <span className="text-sm font-bold">Always do your research</span>
              </div>
              <p className="text-xs">
                To avoid oracle manipulation of your position, make sure the Kodiak v3
                oracle the pool references has full range liquidity of at least $250K USD.
              </p>
              {/* <div className="flex gap-1 items-center text-xs cursor-pointer hover:text-marginalOrange-500">
                <a
                  href={`https://v3.info.kodiak.finance/#/berachain_mainnet/pools/${pool?.oracleAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Kodiak v3 liquidity
                </a>
                <ArrowTopRightOnSquareIcon height={14} />
              </div> */}
              <div className="my-2 flex cursor-pointer items-center justify-end gap-1 text-xs hover:text-marginalOrange-500">
                <a
                  href={`https://v3.info.kodiak.finance/#/berachain_mainnet/pools/${pool?.oracleAddress}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Kodiak V3 Liquidity
                </a>
                <ArrowTopRightOnSquareIcon height={12} />
              </div>
            </section>
          )}
          <div className="my-3 h-px bg-marginalGray-200/20" />
          <div className="space-y-2">
            <ListRow
              item="Oracle price"
              value={
                oraclePrice && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{oraclePrice}</div>
                    {!useInverse ? (
                      <div className="flex items-center">
                        <span>{token1?.symbol}</span>
                        <div>/</div>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{token0?.symbol}</span>
                        <div>/</div>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                )
              }
            />
            <ListRow
              item="Pool price"
              value={
                poolPrice && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{poolPrice}</div>
                    {!useInverse ? (
                      <div className="flex items-center">
                        <span>{token1?.symbol}</span>
                        <div>/</div>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center">
                        <span>{token0?.symbol}</span>
                        <div>/</div>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                )
              }
            />
            <ListRow
              item="Liquidation price"
              value={
                liquidationPrice && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{liquidationPrice}</div>
                    {!useInverse ? (
                      <div className="flex items-center text-marginalGray-200">
                        <span>{token1?.symbol}</span>
                        <div>/</div>
                        <span>{token0?.symbol}</span>
                      </div>
                    ) : (
                      <div className="flex items-center text-marginalGray-200">
                        <span>{token0?.symbol}</span>
                        <div>/</div>
                        <span>{token1?.symbol}</span>
                      </div>
                    )}
                  </div>
                )
              }
            />
            <ListRow
              item="Funding rate"
              value={
                fundingRate && (
                  <div
                    className={`flex flex-wrap justify-end space-x-1 ${fundingRateIndicator}`}
                  >
                    <span>{fundingRate}% weekly</span>
                  </div>
                )
              }
            />
            <ListRow
              item="Escrow rewards"
              value={
                <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                  <span>{pool && escrowRewards ? escrowRewards : "-"} BERA</span>
                </div>
              }
            />
          </div>

          <div className="my-3 h-px bg-marginalGray-200/20" />

          <div className="space-y-2">
            <ListRow
              item="Debt"
              value={
                quotedDebt && (
                  <div className="flex flex-wrap justify-end space-x-1 text-marginalGray-200">
                    <div>{quotedDebt}</div>
                    {debtToken && (
                      <TokenBadge token={debtToken} size={4} reverse={true} />
                    )}
                  </div>
                )
              }
            />
            <ListRow
              item="Selected leverage"
              value={
                leverage && <div className="text-marginalGray-200">{leverage + "x"}</div>
              }
            />
            <ListRow
              item="Health factor"
              value={
                healthFactor && (
                  <>
                    <div className="flex items-center space-x-1">
                      <HealthFactorIcon />
                      <div className={`${healthFactorIndicator}`}>{healthFactor}</div>
                    </div>
                  </>
                )
              }
            />
            {/* <ListRow
              item="Max leverage"
              value={
                poolMaxLeverage && (
                  <div className="text-marginalGray-200">{poolMaxLeverage + "x"}</div>
                )
              }
            /> */}
            <ListRow
              item="Impact"
              value={
                priceImpact && (
                  <div className="flex flex-wrap justify-end space-x-1">
                    <div className={priceImpactIndicator}>{-priceImpact.toFixed(2)}%</div>
                  </div>
                )
              }
            />
            <ListRow
              item="Max slippage"
              value={
                maxSlippage && (
                  <div className="flex flex-wrap items-center justify-end space-x-1 text-marginalGray-200">
                    {maxSlippage === "0.5" && (
                      <div className="rounded bg-marginalGray-800 px-1 py-0.5 text-xs">
                        Auto
                      </div>
                    )}
                    <div>{maxSlippage}%</div>
                  </div>
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export function calculateInversePrice(price: string | undefined) {
  if (isUndefined(price)) return undefined

  const priceFloat = parseFloat(price)

  if (isNaN(priceFloat)) {
    throw new Error("Invalid price input")
  }

  const inversePrice = 1 / priceFloat

  return inversePrice.toString()
}
