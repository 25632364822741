import React, { useState, useRef } from "react"
import { InformationCircleIcon } from "@heroicons/react/24/outline"

interface InformationHoverTipProps {
  /**
   * The text to display in the tooltip
   */
  text: string
  /**
   * Optional icon size in pixels (defaults to 16)
   */
  iconSize?: number
  /**
   * Optional CSS class to apply to the icon
   */
  iconClassName?: string
  /**
   * Optional CSS class to apply to the tooltip
   */
  tooltipClassName?: string
  /**
   * Optional position of the tooltip (defaults to 'top')
   */
  position?: "top" | "bottom" | "right" | "left"
}

/**
 * A component that displays an information icon with a tooltip on hover
 */
export const InformationHoverTip: React.FC<InformationHoverTipProps> = ({
  text,
  iconSize = 16,
  iconClassName = "",
  tooltipClassName = "",
  position = "top",
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const iconRef = useRef<HTMLDivElement>(null)
  
  return (
    <div className="relative inline-flex items-center">
      <div
        ref={iconRef}
        className={`inline-flex cursor-help ${iconClassName}`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        aria-describedby="tooltip"
      >
        <InformationCircleIcon
          className={`h-${iconSize / 4} w-${iconSize / 4}`}
          style={{ height: iconSize, width: iconSize }}
        />
      </div>
      
      {isHovered && (
        <div
          ref={tooltipRef}
          role="tooltip"
          className={`
            absolute z-50 pointer-events-none
            w-52 rounded-md bg-marginalGray-800 px-3 py-2 text-xs 
            text-marginalGray-100 shadow-lg
            ${position === "top" ? "bottom-full mb-2" : ""}
            ${position === "bottom" ? "top-full mt-2" : ""}
            ${position === "left" ? "right-full mr-2" : ""}
            ${position === "right" ? "left-full ml-2" : ""}
            ${tooltipClassName}
          `}
          style={{
            left: position === "top" || position === "bottom" ? 
              "50%" : position === "right" ? "100%" : "auto",
            right: position === "left" ? "100%" : "auto",
            transform: (position === "top" || position === "bottom") ? 
              "translateX(-50%)" : "",
          }}
        >
          {/* Tooltip arrow */}
          {position === "top" && (
            <div className="absolute -bottom-2 left-1/2 -ml-2 h-0 w-0 
                border-x-8 border-t-8 border-x-transparent border-t-marginalGray-800"></div>
          )}
          {position === "bottom" && (
            <div className="absolute -top-2 left-1/2 -ml-2 h-0 w-0 
                border-x-8 border-b-8 border-x-transparent border-b-marginalGray-800"></div>
          )}
          {position === "left" && (
            <div className="absolute top-1/2 -mt-2 -right-2 h-0 w-0 
                border-y-8 border-l-8 border-y-transparent border-l-marginalGray-800"></div>
          )}
          {position === "right" && (
            <div className="absolute top-1/2 -mt-2 -left-2 h-0 w-0 
                border-y-8 border-r-8 border-y-transparent border-r-marginalGray-800"></div>
          )}
          {text}
        </div>
      )}
    </div>
  )
}
