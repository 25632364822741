import { ReactNode } from "react"

export const TableHeaderCell = ({
  align = "right",
  children,
}: {
  align?: "right" | "left"
  children?: ReactNode
}) => {
  return (
    <th
      className={`whitespace-pre px-2 py-3 text-xs text-marginalGray-200 lg:whitespace-nowrap ${align === "right" ? "text-right" : "text-left"} `}
    >
      {children}
    </th>
  )
}

export const TableCell = ({ children }: { children?: ReactNode }) => {
  return (
    <td className="whitespace-pre px-2 py-3 text-right text-sm text-marginalGray-200 first:text-left lg:whitespace-nowrap">
      {children}
    </td>
  )
}

export const MobileTableCell = ({
  label,
  children,
}: {
  label?: string
  children: ReactNode
}) => {
  return (
    <div className="flex w-full flex-col space-y-1 text-xs text-marginalGray-200">
      {label && <span className="text-marginalGray-600">{label}:</span>}
      <div className="flex items-center">{children}</div>
    </div>
  )
}
