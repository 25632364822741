import { useState, useEffect, useRef } from "react"
import { useParams, useNavigate } from "react-router-dom"
import AddLiquidity from "../addLiquidity/AddLiquidity"
import RemoveLiquidity from "../removeLiquidity/RemoveLiquidity"
// Tab import removed as we're using vanilla HTML implementation
import { useMemo } from "react"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { useDispatch } from "react-redux"
import { getPoolDataByAddress, usePoolsContextData } from "src/providers/PoolsProvider"
import { useAccount } from "wagmi"
import { type PoolData, type Token } from "src/types"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { type Address, zeroAddress } from "viem"
import { getReward } from "src/utils/actions/getReward"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { WBERA } from "src/constants/tokens"
import {
  resetAddLiquidityQuote,
  setInputValueA as setInputValueAAddLiquidity,
  setInputValueB as setInputValueBAddLiquidity,
} from "src/state/addLiquidity/reducer"
import {
  resetRemoveLiquidityQuote,
  setInputValue as setInputValueRemoveLiquidity,
} from "src/state/removeLiquidity/reducer"
import { useUserRewardsBalance } from "src/hooks/useUserRewardsBalance"
import { useTotalSupplyInPools } from "src/pages/pools/hooks/useTotalSupplyInPools"
import { useLiquidityLockedInPools } from "src/pages/pools/hooks/useLiquidityLockedInPools"
import { useCurrentPoolsState } from "src/pages/pools/hooks/useCurrentPoolsState"
import { useUserPoolBalance } from "src/pages/pool/hooks/useUserPoolBalance"
import { calculatePercentageOfTotal } from "src/utils/conversions/calculatePercentageOfTotal"
import { useUserStakedPoolBalance } from "src/pages/pool/hooks/useUserStakedPoolBalance"
import { derivePoolLiquidity } from "src/pages/pool/helpers/derivePoolLiquidity"
import { useLivePoolPrices } from "src/pages/trade/hooks/useLivePoolPrices"
import { extrapolateTokenPair } from "src/utils/extrapolateTokenPair"
import { useApplicationState } from "src/state/application/hooks"
import { getTokenByAddress } from "src/constants/tokenList"
import { useMultiplePoolRewardsAPR } from "src/hooks/useMultiplePoolRewardsAPR"
import { DoubleTokenLogo, TokenLogo } from "src/components/TokenBadge"
import { shortenAddress } from "src/utils/shortenAddress"
import { Link } from "react-router-dom"
import { DocumentTextIcon } from "src/components/Icons/DocumentTextIcon"
import { ShieldCheckIcon, ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { TokenBadge } from "src/components/TokenBadge"
import { CircularProgressBar } from "src/pages/pools/components"
import { ArrowLeftIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline"
import { InformationHoverTip } from "src/components/InformationHoverTip"
import MarginalLogo from "src/assets/logos/marginal.svg"
import { CustomConnectButton } from "src/components/Header/CustomConnectButton"
import { getExplorerLink, ExplorerDataType } from "src/utils/getExplorerLink"
import { useAddLiquidityState } from "src/state/addLiquidity/hooks"
import { useRemoveLiquidityState } from "src/state/removeLiquidity/hooks"

const _Pool = () => {
  const { poolAddress } = useParams<{ poolAddress: string }>()
  const { addLiquidityQuote } = useAddLiquidityState()
  const { inputValue: removeLiquidityInputValue } = useRemoveLiquidityState()
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(0)
  const dispatch = useDispatch()
  const prevTabRef = useRef(activeTab)

  // Function to reset all liquidity states
  const resetAllLiquidityStates = useRef(() => {
    if (addLiquidityQuote) {
      dispatch(resetAddLiquidityQuote())
      dispatch(setInputValueAAddLiquidity(""))
      dispatch(setInputValueBAddLiquidity(""))
    }
    if (removeLiquidityInputValue) {
      dispatch(resetRemoveLiquidityQuote())
      dispatch(setInputValueRemoveLiquidity(""))
    }
  }).current

  // Reset states when tab changes
  useEffect(() => {
    if (prevTabRef.current !== activeTab) {
      resetAllLiquidityStates()
      prevTabRef.current = activeTab
    }
  }, [activeTab, resetAllLiquidityStates])

  // Reset states when component unmounts or poolAddress changes
  useEffect(() => {
    resetAllLiquidityStates()

    return () => {
      // Clean up when unmounting
      resetAllLiquidityStates()
    }
  }, [poolAddress, resetAllLiquidityStates])

  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { onNavigateToPools, onNavigateToStake, onNavigateToUnstake } =
    useNavigateRoutes()
  useNetworkChangeRedirect(onNavigateToPools)
  const { poolsDataByAddress } = usePoolsContextData()
  const pool = getPoolDataByAddress(poolAddress as string, poolsDataByAddress)
  const poolsTotalSupply = useTotalSupplyInPools([pool])
  const liquidityLockedInPools = useLiquidityLockedInPools([pool])
  const { balances, refetch: refetchUserPoolBalance } = useUserPoolBalance(
    [pool],
    address,
  )
  const { poolStates } = useCurrentPoolsState([pool?.poolAddress as Address])
  const { balances: userStakedBalance } = useUserStakedPoolBalance(
    [pool as PoolData],
    address,
  )

  const poolState = poolStates[pool?.poolAddress as Address]
  const userLpBalance = balances?.[0]
  const userStakedLpBalance = userStakedBalance?.[pool?.stakePool as Address]
  const userTotalLpBalance = (
    parseFloat(userLpBalance?.parsedBalance ?? "0") +
    parseFloat(userStakedLpBalance?.parsedBalance ?? "0")
  ).toString()

  const poolLiquidityAvailable = poolState
  const poolLiquidityLocked = liquidityLockedInPools?.[0]
  const poolLiquidityTotal = poolsTotalSupply?.[0]

  const userSharePercentageOfTotal = calculatePercentageOfTotal({
    total: parseFloat(poolLiquidityTotal?.parsedTotalSupply ?? "0"),
    partial: parseFloat(userTotalLpBalance ?? "0"),
  })
  const poolUtilization = calculatePercentageOfTotal({
    total: parseFloat(poolLiquidityTotal?.parsedTotalSupply ?? "0"),
    partial: parseFloat(poolLiquidityLocked?.parsedLockedLiquidity ?? "0"),
  })

  const { rewards } = useUserRewardsBalance(
    pool?.stakePool as Address,
    address,
    pool?.rewardTokens ?? [],
  )

  const token0 = getTokenByAddress(pool?.token0?.address, chainId) ?? pool?.token0
  const token1 = getTokenByAddress(pool?.token1?.address, chainId) ?? pool?.token1
  const { quoteToken } = extrapolateTokenPair(token0 ?? null, token1 ?? null, chainId)

  const { token0PriceInUSD, token1PriceInUSD } = useLivePoolPrices({
    chainId,
    selectedPool: pool,
    zeroForOne: true,
    useInverse: true,
    quoteToken,
    position: undefined,
  })

  const { available, locked, total } = derivePoolLiquidity({
    token0: token0 ?? null,
    token1: token1 ?? null,
    sqrtPriceX96: poolState?.sqrtPriceX96,
    liquidityAvailable: poolLiquidityAvailable?.liquidity,
    liquidityLocked: poolLiquidityLocked?.liquidityLocked,
    liquidityTotal: poolLiquidityTotal?.totalSupply,
  })

  const lpTokenSymbol = useErc20TokenSymbol(poolAddress as Address)

  const durationOneYearInSeconds = 86400 * 365

  // Create reward token configs with their corresponding pool addresses
  const rewardConfigs = useMemo(() => {
    if (!pool?.rewardTokens) return []
    return pool.rewardTokens.map((rewardToken) => ({
      rewardToken,
      rewardsPoolWithWETH9:
        rewardToken === WBERA.address ? zeroAddress : (pool.poolAddress as Address),
    }))
  }, [pool])

  const hasClaimableRewards = rewards.some(
    (reward) => parseFloat(reward.formattedBalance || "0") > 0,
  )

  const hasStakeFlow = pool?.stakePool && rewards.length > 0
  const hasStakedLpTokens = parseFloat(userStakedLpBalance?.parsedBalance || "0") > 0

  const { totalAprPercentage } = useMultiplePoolRewardsAPR(
    poolAddress as Address,
    rewardConfigs,
    durationOneYearInSeconds,
    chainId,
  )

  const tvl =
    (total?.token0?.parsed ? parseFloat(total.token0.parsed) : 0) *
      (token0PriceInUSD ?? 0) +
    (total?.token1?.parsed ? parseFloat(total.token1.parsed) : 0) *
      (token1PriceInUSD ?? 0)

  // Tabs configuration - only for Deposit and Withdraw
  const tabs = [
    {
      name: "Deposit",
      component: (
        <AddLiquidity
          key={`deposit-${activeTab === 0 ? "active" : "inactive"}`}
          refetchUserPoolBalance={refetchUserPoolBalance}
        />
      ),
    },
    {
      name: "Withdraw",
      component: (
        <RemoveLiquidity key={`withdraw-${activeTab === 1 ? "active" : "inactive"}`} />
      ),
    },
  ]

  if (!poolAddress) {
    // Redirect to pools list if no pool address provided
    navigate("/pools")
    return null
  }

  return (
    <div className="m-auto flex w-full max-w-7xl flex-col justify-center gap-4">
      {/* Pool component always shows */}
      <div>
        <div className="flex flex-col justify-between space-y-4">
          <div className="flex items-center gap-4">
            <span>
              <button
                onClick={onNavigateToPools}
                className="flex items-center gap-2 rounded border border-marginalGray-800 p-3 px-2.5 py-2"
              >
                <ArrowLeftIcon width={16} />
                <span className="whitespace-nowrap text-marginalGray-200">Go Back</span>
              </button>
            </span>
            <div className="flex flex-wrap items-center gap-2">
              <span className="flex items-center gap-2 font-bold text-white">
                <img
                  src={MarginalLogo}
                  alt="Marginal Logo"
                  width={24}
                  height={24}
                  className=""
                />
                <span>Liquidity Pools</span>
              </span>
              <span className="rounded-full border border-marginalOrange-500 bg-marginalOrange-500 px-2 py-1 text-xs text-white">
                Version 1
              </span>
            </div>
          </div>
          <div className="flex flex-wrap gap-4 md:gap-8">
            <div className="flex">
              <div className="flex items-center space-x-2 text-2xl font-bold text-white">
                <DoubleTokenLogo
                  token0={token0 as Token}
                  token1={token1 as Token}
                  size={8}
                />
                <div>
                  <div>
                    {token0?.symbol}/{token1?.symbol}
                  </div>
                  <a
                    href={
                      getExplorerLink(
                        chainId,
                        poolAddress as string,
                        ExplorerDataType.CONTRACT,
                      ) ?? "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-xs text-marginalGray-600 hover:text-marginalOrange-500"
                  >
                    <span>View contract</span>
                    <ArrowTopRightOnSquareIcon className="h-3 w-3" />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-fit">
              <div className="flex flex-wrap gap-4">
                <section className="hidden divide-x divide-marginalGray-600 rounded bg-marginalGray-900 md:flex">
                  <div>
                    <div className="flex items-center gap-2 p-3">
                      <TokenLogo
                        symbol={token0?.symbol}
                        imgUrl={token0?.imgUrl}
                        size={7}
                      />
                      <div>
                        <div className="flex items-center space-x-3">
                          <div className="text-xs text-marginalGray-200">
                            {token0?.symbol}
                          </div>
                          <div className="flex items-center space-x-1 rounded-full border border-marginalGray-600 px-1">
                            <button
                              onClick={() => {
                                if (token0?.address) {
                                  navigator.clipboard.writeText(token0.address)
                                  // Optional: You could add a toast notification here
                                }
                              }}
                              className="flex cursor-pointer items-center gap-1 transition-colors hover:text-marginalOrange-500"
                            >
                              <span className="text-xs text-marginalGray-200">
                                {shortenAddress(token0?.address)}
                              </span>
                              <DocumentDuplicateIcon className="h-3 w-3" />
                            </button>
                          </div>
                        </div>
                        <div className="text-sm font-bold text-white">{token0?.name}</div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 p-3">
                    <TokenLogo symbol={token1?.symbol} imgUrl={token1?.imgUrl} size={7} />
                    <div>
                      <div className="flex items-center space-x-3">
                        <div className="text-xs text-marginalGray-200">
                          {token1?.symbol}
                        </div>
                        <div className="flex items-center space-x-1 rounded-full border border-marginalGray-600 px-1">
                          <button
                            onClick={() => {
                              if (token1?.address) {
                                navigator.clipboard.writeText(token1.address)
                                // Optional: You could add a toast notification here
                              }
                            }}
                            className="flex cursor-pointer items-center gap-1 transition-colors hover:text-marginalOrange-500"
                          >
                            <span className="text-xs text-marginalGray-200">
                              {shortenAddress(token1?.address)}
                            </span>
                            <DocumentDuplicateIcon className="h-3 w-3" />
                          </button>
                        </div>
                      </div>
                      <div className="text-sm font-bold text-white">{token1?.name}</div>
                    </div>
                  </div>
                </section>

                <section className="flex flex-col justify-center space-y-2 rounded bg-marginalGray-900 p-2">
                  <div className="flex items-center space-x-1">
                    <DocumentTextIcon className="h-3 w-3" />
                    <span className="text-xs">Contract: </span>
                    <button
                      onClick={() => {
                        if (poolAddress) {
                          navigator.clipboard.writeText(poolAddress)
                          // Optional: You could add a toast notification here
                        }
                      }}
                      className="flex cursor-pointer items-center gap-1 text-xs transition-colors hover:text-marginalOrange-500"
                    >
                      {shortenAddress(poolAddress)}
                      <DocumentDuplicateIcon className="h-3 w-3" />
                    </button>
                  </div>

                  <div className="flex items-center space-x-1">
                    <ShieldCheckIcon className="h-3 w-3" />
                    <span className="text-xs">Security audit:</span>
                    <Link
                      to="https://github.com/MarginalProtocol/v1-core/blob/main/audits/spearbit.pdf"
                      target="_blank"
                      className="flex items-center gap-1 text-xs hover:text-marginalOrange-500"
                    >
                      Spearbit Review
                      <ArrowTopRightOnSquareIcon className="h-3 w-3" />
                    </Link>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full flex-wrap-reverse gap-4 md:flex-nowrap">
        <section className="w-full max-w-3xl rounded bg-marginalGray-900 p-6">
          <h2 className="text-xl font-bold text-white">Liquidity & configuration</h2>

          {/* Pool stats section */}
          <div className="flex flex-col pt-10 lg:flex-row">
            <h3 className="mb-4 min-w-64 text-lg font-medium text-white">Stats</h3>

            <div className="flex-1">
              <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
                <div className="min-w-fit space-y-2 rounded-lg bg-marginalBlack p-4">
                  <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                    TVL
                    <InformationHoverTip
                      text="Total Value Locked - The total value of all assets in this liquidity pool"
                      iconSize={16}
                      position="top"
                    />
                  </div>
                  <div className="text-2xl font-semibold text-white">
                    ${formatNumberAmount(tvl?.toString())}
                  </div>
                </div>

                <div className="min-w-fit space-y-2 rounded-lg bg-marginalBlack p-4">
                  <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                    APY
                    <InformationHoverTip
                      text="Annual Percentage Yield - The projected yearly return for providing liquidity to this pool"
                      iconSize={16}
                      position="top"
                    />
                  </div>
                  <div className="text-2xl font-semibold text-white">
                    {!totalAprPercentage || totalAprPercentage === 0
                      ? "-"
                      : `${totalAprPercentage.toFixed(2)}%`}
                  </div>
                </div>

                <div className="min-w-fit space-y-2 rounded-lg bg-marginalBlack p-4">
                  <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                    Utilization
                    <InformationHoverTip
                      text="The percentage of the pool's capital that is currently being used for lending"
                      iconSize={16}
                      position="top"
                    />
                  </div>
                  <div className="text-2xl font-semibold text-white">
                    {poolUtilization?.toFixed(2)}%
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Your Liquidity Section */}
          {address && (
            <>
              <div className="my-10 h-px w-full bg-marginalGray-800" />
              <div className="flex flex-col lg:flex-row">
                <h3 className="mb-4 min-w-64 text-lg font-medium text-white">
                  Your Liquidity
                </h3>

                <div className="flex-1 space-y-6">
                  <div className="grid grid-cols-1 gap-4 xl:grid-cols-3">
                    <div className="space-y-2 rounded-lg bg-marginalBlack p-4">
                      <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                        Your shares
                        <InformationHoverTip
                          text="The number of LP tokens you currently own in this pool"
                          iconSize={16}
                          position="top"
                        />
                      </div>
                      <div className="flex flex-wrap gap-1 text-xl font-semibold text-white">
                        <span className="truncate">
                          {parseFloat(userTotalLpBalance || "0") > 0
                            ? formatNumberAmount(userTotalLpBalance)
                            : "0"}{" "}
                        </span>
                        <span className="whitespace-nowrap">{lpTokenSymbol}</span>
                      </div>
                    </div>

                    <div className="space-y-2 rounded-lg bg-marginalBlack p-4">
                      <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                        Pool share
                        <InformationHoverTip
                          text="Your percentage ownership of the total pool"
                          iconSize={16}
                          position="top"
                        />
                      </div>
                      <div className="text-xl font-semibold text-white">
                        {parseFloat(userTotalLpBalance || "0") > 0
                          ? userSharePercentageOfTotal?.toFixed(4)
                          : "0"}
                        %
                      </div>
                    </div>

                    <div className="space-y-2 rounded-lg bg-marginalBlack p-4">
                      <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                        Value
                        <InformationHoverTip
                          text="The current USD value of your pool position"
                          iconSize={16}
                          position="top"
                        />
                      </div>
                      <div className="text-xl font-semibold text-white">
                        $
                        {parseFloat(userTotalLpBalance || "0") > 0
                          ? formatNumberAmount(
                              // Token0 value
                              (
                                (total?.token0?.parsed &&
                                userSharePercentageOfTotal &&
                                token0PriceInUSD
                                  ? parseFloat(total.token0.parsed) *
                                    (userSharePercentageOfTotal / 100) *
                                    token0PriceInUSD
                                  : 0) +
                                // Token1 value
                                (total?.token1?.parsed &&
                                userSharePercentageOfTotal &&
                                token1PriceInUSD
                                  ? parseFloat(total.token1.parsed) *
                                    (userSharePercentageOfTotal / 100) *
                                    token1PriceInUSD
                                  : 0)
                              ).toString(),
                            )
                          : "0"}
                      </div>
                    </div>
                  </div>

                  {/* User's Pooled Tokens */}
                  <div className="space-y-4 rounded-xl bg-marginalBlack p-4">
                    <div className="flex items-center justify-between">
                      <div className="text-sm text-marginalGray-200">
                        Your pooled tokens
                      </div>

                      <div className="text-sm text-marginalGray-200">
                        <span className="text-marginalGray-600">Share: </span>
                        {userSharePercentageOfTotal?.toFixed(2) || "0"}%
                      </div>
                    </div>

                    <div className="space-y-2">
                      <div className="flex justify-between py-1">
                        <div className="w-1/2 text-marginalGray-200">
                          <TokenBadge token={token0 as Token} size={5} />
                        </div>
                        <div className="flex flex-col items-end text-marginalGray-200">
                          <p>
                            {(() => {
                              const value =
                                total?.token0?.parsed && userSharePercentageOfTotal
                                  ? parseFloat(total.token0.parsed) *
                                    (userSharePercentageOfTotal / 100)
                                  : 0
                              if (value === 0) return "0.0"
                              return value > 0.0001
                                ? formatNumberAmount(value.toString())
                                : value.toExponential(4)
                            })()}
                          </p>
                          <p className="text-xs text-marginalGray-600">
                            $
                            {formatNumberAmount(
                              total?.token1?.parsed &&
                                userSharePercentageOfTotal &&
                                token1PriceInUSD
                                ? (
                                    parseFloat(total.token1.parsed) *
                                    (userSharePercentageOfTotal / 100) *
                                    token1PriceInUSD
                                  ).toString()
                                : "0",
                            )}
                          </p>
                        </div>
                      </div>

                      <div className="flex justify-between py-1">
                        <div className="w-1/2 text-marginalGray-200">
                          <TokenBadge token={token1 as Token} size={5} />
                        </div>
                        <div className="flex flex-col items-end text-marginalGray-200">
                          <p>
                            {(() => {
                              const value =
                                total?.token1?.parsed && userSharePercentageOfTotal
                                  ? parseFloat(total.token1.parsed) *
                                    (userSharePercentageOfTotal / 100)
                                  : 0
                              if (value === 0) return "0.0"
                              return value > 0.0001
                                ? formatNumberAmount(value.toString())
                                : value.toExponential(4)
                            })()}
                          </p>
                          <p className="text-xs text-marginalGray-600">
                            $
                            {formatNumberAmount(
                              total?.token1?.parsed &&
                                userSharePercentageOfTotal &&
                                token1PriceInUSD
                                ? (
                                    parseFloat(total.token1.parsed) *
                                    (userSharePercentageOfTotal / 100) *
                                    token1PriceInUSD
                                  ).toString()
                                : "0",
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Staked and Rewards Row - Only show if staking is available AND there are rewards */}
                  {hasStakeFlow ? (
                    <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
                      {/* Staked */}
                      <div className="space-y-4 rounded-xl bg-marginalBlack p-4">
                        <div className="text-sm text-marginalGray-200">Staked</div>

                        <div className="flex flex-col items-start justify-between space-y-2">
                          <div className="text-base text-marginalGray-200">
                            {userStakedLpBalance?.parsedBalance
                              ? formatNumberAmount(
                                  userStakedLpBalance.parsedBalance,
                                  true,
                                )
                              : "0.0"}{" "}
                            {lpTokenSymbol}
                          </div>

                          <div className="flex items-center gap-2 text-sm text-marginalGray-200">
                            <span className="text-marginalGray-600">APR</span>
                            {totalAprPercentage?.toFixed(2) || "-"}%
                          </div>
                        </div>

                        <div className="flex flex-col space-y-2 xl:flex-row xl:space-x-4 xl:space-y-0">
                          <button
                            onClick={() => {
                              onNavigateToStake(poolAddress as string)
                            }}
                            className="w-full rounded-lg bg-marginalGray-800 px-4 py-2 text-white hover:bg-marginalOrange-500"
                          >
                            Stake
                          </button>
                          <button
                            onClick={() => {
                              onNavigateToUnstake(poolAddress as string)
                            }}
                            disabled={!hasStakedLpTokens}
                            className={`w-full rounded-lg bg-marginalGray-800 px-4 py-2 text-white ${!hasStakedLpTokens ? "cursor-not-allowed opacity-30" : "hover:bg-marginalOrange-500"}`}
                          >
                            Unstake
                          </button>
                        </div>
                      </div>

                      {/* Rewards */}
                      <div className="space-y-4 rounded-xl bg-marginalBlack p-4">
                        <div className="text-sm text-marginalGray-200">Rewards</div>
                        <div className="flex w-full flex-col justify-between space-y-2">
                          {rewards.map((reward) => (
                            <PoolReward
                              key={reward.tokenAddress}
                              rewardToken={reward.tokenAddress}
                              balance={reward.formattedBalance}
                            />
                          ))}
                        </div>
                        {/* Always show claim button, but disable when no rewards available */}
                        <button
                          onClick={async () => {
                            try {
                              if (!pool?.stakePool) {
                                throw new Error("Missing stake pool address")
                              }
                              await getReward(pool.stakePool as Address, chainId)
                            } catch (error) {
                              console.error("Claim callback error:", error)
                            }
                          }}
                          disabled={!hasClaimableRewards}
                          className={`w-full rounded-lg bg-marginalGray-800 px-4 py-2 text-white ${!hasClaimableRewards ? "cursor-not-allowed opacity-30" : "hover:bg-marginalOrange-500"}`}
                        >
                          Claim
                        </button>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </>
          )}

          <div className="my-10 h-px w-full bg-marginalGray-800" />

          {/* Supply Info Section */}
          <div className="flex flex-col lg:flex-row">
            <h3 className="mb-4 min-w-64 text-lg font-medium text-white">Supply Info</h3>

            <div className="flex-1 space-y-10">
              <div className="flex flex-col items-start gap-6 sm:flex-row sm:items-center sm:gap-10">
                <div className="relative flex items-center justify-center">
                  <CircularProgressBar
                    sqSize={80}
                    strokeWidth={5}
                    percentage={poolUtilization ?? 0}
                  />
                  <div className="absolute text-lg font-bold text-white">
                    {poolUtilization?.toFixed(2)}%
                  </div>
                </div>

                <div>
                  <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                    Pool Utilization
                  </div>

                  <div className="flex flex-wrap gap-1">
                    <span className="text-white">
                      {poolLiquidityLocked?.parsedLockedLiquidity} LP/
                    </span>
                    <span>{poolLiquidityTotal?.parsedTotalSupply} LP</span>
                  </div>
                </div>
              </div>

              {/* Pool Liquidity Summary */}
              <div className="mt-8 grid grid-cols-1 gap-4 xl:grid-cols-3">
                <div className="rounded-lg bg-marginalBlack p-4">
                  <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                    Total Liquidity
                    <InformationHoverTip
                      text="The combined value of all tokens in the pool"
                      iconSize={16}
                      position="top"
                    />
                  </div>
                  <div className="text-xl font-semibold text-white">
                    $
                    {formatNumberAmount(
                      // Token0 total value
                      (
                        (total?.token0?.parsed && token0PriceInUSD
                          ? parseFloat(total.token0.parsed) * token0PriceInUSD
                          : 0) +
                        // Token1 total value
                        (total?.token1?.parsed && token1PriceInUSD
                          ? parseFloat(total.token1.parsed) * token1PriceInUSD
                          : 0)
                      ).toString(),
                    )}
                  </div>
                </div>

                <div className="rounded-lg bg-marginalBlack p-4">
                  <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                    Available Liquidity
                    <InformationHoverTip
                      text="Liquidity that is currently available for lending or withdrawal"
                      iconSize={16}
                      position="top"
                    />
                  </div>
                  <div className="text-xl font-semibold text-white">
                    $
                    {formatNumberAmount(
                      // Token0 available value
                      (
                        (available?.token0.parsed && token0PriceInUSD
                          ? parseFloat(available.token0.parsed) * token0PriceInUSD
                          : 0) +
                        // Token1 available value
                        (available?.token1.parsed && token1PriceInUSD
                          ? parseFloat(available.token1.parsed) * token1PriceInUSD
                          : 0)
                      ).toString(),
                    )}
                  </div>
                </div>

                <div className="rounded-lg bg-marginalBlack p-4">
                  <div className="text-marginalGray-300 flex items-center gap-1 text-sm">
                    Borrowed Liquidity
                    <InformationHoverTip
                      text="The amount of liquidity currently being used by borrowers"
                      iconSize={16}
                      position="top"
                    />
                  </div>
                  <div className="text-xl font-semibold text-white">
                    $
                    {formatNumberAmount(
                      // Token0 locked value
                      (
                        (locked?.token0.parsed && token0PriceInUSD
                          ? parseFloat(locked.token0.parsed) * token0PriceInUSD
                          : 0) +
                        // Token1 locked value
                        (locked?.token1.parsed && token1PriceInUSD
                          ? parseFloat(locked.token1.parsed) * token1PriceInUSD
                          : 0)
                      ).toString(),
                    )}
                  </div>
                </div>
              </div>

              {/* Pool Composition */}
              <div className="space-y-4 rounded-xl bg-marginalBlack p-4">
                <div className="text-marginalGray-200">Pooled tokens</div>
                <div className="space-y-2">
                  <div className="flex justify-between">
                    <div className="w-1/3 text-sm text-marginalGray-400">Token</div>
                    <div className="text-sm text-marginalGray-400">Available</div>
                    <div className="text-sm text-marginalGray-400">Borrowed</div>
                  </div>

                  <div className="flex items-center justify-between py-1">
                    <div className="flex w-1/3 items-center gap-2 text-marginalGray-200">
                      <TokenLogo
                        symbol={token0?.symbol}
                        imgUrl={token0?.imgUrl}
                        size={8}
                      />
                      <span className="text-xl font-bold text-marginalGray-200">
                        {token0?.symbol}
                      </span>
                    </div>

                    <div className="flex flex-col items-end">
                      <p className="text-lg text-marginalGray-200">
                        {formatNumberAmount(available?.token0.parsed)}
                      </p>
                      <p className="text-xs text-marginalGray-600">
                        ~$
                        {formatNumberAmount(
                          available?.token0.parsed && token0PriceInUSD
                            ? (
                                parseFloat(available.token0.parsed) * token0PriceInUSD
                              ).toString()
                            : "0",
                        )}
                      </p>
                    </div>

                    <div className="flex flex-col items-end">
                      <p className="text-lg text-marginalGray-200">
                        {formatNumberAmount(locked?.token0.parsed)}
                      </p>
                      <p className="text-xs text-marginalGray-600">
                        ~$
                        {formatNumberAmount(
                          locked?.token0.parsed && token0PriceInUSD
                            ? (
                                parseFloat(locked.token0.parsed) * token0PriceInUSD
                              ).toString()
                            : "0",
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center justify-between py-1">
                    <div className="flex w-1/3 items-center gap-2 text-marginalGray-200">
                      <TokenLogo
                        symbol={token1?.symbol}
                        imgUrl={token1?.imgUrl}
                        size={8}
                      />
                      <span className="text-xl font-bold text-marginalGray-200">
                        {token1?.symbol}
                      </span>
                    </div>

                    <div className="flex flex-col items-end">
                      <p className="text-lg text-marginalGray-200">
                        {formatNumberAmount(available?.token1.parsed)}
                      </p>
                      <p className="text-xs text-marginalGray-600">
                        ~$
                        {formatNumberAmount(
                          available?.token1.parsed && token1PriceInUSD
                            ? (
                                parseFloat(available.token1.parsed) * token1PriceInUSD
                              ).toString()
                            : "0",
                        )}
                      </p>
                    </div>

                    <div className="flex flex-col items-end">
                      <p className="text-lg text-marginalGray-200">
                        {formatNumberAmount(locked?.token1.parsed)}
                      </p>
                      <p className="text-xs text-marginalGray-600">
                        ~$
                        {formatNumberAmount(
                          locked?.token1.parsed && token1PriceInUSD
                            ? (
                                parseFloat(locked.token1.parsed) * token1PriceInUSD
                              ).toString()
                            : "0",
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <section className="mt-8 space-y-6">
                <h4 className="text-sm font-bold text-white">Token Info</h4>

                <div className="grid grid-cols-2 gap-4">
                  <div className="items-baseline rounded-lg bg-marginalBlack p-4 text-sm">
                    <div className="text-marginalGray-400">Token</div>
                    <div className="mt-1 flex flex-col text-marginalGray-200">
                      <div className="flex items-center gap-1">
                        <TokenLogo
                          symbol={token0?.symbol}
                          imgUrl={token0?.imgUrl}
                          size={5}
                        />
                        <span className="text-lg font-bold text-white">
                          {token0?.symbol}
                        </span>
                      </div>
                      <span className="flex gap-1 whitespace-nowrap text-xs">
                        <span className="truncate">
                          {shortenAddress(token0?.address, 4)}
                        </span>
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(token0?.address || "")
                          }}
                          className="cursor-pointer hover:opacity-70"
                        >
                          <DocumentDuplicateIcon className="h-3 w-3" />
                        </button>
                      </span>
                    </div>
                  </div>

                  <div className="space-y-2 rounded-lg bg-marginalBlack p-4 text-sm">
                    <div className="text-marginalGray-400">Token contract</div>
                    <div className="mt-1 text-xs text-marginalGray-200">
                      <a
                        href={
                          getExplorerLink(
                            chainId,
                            token0?.address as string,
                            ExplorerDataType.CONTRACT,
                          ) ?? "#"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-1 hover:opacity-70"
                      >
                        <span>View contract</span>
                        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="rounded-lg bg-marginalBlack p-4">
                    <div className="text-sm text-marginalGray-400">Token</div>
                    <div className="mt-1 flex flex-col text-marginalGray-200">
                      <div className="flex items-center gap-1">
                        <TokenLogo
                          symbol={token1?.symbol}
                          imgUrl={token1?.imgUrl}
                          size={5}
                        />
                        <span className="text-lg font-bold text-white">
                          {token1?.symbol}
                        </span>
                      </div>
                      <span className="flex gap-1 whitespace-nowrap text-xs">
                        <span className="truncate">
                          {shortenAddress(token1?.address, 4)}
                        </span>
                        <button
                          onClick={() => {
                            navigator.clipboard.writeText(token1?.address || "")
                          }}
                          className="cursor-pointer hover:opacity-70"
                        >
                          <DocumentDuplicateIcon className="h-3 w-3" />
                        </button>
                      </span>
                    </div>
                  </div>

                  <div className="space-y-2 rounded-lg bg-marginalBlack p-4 text-sm">
                    <div className="text-marginalGray-400">Token contract</div>
                    <div className="mt-1 text-xs text-marginalGray-200">
                      <a
                        href={
                          getExplorerLink(
                            chainId,
                            token1?.address as string,
                            ExplorerDataType.CONTRACT,
                          ) ?? "#"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center gap-1 hover:opacity-70"
                      >
                        <span>View contract</span>
                        <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                      </a>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </section>

        {/* Toggle between Deposit and Withdraw */}
        <div className="mb-auto flex h-fit w-full min-w-96 max-w-3xl flex-col space-y-4 rounded bg-marginalGray-900 p-4 md:max-w-112">
          {!address ? (
            <div className="flex flex-col rounded bg-marginalGray-950 p-6">
              <h2 className="mb-6 text-lg font-semibold text-white">Your info</h2>
              <p className="mb-6 text-left text-sm text-marginalGray-200">
                Please connect a wallet to view your personal information here.
              </p>
              <CustomConnectButton />
            </div>
          ) : (
            <>
              <h3 className="mb-6 text-xl font-bold text-white">Your liquidity</h3>
              <div className="flex w-full items-center">
                {tabs.map((tab, index) => (
                  <button
                    key={tab.name}
                    onClick={() => setActiveTab(index)}
                    className={`w-full transform-gpu border-2 text-xl font-medium outline-none transition-all duration-75 focus:outline-none focus:ring-0 active:outline-none active:ring-0 ${activeTab === index ? "border-marginalOrange-500 bg-marginalOrange-500 text-white" : "text-marginalGray-300 border-marginalGray-800 hover:text-white"}`}
                  >
                    <div className="px-2 py-1">{tab.name}</div>
                  </button>
                ))}
              </div>
              <div>{tabs[activeTab].component}</div>
              {hasStakeFlow ? (
                <div className="flex gap-2 pt-4">
                  <button
                    onClick={() => {
                      onNavigateToStake(poolAddress as string)
                    }}
                    className="w-full rounded-lg bg-marginalGray-800 px-4 py-2 text-white hover:bg-marginalOrange-500"
                  >
                    Stake
                  </button>
                  <button
                    onClick={() => {
                      onNavigateToUnstake(poolAddress as string)
                    }}
                    disabled={!hasStakedLpTokens}
                    className={`w-full rounded-lg bg-marginalGray-800 px-4 py-2 text-white ${!hasStakedLpTokens ? "cursor-not-allowed opacity-30" : "hover:bg-marginalOrange-500"}`}
                  >
                    Unstake
                  </button>
                  {/* Always show claim button, but disable when no rewards available */}
                  <button
                    onClick={async () => {
                      try {
                        if (!pool?.stakePool) {
                          throw new Error("Missing stake pool address")
                        }
                        await getReward(pool.stakePool as Address, chainId)
                      } catch (error) {
                        console.error("Claim callback error:", error)
                      }
                    }}
                    disabled={!hasClaimableRewards}
                    className={`w-full rounded-lg bg-marginalGray-800 px-4 py-2 text-white ${!hasClaimableRewards ? "cursor-not-allowed opacity-30" : "hover:bg-marginalOrange-500"}`}
                  >
                    Claim
                  </button>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
      {/* <Pool /> */}
    </div>
  )
}

export default _Pool

const PoolReward = ({
  rewardToken,
  balance,
}: {
  rewardToken: Address
  balance?: string
}) => {
  const tokenSymbol = useErc20TokenSymbol(rewardToken)
  return (
    <div className="flex items-center space-x-1">
      <div className="text-base text-marginalGray-200">{formatNumberAmount(balance)}</div>
      <span className="text-base text-marginalGray-200">{tokenSymbol}</span>
    </div>
  )
}
