import { ToastContainer } from "react-toastify"

export const ToastProvider = ({ children }: { children: React.ReactNode }) => {
  const className =
    "font-mono flex items-start border border-marginalGray-800 bg-marginalBlack w-fit rounded-[15px] p-4 space-x-4 overflow-hidden text-sm"

  return (
    <>
      {children}
      <ToastContainer
        autoClose={5000}
        closeButton={false}
        toastClassName={className}
        position="bottom-right"
        newestOnTop={true}
        pauseOnHover={true}
        hideProgressBar={true}
        pauseOnFocusLoss={false}
        closeOnClick={false}
        theme="dark"
      />
    </>
  )
}
