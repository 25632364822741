import "./globals.css"
import "@rainbow-me/rainbowkit/styles.css"
import React from "react"
import ReactDOM from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import store from "./state"

import { Provider } from "react-redux"
import { WagmiProvider } from "wagmi"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { RainbowKitProvider, darkTheme, Theme } from "@rainbow-me/rainbowkit"
import { merge } from "lodash"
import PricesUpdater from "src/state/prices/updater"
import ApplicationUpdater from "src/state/application/updater"
import { wagmiConfig } from "./wagmiConfig"
import { DEFAULT_CHAIN_ID } from "./constants/chains/chains"


const theme = merge(darkTheme(), {
  colors: {
    accentColor: "#FF6B26",
    accentColorForeground: "#FFF",
    modalBackground: "#2C2C2C",
    modalBorder: "#3A3A3A",
    modalTextSecondary: "#CACACA",
    modalText: "#BEBEBE",
  },
  fonts: {
    body: "PP Fraktion Mono, monaco, monospace",
  },
  radii: {
    modal: "20px",
    actionButton: "8px",
  },
} as Theme)

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

function Updater() {
  return (
    <>
      <ApplicationUpdater />
      <PricesUpdater />
    </>
  )
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider initialChain={DEFAULT_CHAIN_ID} theme={theme}>
            <Updater />
            <App />
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </Provider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

declare global {
  interface BigInt {
    toJSON(): string
  }
}

BigInt.prototype.toJSON = function () {
  return this.toString()
}
