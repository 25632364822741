import { BaseQueryApi, BaseQueryFn } from "@reduxjs/toolkit/query"
import { createApi } from "@reduxjs/toolkit/query/react"
import { ClientError, GraphQLClient } from "graphql-request"
import { DocumentNode } from "graphql"
import { ChainId } from "src/constants/chains/chains"
import { AppState } from "src/state"
import { getAccount } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"

// TODO: Update to production URLs when available
const SUBGRAPH_URLS: { [key: number]: string } = {
  [ChainId.BERACHAIN]:
    process.env.NODE_ENV === "production"
      ? "https://gateway.thegraph.com/api/8d87adf0f4a6e587d7325c536e09ebb9/subgraphs/id/djsesqUbPm1B8wuqZ2M4osrkwGJaoQGMgC93nDn5aZ5"
      : "https://api.studio.thegraph.com/query/83456/v1-marginal-berachain/version/latest",
  // [ChainId.BASE]:
  //   process.env.NODE_ENV === "production"
  //     ? // "https://api.studio.thegraph.com/query/83456/marginal-v1-base/version/latest"
  //       "https://api.studio.thegraph.com/query/83456/test-v1-marginal-base/version/latest"
  //     : "https://api.studio.thegraph.com/query/83456/test-v1-marginal-base/version/latest",
  // [ChainId.SEPOLIA]:
  //   process.env.NODE_ENV === "production"
  //     ? // "https://api.studio.thegraph.com/query/83456/v1-marginal-sepolia/version/latest"
  //       "https://api.studio.thegraph.com/query/83456/test-v1-marginal-sepolia/version/latest"
  //     : "https://api.studio.thegraph.com/query/83456/test-v1-marginal-sepolia/version/latest",
  // [ChainId.BERACHAIN_BARTIO]:
  //   process.env.NODE_ENV === "production"
  //     ? "https://api.goldsky.com/api/public/project_clzskpdib2r6301oscaxh0lab/subgraphs/marginal-v1-bartio/latest/gn"
  //     : "https://api.goldsky.com/api/public/project_clzskpdib2r6301oscaxh0lab/subgraphs/marginal-v1-bartio/latest/gn",
  // [ChainId.ETH]:
  //   process.env.NODE_ENV === "production"
  //     ? // "https://api.studio.thegraph.com/query/83456/v1-marginal-mainnet/version/latest"
  //       "https://api.studio.thegraph.com/query/83456/test-v1-marginal-mainnet/version/latest"
  //     : "https://api.studio.thegraph.com/query/83456/test-v1-marginal-mainnet/version/latest",
}

// Graphql query client wrapper that builds a dynamic url based on chain id
function graphqlRequestBaseQuery(): BaseQueryFn<
  { document: string | DocumentNode; variables?: any },
  unknown,
  Pick<ClientError, "name" | "message" | "stack">,
  Partial<Pick<ClientError, "request" | "response">>
> {
  return async ({ document, variables }, { getState }: BaseQueryApi) => {
    try {
      const chainId =
        getAccount(wagmiConfig).chain?.id ?? (getState() as AppState).application.chainId
      const subgraphUrl = SUBGRAPH_URLS[chainId]

      if (!subgraphUrl) {
        return {
          error: {
            name: "UnsupportedChainId",
            message: `Subgraph queries against ChainId ${chainId} are not supported.`,
            stack: "",
          },
        }
      }

      return {
        data: await new GraphQLClient(subgraphUrl).request(document, variables),
        meta: {},
      }
    } catch (error) {
      if (error instanceof ClientError) {
        const { name, message, stack, request, response } = error
        return { error: { name, message, stack }, meta: { request, response } }
      }
      throw error
    }
  }
}

export const api = createApi({
  baseQuery: graphqlRequestBaseQuery(),
  endpoints: () => ({}),
})
