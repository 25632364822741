import { useState } from "react"
import { type Token } from "src/types"

interface TokenLogoProps {
  symbol?: string
  imgUrl?: string
  size?: number
}

export const TokenLogo = ({ symbol, imgUrl, size = 7 }: TokenLogoProps) => {
  const [imgLoaded, setImgLoaded] = useState(() => {
    const img = new Image()
    img.src = imgUrl ?? ""
    return imgUrl ? img.complete : false
  })

  return (
    <div className="relative flex">
      <div
        className={`relative w-${size} h-${size} ${
          imgLoaded ? "bg-transparent" : "bg-marginal-gray-800"
        } rounded-full transition-opacity duration-200 ease-in`}
      >
        <img
          className={`opacity-${
            imgLoaded ? 100 : 0
          } transition-opacity duration-200 ease-in w-${size} h-${size} rounded-full bg-transparent`}
          src={imgUrl}
          alt={`${symbol ?? "token"} logo`}
          onLoad={() => void setImgLoaded(true)}
          loading="lazy"
        />
      </div>
    </div>
  )
}

export const DoubleTokenLogo = ({
  token0,
  token1,
  size,
}: {
  token0: Token
  token1: Token
  size: number
}) => {
  return (
    <div className="flex -space-x-2">
      <span className="z-20">
        <TokenLogo symbol={token0?.symbol} imgUrl={token0?.imgUrl} size={size} />
      </span>
      <span className="z-10">
        <TokenLogo symbol={token1?.symbol} imgUrl={token1?.imgUrl} size={size} />
      </span>
    </div>
  )
}

export const TokenBadge = ({
  token,
  size = 7,
  reverse = false,
}: {
  token: Token
  size?: number
  reverse?: boolean
}) => {
  return (
    <div className="flex items-center space-x-1">
      {reverse ? (
        <>
          <span className="font-bold text-white">{token?.symbol}</span>
          <TokenLogo symbol={token?.symbol} imgUrl={token?.imgUrl} size={size} />
        </>
      ) : (
        <>
          <TokenLogo symbol={token?.symbol} imgUrl={token?.imgUrl} size={size} />
          <span className="font-bold text-white">{token?.symbol}</span>
        </>
      )}
    </div>
  )
}
