import React, { Suspense, lazy, useTransition } from "react"
import {
  Routes as BrowserRoutes,
  Route,
  useNavigate,
  useLocation,
  Link as RouterLink,
} from "react-router-dom"
import type { ComponentType, ReactNode } from "react"
import { Layout } from "src/Layout"

// Routes without lazy loading
import Trade from "src/pages/trade/Trade"
import Swap from "src/pages/swap/Swap"
import TermsOfUse from "src/pages/termsOfUse/TermsOfUse"
import PrivacyPolicy from "src/pages/privacyPolicy/PrivacyPolicy"
import NEW_POOL from "./pages/pool/_Pool"
import Stake from "src/pages/stake/Stake"
import Unstake from "src/pages/unstake/Unstake"

// Routes with lazy loading
const Pools = lazyLoad(() => import("src/pages/pools/Pools"))
const AddLiquidity = lazyLoad(() => import("src/pages/addLiquidity/AddLiquidity"))
const RemoveLiquidity = lazyLoad(
  () => import("src/pages/removeLiquidity/RemoveLiquidity"),
)
const Pool = lazyLoad(() => import("src/pages/pool/Pool"))
const ManagePosition = lazyLoad(
  () => import("src/pages/position/managePosition/ManagePosition"),
)
const Close = lazyLoad(() => import("src/pages/position/managePosition/Close"))
const Positions = lazyLoad(() => import("src/pages/positions/Positions"))
const Position = lazyLoad(() => import("src/pages/position/Position"))
// Stake and Unstake now imported directly at the top
const CreateNewPool = lazyLoad(() => import("src/pages/createPool/CreateNewPool"))
const Launch = lazyLoad(() => import("src/pages/lbp/Launch"))
const LaunchProject = lazyLoad(() => import("src/pages/lbp/LaunchProject"))
const Transactions = lazyLoad(() => import("src/pages/transactions/Transactions"))

const PageLoader = () => (
  <div className="flex h-[calc(100vh-200px)] w-full items-center justify-center">
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-marginalOrange-500"
    >
      <style>{`
        .spinner_9y7u {
          animation: spinner_fUkk 2.4s linear infinite;
          animation-delay: -2.4s;
        }
        .spinner_DF2s {
          animation-delay: -1.6s;
        }
        .spinner_q27e {
          animation-delay: -0.8s;
        }
        @keyframes spinner_fUkk {
          8.33% { x: 13px; y: 1px; }
          25% { x: 13px; y: 1px; }
          33.3% { x: 13px; y: 13px; }
          50% { x: 13px; y: 13px; }
          58.33% { x: 1px; y: 13px; }
          75% { x: 1px; y: 13px; }
          83.33% { x: 1px; y: 1px; }
        }
      `}</style>
      <rect className="spinner_9y7u" x="1" y="1" rx="1" width="10" height="10" />
      <rect
        className="spinner_9y7u spinner_DF2s"
        x="1"
        y="1"
        rx="1"
        width="10"
        height="10"
      />
      <rect
        className="spinner_9y7u spinner_q27e"
        x="1"
        y="1"
        rx="1"
        width="10"
        height="10"
      />
    </svg>
  </div>
)

// Custom Link component that uses useTransition for smooth navigation
export function TransitionLink({
  to,
  children,
  className,
  ...props
}: {
  to: string
  children: ReactNode
  className?: string
  [key: string]: any
}) {
  const navigate = useNavigate()
  const [isPending, startTransition] = useTransition()

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    startTransition(() => {
      navigate(to)
    })
  }

  return (
    <RouterLink
      to={to}
      onClick={handleClick}
      className={`${className || ""} ${isPending ? "opacity-70" : ""}`}
      {...props}
    >
      {children}
    </RouterLink>
  )
}

// Provider that enables navigation transition management
function NavigationTransitionProvider({ children }: { children: ReactNode }) {
  const location = useLocation()
  const [isPending] = useTransition()

  // Track route changes for analytics or other side effects
  React.useEffect(() => {
    // Any global navigation side effects can go here
  }, [location.pathname])

  return (
    <>
      {children}
      {isPending && (
        <div className="fixed left-0 right-0 top-0 z-50 h-1 bg-marginalGray-900 bg-opacity-80">
          <div
            className="h-full animate-pulse bg-marginalOrange-500"
            style={{ width: "100%" }}
          />
        </div>
      )}
    </>
  )
}

// Component that ensures loader appears for a minimum duration while loading content in background
function MinimumLoadingTime({
  children,
  minDuration = 250,
}: {
  children: ReactNode
  minDuration?: number
}) {
  const [isLoading, setIsLoading] = React.useState(true)
  const childrenRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, minDuration)

    return () => clearTimeout(timer)
  }, [minDuration])

  return (
    <>
      {/* Hidden div that renders children immediately to start data fetching */}
      <div
        ref={childrenRef}
        style={{ display: isLoading ? "none" : "contents" }}
        aria-hidden={isLoading}
      >
        {children}
      </div>

      {/* Show loader while in loading state */}
      {isLoading && <PageLoader />}
    </>
  )
}

// Type-safe lazy loading helper
function lazyLoad<T extends ComponentType<any>>(
  importFn: () => Promise<{ default: T }>,
): ComponentType<React.ComponentPropsWithRef<T>> {
  const LazyComponent = lazy(importFn)
  return function LazyLoadedComponent(props: React.ComponentPropsWithRef<T>) {
    return (
      <Suspense fallback={<PageLoader />}>
        <MinimumLoadingTime>
          <LazyComponent {...props} />
        </MinimumLoadingTime>
      </Suspense>
    )
  }
}

export default function AppRoutes() {
  return (
    <Layout>
      <NavigationTransitionProvider>
        <BrowserRoutes>
          {/* Eagerly loaded routes */}
          <Route path="/" element={<Trade />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />

          {/* Lazy-loaded routes with automatic Suspense boundary */}
          <Route path="/launch" element={<Launch />} />
          <Route path="/launch/:projectAddress" element={<LaunchProject />} />
          <Route path="positions/position/close/:indexedId" element={<Close />} />
          <Route
            path="positions/position/manage/:positionKey"
            element={<ManagePosition />}
          />
          <Route path="/pools/unstake/:poolAddress" element={<Unstake />} />
          <Route path="/pools/stake/:poolAddress" element={<Stake />} />
          <Route path="/pools/:poolAddress" element={<Pool />} />
          <Route path="/pool/:poolAddress" element={<NEW_POOL />} />
          <Route
            path="/pools/liquidity/remove/:poolAddress"
            element={<RemoveLiquidity />}
          />
          <Route path="/transactions" element={<Transactions />} />
          <Route path="/pools/liquidity/add/:poolAddress" element={<AddLiquidity />} />
          <Route path="/positions/position/:positionKey" element={<Position />} />
          <Route path="/positions" element={<Positions />} />
          <Route path="/pools/create" element={<CreateNewPool />} />
          <Route path="/pools" element={<Pools />} />
          <Route path="/swap" element={<Swap />} />
        </BrowserRoutes>
      </NavigationTransitionProvider>
    </Layout>
  )
}
