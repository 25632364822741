import { NavLink } from "react-router-dom"
import { CloseIcon } from "../Icons/CloseIcon"
import { useRef, useEffect } from "react"

interface MobileMenuProps {
  isOpen: boolean
  onClose: () => void
  position: "top" | "bottom"
  heading: string
  links: Record<string, string>
}

export const MobileMenu = ({
  isOpen,
  onClose,
  position,
  heading,
  links,
}: MobileMenuProps) => {
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // Implement close on blur
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside)
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [isOpen, onClose])

  if (isOpen)
    return (
      <div
        ref={menuRef}
        className={`${position === "top" && "inset-0"} ${position === "bottom" && "inset-x-0 bottom-0"} fixed z-50 flex h-fit w-full flex-col bg-marginalGray-950`}
      >
        <div className="flex justify-between p-2">
          <h2 className="p-2 text-lg font-bold text-white">{heading}</h2>
          <button onClick={onClose} className="flex p-2 transition-all hover:text-white">
            <CloseIcon />
          </button>
        </div>
        {Object.entries(links).map(([label, href], i) => {
          const isLastLink = i === Object.entries(links).length - 1

          return href ? (
            <NavLink
              key={i}
              to={href}
              target="_blank"
              rel="noreferrer"
              className={`${isLastLink && "pb-3"} px-4 py-2 hover:bg-marginalGray-900 hover:text-white focus-visible:bg-marginalGray-900 focus-visible:text-white`}
            >
              {label}
            </NavLink>
          ) : (
            <p
              key={`disabled-${i}`}
              className={`${isLastLink && "pb-3"} flex w-full cursor-not-allowed text-nowrap px-4 py-1 text-marginalGray-600`}
            >
              {label}
            </p>
          )
        })}
      </div>
    )

  return null
}
