import { PoolsView } from "src/pages/pools/hooks/usePoolsFilters"
// import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
// import { PoolsTabSelector } from "src/pages/pools/components/PoolsTabSelector"
// import { ActionButton } from "src/components/ui/ActionButton"
import MarginalLogo from "src/assets/logos/marginal.svg"

interface PoolsHeaderProps {
  view: PoolsView
  onViewChange: (view: PoolsView) => void
}

export const PoolsHeader = ({ view, onViewChange }: PoolsHeaderProps) => {
  // const { onNavigateToCreatePool } = useNavigateRoutes()

  return (
    <div className="flex w-full flex-col items-start px-4 pb-4">
      <div className="flex w-full items-center justify-between">
        <span className="flex items-center gap-2 font-bold text-white">
          <img
            src={MarginalLogo}
            alt="Marginal Logo"
            width={24}
            height={24}
            className=""
          />
          <span className="text-xl">Liquidity Pools</span>
          <span className="rounded-full border border-marginalOrange-500 bg-marginalOrange-500 px-2 py-1 text-xs text-white">
            Version 1
          </span>
        </span>
        {/* <div className="flex justify-center space-x-2 md:w-fit md:justify-end md:space-x-4">
          <ActionButton action="Create Pool" onClick={onNavigateToCreatePool} size="sm" />
        </div> */}
      </div>
      {/* <PoolsTabSelector
        view={view}
        handlePrimaryClick={() => onViewChange(PoolsView.INCENTIVIZED)}
        handleSecondaryClick={() => onViewChange(PoolsView.UNINCENTIVIZED)}
      /> */}
    </div>
  )
}
