import { TableHeaderCell } from "./TableComponents"
import { PoolData } from "src/types"
import { PoolRow } from "src/pages/pools/components/PoolRow"
import { PoolRowLoading } from "src/pages/pools/components/PoolRowLoading"
import { PoolsView } from "src/pages/pools/hooks/usePoolsFilters"
import { PoolLiquidityLocked } from "src/pages/pools//hooks/useLiquidityLockedInPools"
import { PoolBalance } from "src/pages/pool/hooks/useUserPoolBalance"
import { PoolTotalSupply } from "src/pages/pools/hooks/useTotalSupplyInPools"
import { PoolState } from "src/pages/pools/hooks/useCurrentPoolsState"
import { TokenBalance } from "src/pages/pools/hooks/useMultipleErc20TokenBalances"

interface PoolsTableProps {
  view: PoolsView
  pools: PoolData[]
  isLoading: boolean
  address?: string
  chainId: number
  liquidityLocked?: PoolLiquidityLocked[]
  userPoolBalances?: PoolBalance[]
  poolTotalSupply?: PoolTotalSupply[]
  poolStates: { [poolAddress: string]: PoolState | undefined }
  poolTokenBalances?: { [poolAddress: string]: TokenBalance[] }
}

export const PoolsTable = ({
  pools,
  isLoading,
  chainId,
  liquidityLocked,
  userPoolBalances,
  poolTotalSupply,
  poolStates,
  poolTokenBalances,
}: PoolsTableProps) => {
  return (
    <table className="w-full table-auto border-separate border-spacing-0 overflow-hidden border border-marginalGray-800">
      <thead className="hidden md:table-header-group">
        <tr>
          <TableHeaderCell align="left">Pool</TableHeaderCell>
          <TableHeaderCell>TVL</TableHeaderCell>
          <TableHeaderCell>APR</TableHeaderCell>
          <TableHeaderCell>Pooled Assets</TableHeaderCell>
          <TableHeaderCell>Utilization</TableHeaderCell>
          <TableHeaderCell>My Share</TableHeaderCell>
        </tr>
      </thead>
      <tbody>
        {isLoading
          ? Array.from({ length: 5 }).map((_, index) => <PoolRowLoading key={index} />)
          : pools.length > 0
            ? pools.map((pool: PoolData, index: number) => {
                const poolLiquidityLocked = liquidityLocked?.find(
                  (liquidity) => liquidity?.pool?.poolAddress === pool.poolAddress,
                )
                const userPoolBalance = userPoolBalances?.find(
                  (balance) => balance?.pool?.poolAddress === pool.poolAddress,
                )
                const totalSupply = poolTotalSupply?.find(
                  (supply) => supply?.pool?.poolAddress === pool.poolAddress,
                )
                return (
                  <PoolRow
                    key={pool.poolAddress}
                    chainId={chainId}
                    pool={pool}
                    liquidityLocked={poolLiquidityLocked}
                    userPoolBalance={userPoolBalance}
                    poolTotalSupply={totalSupply}
                    poolState={poolStates[pool.poolAddress]}
                    poolTokenBalances={poolTokenBalances?.[pool.poolAddress]}
                  />
                )
              })
            : null}
      </tbody>
    </table>
  )
}
