import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  ReactNode,
  useMemo,
} from "react"
import { useAccount } from "wagmi"
import { useApplicationState } from "src/state/application/hooks"
import { useGetTransactionsQuery } from "src/state/api/generated"
import { isAddress } from "viem"

interface TransactionsContextType {
  transactions: any[] | undefined // We'll type this properly once we know the full data structure
  isLoading: boolean
  isError: boolean
  error: any
  refetch: () => Promise<any>
}

const TransactionsContext = createContext<TransactionsContextType>(
  {} as TransactionsContextType,
)

interface TransactionsProviderProps {
  children: ReactNode
}

export function TransactionsProvider({ children }: TransactionsProviderProps) {
  const { address } = useAccount()
  const { chainId } = useApplicationState()

  // Validate and format address
  const validatedAddress = useMemo(() => {
    if (!address) return undefined
    return isAddress(address) ? address.toLowerCase() : undefined
  }, [address])

  // Fetch transactions data
  const {
    data,
    refetch: refetchTransactionsData,
    isLoading,
    isError,
    error,
  } = useGetTransactionsQuery(
    { address: validatedAddress ?? "" },
    {
      skip: !validatedAddress,
      refetchOnMountOrArgChange: true,
      // Poll every 30 seconds
      pollingInterval: 30 * 1000,
    },
  )

  // Sort transactions by timestamp in descending order (newest first)
  const sortedTransactions = useMemo(() => {
    if (!data?.transactions) return undefined
    return [...data.transactions].sort(
      (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp),
    )
  }, [data?.transactions])

  // Create wrapped refetch function with better return type
  const refetch = useCallback(async () => {
    return refetchTransactionsData()
  }, [refetchTransactionsData])

  // Refetch transactions data when chain changes or address changes
  useEffect(() => {
    if (validatedAddress) {
      refetch()
    }
  }, [chainId, refetch, validatedAddress])

  // Create the context value object
  const value = {
    transactions: sortedTransactions,
    isLoading,
    isError,
    error,
    refetch,
  }

  return (
    <TransactionsContext.Provider value={value}>{children}</TransactionsContext.Provider>
  )
}

/**
 * Hook to use the transactions data
 */
export function useTransactionsData() {
  const context = useContext(TransactionsContext)
  if (context === undefined) {
    throw new Error("useTransactionsData must be used within a TransactionsProvider")
  }
  return context
}
