import { ComponentProps } from "react"

interface DotIconProps extends ComponentProps<"svg"> {
  size?: number
  className?: string
}

export const DotIcon = ({ size = 16, className = "", ...props }: DotIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 4 4"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={`inline-block ${className}`}
      {...props}
    >
      <circle cx="2" cy="2" r="1" />
    </svg>
  )
}
