import { useMemo } from "react"
import { Token } from "src/types"
import { TOKENS_BY_CHAIN } from "src/constants/tokenList"

/**
 * Hook to get a merged and prioritized list of tokens for a specific chain
 * Prioritizes tokens from our predefined tokenList over tokens from the subgraph
 */
export function useTokenList(
  chainId: number | undefined,
  tokensFromSubgraph: Token[] = [],
): Token[] {
  return useMemo(() => {
    if (!chainId) return []

    // Get our predefined tokens for this chain
    const predefinedTokens = TOKENS_BY_CHAIN[chainId] || []

    // Create a Set of subgraph token addresses for quick lookup
    const subgraphAddresses = new Set(
      tokensFromSubgraph.map((token) => token.address.toLowerCase())
    )

    // Filter predefined tokens to only include those that exist in subgraph
    const matchedPredefinedTokens = predefinedTokens.filter((token) =>
      subgraphAddresses.has(token.address.toLowerCase())
    )

    // Create a Set of matched predefined token addresses
    const matchedPredefinedAddresses = new Set(
      matchedPredefinedTokens.map((token) => token.address.toLowerCase())
    )

    // Get subgraph tokens that don't exist in predefined list
    const uniqueSubgraphTokens = tokensFromSubgraph.filter(
      (token) => !matchedPredefinedAddresses.has(token.address.toLowerCase())
    )

    // Combine matched predefined tokens with unique subgraph tokens
    return [...matchedPredefinedTokens, ...uniqueSubgraphTokens]
  }, [chainId, tokensFromSubgraph])
}
