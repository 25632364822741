import { useDisclosure } from "src/hooks/useDisclosure"
import { MobileMenu } from "../Header/MobileMenuV2"
import { CircleInfoIcon } from "../Icons/CircleInfoIcon"
import { ContactIcon } from "../Icons/ContactIcon"
import { DocsIcon } from "../Icons/DocsIcon"
import { CONTACT_LINKS, LEGAL_LINKS, RESOURCES_LINKS } from "src/constants/links"

export const MobileFooter = () => {
  // Disclosure: Terms Menu
  const { isOpen: isTermsOpen, onOpen: openTerms, onClose: closeTerms } = useDisclosure()

  // Disclosure: Resources Menu
  const {
    isOpen: isResourcesOpen,
    onOpen: openResources,
    onClose: closeResources,
  } = useDisclosure()

  // Disclosure: Contact Menu
  const {
    isOpen: isContactOpen,
    onOpen: openContact,
    onClose: closeContact,
  } = useDisclosure()

  return (
    <footer className="flex h-[45px] w-full items-center border-t border-marginalGray-200/20 bg-marginalGray-950 lg:hidden">
      <div className="flex h-full items-center">
        <small className="text-nowrap px-6 text-sm font-normal">Marginal © 2025</small>
      </div>

      <div className="ml-auto flex h-full -space-x-[1px]">
        <button
          onClick={openTerms}
          className="flex h-full w-min cursor-pointer items-center text-nowrap border-l border-marginalGray-200/20 stroke-marginalGray-200 p-4 text-sm font-normal outline-none transition-all hover:bg-marginalGray-900 hover:stroke-white focus-visible:bg-marginalGray-900 focus-visible:stroke-white"
        >
          <CircleInfoIcon className="h-4 w-4" />
        </button>

        <button
          onClick={openResources}
          className="flex h-full items-center border-l border-marginalGray-200/20 stroke-marginalGray-200 p-4 transition-all hover:bg-marginalGray-900 hover:stroke-white"
        >
          <DocsIcon className="h-4 w-4" />
        </button>

        <button
          onClick={openContact}
          className="flex h-full items-center border-l border-marginalGray-200/20 stroke-marginalGray-200 p-4 transition-all hover:bg-marginalGray-900 hover:stroke-white"
        >
          <ContactIcon className="h-4 w-4" />
        </button>
      </div>

      <MobileMenu
        isOpen={isTermsOpen}
        onClose={closeTerms}
        position="bottom"
        heading="Terms"
        links={LEGAL_LINKS}
      />

      <MobileMenu
        isOpen={isResourcesOpen}
        onClose={closeResources}
        position="bottom"
        heading="Resources"
        links={RESOURCES_LINKS}
      />

      <MobileMenu
        isOpen={isContactOpen}
        onClose={closeContact}
        position="bottom"
        heading="Contact"
        links={CONTACT_LINKS}
      />
    </footer>
  )
}
