import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  ReactNode,
  useMemo,
} from "react"
import _ from "lodash"
import { useApplicationState } from "src/state/application/hooks"
import { useGetPoolsQuery } from "src/state/api/generated"
import { getAddress } from "viem"
import { Token, PoolData } from "src/types"

type PoolsDataByAddress = {
  [address: string]: PoolData
}

interface PoolsContextType {
  pools: PoolData[]
  poolsDataByAddress: PoolsDataByAddress
  tokensFromPools: Token[]
  isLoading: boolean
  isError: boolean
  error: any
  refetch: () => Promise<any>
}

const PoolsContext = createContext<PoolsContextType>({} as PoolsContextType)

interface PoolsProviderProps {
  children: ReactNode
}

const constructTokensDataFromPool = (pool: PoolData): [Token, Token] => {
  const token0 = pool.token0
  const token1 = pool.token1
  return [token0, token1]
}

export function PoolsProvider({ children }: PoolsProviderProps) {
  const { chainId } = useApplicationState()

  // Fetch pools data
  const {
    data: poolsData,
    refetch: refetchPoolsData,
    isLoading,
    isError,
    error,
  } = useGetPoolsQuery(undefined, {
    refetchOnMountOrArgChange: true,
    // Poll every 2 minutes
    pollingInterval: 2 * 60 * 1000,
  })

  // Define whitelist constants outside of the component for better memoization
  const WHITELISTED_POOLS = [
    "0x540eec7a14a140ad9189e80b60a27f610d7a0b17",
    "0x9d79f315d873f5f6890a251da71a185432629aff",
    "0xb006ee435c9012b7bef6bce60a8e9418984ee7a4",
    "0xB2230a4f6d8145D02C6F4bE6B8fc3545c53529B5",
  ].map((address) => address.toLowerCase())

  const filteredPools = useMemo(() => {
    if (!poolsData?.pools) return []
    return (
      poolsData.pools.filter((pool) =>
        WHITELISTED_POOLS.includes(pool.id.toLowerCase()),
      ) || []
    )
  }, [poolsData?.pools, WHITELISTED_POOLS])

  // Helper function to construct pool data from query result
  const constructPoolDataFromQuery = (pool: any): PoolData => {
    const [token0, token1] = constructTokensDataFromPool({
      token0: pool.token0,
      token1: pool.token1,
    } as PoolData) // Type cast to satisfy function parameters

    return {
      tokenPair: pool?.tokenPair,
      poolAddress: pool?.id,
      oracleAddress: pool?.oracle,
      maintenance: pool?.maintenance,
      decimals: pool?.decimals,
      token0: token0,
      token1: token1,
      factoryAddress: pool?.factory?.id,
      stakePool: pool?.stakePool?.id,
      rewardTokens: pool?.rewardTokens,
    }
  }

  // Equivalent to useConstructedPoolsData
  const transformedPools = useMemo(() => {
    return filteredPools?.map((pool: any) => constructPoolDataFromQuery(pool))
  }, [filteredPools])

  // Equivalent to usePoolsDataByAddress
  const poolsDataByAddress = useMemo(() => {
    const library: PoolsDataByAddress = {}
    transformedPools?.forEach((pool: any) => {
      const poolAddress = getAddress(pool.poolAddress)
      if (poolAddress) {
        library[poolAddress] = pool
      }
    })
    return library
  }, [transformedPools])

  // Equivalent to useTokensFromPools
  const tokensFromPools = useMemo(() => {
    const tokenMap = new Map<string, Token>()
    transformedPools?.forEach((pool) => {
      const [token0, token1] = constructTokensDataFromPool(pool)
      tokenMap.set(token0.address, token0)
      tokenMap.set(token1.address, token1)
    })
    return Array.from(tokenMap.values())
  }, [transformedPools])

  // Create wrapped refetch function with better return type
  const refetch = useCallback(async () => {
    return refetchPoolsData()
  }, [refetchPoolsData])

  // Refetch pools data when chain changes
  useEffect(() => {
    refetch()
  }, [chainId, refetch])

  // Create the context value object
  const value = {
    pools: transformedPools,
    poolsDataByAddress,
    tokensFromPools,
    isLoading,
    isError,
    error,
    refetch,
  }

  return <PoolsContext.Provider value={value}>{children}</PoolsContext.Provider>
}

/**
 * Hook to use the pools data
 */
export function usePoolsContextData() {
  const context = useContext(PoolsContext)
  if (context === undefined) {
    throw new Error("usePoolsContextData must be used within a PoolsProvider")
  }
  return context
}

/**
 * Helper function to get pool address in checksummed format
 */
export const getPoolAddress = (poolAddress: string | undefined): string => {
  return _.isUndefined(poolAddress) ? "" : getAddress(poolAddress)
}

/**
 * Helper function to get pool data by address
 */
export const getPoolDataByAddress = (
  poolAddress: string,
  poolsDataByAddress: PoolsDataByAddress,
) => {
  return !_.isEmpty(poolsDataByAddress)
    ? poolsDataByAddress[getPoolAddress(poolAddress)]
    : null
}
