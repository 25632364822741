import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { Token, MintQuote } from "src/types"
import { fetchMintQuote } from "./hooks"

enum FetchState {
  IDLE = "idle",
  LOADING = "loading",
  VALID = "valid",
  INVALID = "invalid",
}

export const EMPTY_MINT_QUOTE: MintQuote = {
  size: 0n,
  debt: 0n,
  margin: 0n,
  safeMarginMinimum: 0n,
  fees: 0n,
  safe: true,
  health: 0n,
  liquidityAfter: 0n,
  sqrtPriceX96After: 0n,
  liquidityLockedAfter: 0n,
}

export interface TradeState {
  inputValue: string
  leverage: number
  tradeToken: Token | null
  marginToken: Token | null
  debtToken: Token | null
  isLong: boolean
  mintQuote: MintQuote
  status: FetchState
  error: any
}

export const initialState: TradeState = {
  inputValue: "",
  leverage: 1.1,
  tradeToken: null,
  marginToken: null,
  debtToken: null,
  isLong: true,
  mintQuote: EMPTY_MINT_QUOTE,
  status: FetchState.IDLE,
  error: null,
}

export const tradeSlice = createSlice({
  name: "trade",
  initialState,
  reducers: {
    setInputValue: (state: TradeState, action: PayloadAction<string>) => {
      state.inputValue = action.payload
    },
    setLeverage: (state: TradeState, action: PayloadAction<number>) => {
      state.leverage = action.payload
    },
    setTradeToken: (state: TradeState, action: PayloadAction<Token | null>) => {
      state.tradeToken = action.payload
    },
    setMarginToken: (state: TradeState, action: PayloadAction<Token | null>) => {
      state.marginToken = action.payload
    },
    setDebtToken: (state: TradeState, action: PayloadAction<Token | null>) => {
      state.debtToken = action.payload
    },
    setIsLong: (state: TradeState, action: PayloadAction<boolean>) => {
      state.isLong = action.payload
    },
    resetMintQuote: (state) => {
      state.mintQuote = EMPTY_MINT_QUOTE
      state.status = FetchState.IDLE
      state.error = null
    },
    resetTradeState: (state) => {
      state.inputValue = ""
      state.leverage = 1.1
      state.mintQuote = EMPTY_MINT_QUOTE
      state.status = FetchState.IDLE
      state.error = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMintQuote.pending, (state) => {
        state.status = FetchState.LOADING
      })
      .addCase(fetchMintQuote.fulfilled, (state, action: PayloadAction<MintQuote>) => {
        state.mintQuote = action.payload
        state.status = FetchState.VALID
        state.error = null
      })
      .addCase(fetchMintQuote.rejected, (state, action) => {
        state.mintQuote = EMPTY_MINT_QUOTE
        state.status = FetchState.INVALID
        state.error = action.payload
      })
  },
})

export const {
  setInputValue,
  setLeverage,
  setTradeToken,
  setMarginToken,
  setDebtToken,
  setIsLong,
  resetMintQuote,
  resetTradeState,
} = tradeSlice.actions

export default tradeSlice.reducer
