import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  ReactNode,
  useMemo,
} from "react"
import { useAccount } from "wagmi"
import { useApplicationState } from "src/state/application/hooks"
import { useGetPositionsQuery, GetPositionsQuery } from "src/state/api/generated"
import { getTokenByAddress } from "src/constants/tokenList"
import { formatBigIntToString } from "src/utils/formatBigIntToString"
import { formatNumberAmount } from "src/utils/formatNumberAmount"

interface TransactionDetails {
  transactionType: string
  amount: string
  timestamp: string
  hash: string
}

interface PositionsContextType {
  positions: { [tokenId: string]: GetPositionsQuery["positions"][0] } | undefined
  isLoading: boolean
  isError: boolean
  error: any
  refetch: () => Promise<any>
  getPosition: (positionKey: string) => {
    position: GetPositionsQuery["positions"][0] | undefined
    isLoading: boolean
    isError: boolean
  }
  getPositionTransactionHistory: (
    positionKey: string,
    chainId?: number,
  ) => {
    transactions: TransactionDetails[]
    isLoading: boolean
    isError: boolean
  }
}

const PositionsContext = createContext<PositionsContextType>({} as PositionsContextType)

interface PositionsProviderProps {
  children: ReactNode
}

export function PositionsProvider({ children }: PositionsProviderProps) {
  const { address } = useAccount()
  const { chainId } = useApplicationState()

  // Fetch positions data
  const {
    data: positionsData,
    refetch: refetchPositionsData,
    isLoading,
    isError,
    error,
  } = useGetPositionsQuery(
    { address: address?.toLowerCase() ?? "" },
    {
      skip: !address,
      refetchOnMountOrArgChange: true,
      // Poll every 30 seconds
      pollingInterval: 30 * 1000,
    },
  )

  // Convert positions array to map by tokenId
  const positionsMap = useMemo(() => {
    if (!positionsData?.positions) return undefined
    return positionsData.positions.reduce<{
      [tokenId: string]: GetPositionsQuery["positions"][0]
    }>((acc, position) => {
      if (position.tokenId) {
        acc[position.tokenId] = position
      }
      return acc
    }, {})
  }, [positionsData?.positions])

  const refetch = useCallback(async () => {
    return refetchPositionsData()
  }, [refetchPositionsData])

  // Refetch positions data when chain changes
  useEffect(() => {
    if (address) {
      refetch()
    }
  }, [chainId, refetch, address])

  // Method to get a single position from the already fetched data
  const getPosition = useCallback(
    (positionKey: string) => {
      // First try direct lookup by tokenId
      let foundPosition = positionsMap?.[positionKey]

      // If not found, try to find the position where the id matches the positionKey
      // which could be in the format of "poolAddress-positionId"
      if (!foundPosition && positionsMap) {
        foundPosition = Object.values(positionsMap).find((pos) => pos.id === positionKey)
      }

      return {
        position: foundPosition,
        isLoading,
        isError,
      }
    },
    [positionsMap, isLoading, isError],
  )

  // Method to get and format transaction history for a specific position
  const getPositionTransactionHistory = useCallback(
    (positionKey: string, chainId?: number) => {
      const { position } = getPosition(positionKey)
      const formattedTransactions: TransactionDetails[] = []

      if (position && chainId) {
        const token0 =
          getTokenByAddress(position.pool?.token0?.address, chainId) ??
          position.pool?.token0
        const token1 =
          getTokenByAddress(position.pool?.token1?.address, chainId) ??
          position.pool?.token1
        const [marginToken] = position.zeroForOne ? [token1, token0] : [token0, token1]

        // Create a copy of the array before sorting to avoid modifying the readonly array
        const sortedTransactions = [...position.transactions].sort(
          (a, b) => parseInt(b.timestamp) - parseInt(a.timestamp),
        )

        formattedTransactions.push(
          ...sortedTransactions.map((tx) => {
            let transactionType = ""
            let amount = ""

            if (tx.type === "MINT" && tx.mint) {
              transactionType = "Open position"
              amount = `${formatNumberAmount(
                formatBigIntToString(tx.mint.margin, marginToken.decimals),
                true,
              )} ${marginToken.symbol}`
            } else if (tx.type === "FREE" && tx.free) {
              transactionType = "Remove margin"
              const marginDiff =
                BigInt(tx.free.marginBefore) - BigInt(tx.free.marginAfter)
              amount = `${formatNumberAmount(
                formatBigIntToString(marginDiff, marginToken.decimals),
                true,
              )} ${marginToken.symbol}`
            } else if (tx.type === "LOCK" && tx.lock) {
              transactionType = "Add margin"
              const marginDiff =
                BigInt(tx.lock.marginAfter) - BigInt(tx.lock.marginBefore)
              amount = `${formatNumberAmount(
                formatBigIntToString(marginDiff, marginToken.decimals),
                true,
              )} ${marginToken.symbol}`
            } else if (tx.type === "IGNITE" && tx.ignite) {
              transactionType = "Close position"
              amount = `${formatNumberAmount(
                formatBigIntToString(tx.ignite.amountOut, marginToken.decimals),
                true,
              )} ${marginToken.symbol}`
            }

            return {
              transactionType,
              amount,
              timestamp: tx.timestamp,
              hash: tx.id,
            }
          }),
        )
      }

      return {
        transactions: formattedTransactions,
        isLoading,
        isError,
      }
    },
    [getPosition, isLoading, isError],
  )

  const value = {
    positions: address ? positionsMap : undefined,
    isLoading,
    isError,
    error,
    refetch,
    getPosition,
    getPositionTransactionHistory,
  }

  return <PositionsContext.Provider value={value}>{children}</PositionsContext.Provider>
}

/**
 * Hook to use the positions data
 */
export function usePositionsData() {
  const context = useContext(PositionsContext)
  if (context === undefined) {
    throw new Error("usePositionsData must be used within a PositionsProvider")
  }
  return context
}
