import { AmountInput } from "src/components/ui/AmountInput"

export const TradeInput = ({
  title,
  value,
  onChange,
}: {
  title: string
  value: string
  onChange: (value: string) => void
}) => {
  return (
    <div className="flex-1 space-y-1">
      <label htmlFor="trade-input" className="text-sm font-bold text-textGray">
        {title}
      </label>
      <AmountInput value={value} onChange={onChange} />
    </div>
  )
}
