import { useAccount } from "wagmi"
import { useConnectModal, useChainModal } from "@rainbow-me/rainbowkit"
import { Token } from "src/types"
import { isSupportedChain } from "src/constants/chains/chains"
import { ActionButton } from "src/components/ui/ActionButton"

interface Props {
  chainId: number | undefined
  marginToken: Token | null
  isInputValid: boolean
  isTokensValid: boolean
  isApproved: boolean
  isBalanceSufficient: boolean
  isPendingWallet: boolean
  isPendingApprove: boolean
  onApprove: () => Promise<void>
  onConfirm: () => void
  mintQuote: any
  mintCallback: (() => Promise<any>) | undefined
  error: any
}

export const ConfirmTradeButton = ({
  chainId,
  marginToken,
  isInputValid,
  isTokensValid,
  isApproved,
  isBalanceSufficient,
  isPendingWallet,
  isPendingApprove,
  onApprove,
  onConfirm,
  mintQuote,
  mintCallback,
  error,
}: Props) => {
  const { address, chainId: connectedChainId } = useAccount()
  const { openConnectModal } = useConnectModal()
  const { openChainModal } = useChainModal()
  const isValidNetwork = isSupportedChain(connectedChainId ?? 0)

  if (!address) {
    return (
      <ActionButton action="Connect wallet" textSize="lg" onClick={openConnectModal} />
    )
  }
  if (!isValidNetwork) {
    return <ActionButton action="Switch network" textSize="lg" onClick={openChainModal} />
  }
  if (error) {
    return <ActionButton action={error} textSize="lg" disabled={true} />
  }

  if (isPendingWallet) {
    return <ActionButton action="Confirm in wallet" textSize="lg" disabled />
  }

  if (isPendingApprove) {
    return <ActionButton action="Approving..." textSize="lg" disabled={true} />
  }

  if (!isInputValid) {
    return <ActionButton action="Enter amount" textSize="lg" disabled={true} />
  }

  if (!isTokensValid) {
    return <ActionButton action="Select token" textSize="lg" disabled={true} />
  }

  if (!isBalanceSufficient) {
    return <ActionButton action="Insufficient balance" textSize="lg" disabled={true} />
  }

  if (!isApproved) {
    return (
      <ActionButton
        action={`Approve ${marginToken?.symbol} token`}
        onClick={onApprove && (() => onApprove())}
        disabled={!onApprove}
        textSize="lg"
      />
    )
  }

  return (
    <ActionButton
      action="Trade"
      textSize="lg"
      onClick={mintCallback && (() => onConfirm())}
      disabled={!mintQuote}
    />
  )
}
