import { ComponentProps } from "react"

export const MarginalIcon = (props: ComponentProps<"svg">) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 219 100"
      fill="none"
      overflow="visible"
      {...props}
    >
      <g filter="url(#filter0_dd_1671_414)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M47.6644 41.4473V7.72941L69.8397 41.4473H82.9945L55.3938 0H36.0703V41.4473H47.6644ZM47.6644 47.0195H36.0703V57.3253H47.6644V47.0195ZM47.6644 62.8975H36.0703V92.753H47.6644V62.8975ZM83.9469 62.8975H97.2787L109.439 81.1588L121.6 62.8975H134.928L115.297 92.753H103.582L83.9469 62.8975ZM170.369 62.8975V92.753H181.963V62.8975H170.369ZM181.963 57.3253H170.369V47.0195H181.963V57.3253ZM181.963 41.4473V0H163.485L135.884 41.4473H149.032L170.369 8.99752V41.4473H181.963ZM145.368 47.0195L138.592 57.3253H125.311L132.174 47.0195H145.368ZM86.7052 47.0195H73.5043L80.2822 57.3253H93.5681L86.7052 47.0195Z"
        />
      </g>
      <path d="M219 47.0195V57.3254H0V47.0195H219Z" />
      <defs>
        <filter
          id="filter0_dd_1671_414"
          x="2.10166"
          y="0"
          width="213.83"
          height="160.69"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="6.79373"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_1671_414"
          />
          <feOffset dy="13.5875" />
          <feGaussianBlur stdDeviation="6.79373" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1671_414"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="6.79373"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_1671_414"
          />
          <feOffset dy="33.9687" />
          <feGaussianBlur stdDeviation="20.3812" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_1671_414"
            result="effect2_dropShadow_1671_414"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_1671_414"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  )
}
