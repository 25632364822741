import _ from "lodash"
import { Address, maxUint256 } from "viem"
import { Link, useParams } from "react-router-dom"
import { InputContainer } from "src/components/ui/InputContainer"
import { useNavigateRoutes } from "src/hooks/useNavigateRoutes"
import { usePoolsContextData, getPoolDataByAddress } from "src/providers/PoolsProvider"
import { useAccount } from "wagmi"
import { ListRow } from "src/components/ui/ListRow"
import { useErc20TokenSymbol } from "src/hooks/useErc20TokenSymbol"
import { Card } from "src/components/ui/Card"
import { useNetworkChangeRedirect } from "src/hooks/useNetworkChangeRedirect"
import { StakeInput } from "src/pages/stake/components/StakeInput"
import { useUnstakeActionHandlers, useUnstakeState } from "src/state/unstake/hook"
import { useEffect, useMemo, useState } from "react"
import { isBlank } from "src/utils/isBlank"
import { formatStringToBigInt } from "src/utils/formatStringToBigInt"
import { useErc20TokenAllowance } from "src/hooks/useErc20TokenAllowance"
import { isOnlyZeroes } from "src/utils/isOnlyZeroes"
import { approveErc20Token } from "src/utils/actions/approveErc20Token"
import { waitForTransactionReceipt } from "@wagmi/core"
import { wagmiConfig } from "src/wagmiConfig"
import { PercentRangeSlider } from "src/components/PercentRangeSlider"
import { useTransactionModal } from "src/hooks/useTransactionModal"
import { useUserStakedPoolBalance } from "src/pages/pool/hooks/useUserStakedPoolBalance"
import { formatNumberAmount } from "src/utils/formatNumberAmount"
import { type PoolData, type Token } from "src/types"
import { convertMaintenanceToLeverage } from "src/utils/conversions/convertMaintenanceToLeverage"
import { useApplicationState } from "src/state/application/hooks"
import { DoubleTokenLogo, TokenLogo } from "src/components/TokenBadge"
import { getTokenByAddress } from "src/constants/tokenList"
import { isTransactionReceiptError } from "src/utils/isTransactionReceiptError"
import { toast } from "react-toastify"
import { Toast } from "src/components/ui/Toast"
import { shortenAddress } from "src/utils/shortenAddress"
import {
  ArrowLeftIcon,
  ArrowTopRightOnSquareIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  ShieldCheckIcon,
} from "@heroicons/react/24/outline"
import { ExplorerDataType, getExplorerLink } from "src/utils/getExplorerLink"
import MarginalLogo from "src/assets/logos/marginal.svg"
import { ArrowOutgoingIcon } from "src/components/Icons/ArrowOutgoingIcon"
import { ConfirmUnstakeButton } from "./ConfirmUnstakeButton"
import { trimTrailingZeroes } from "src/utils/trimTrailingZeroes"
import { unstake } from "src/utils/actions/unstake"

const Unstake = () => {
  const { chainId } = useApplicationState()
  const { address } = useAccount()
  const { poolAddress } = useParams()
  const { poolsDataByAddress } = usePoolsContextData()
  const { unstakePool, unstakeTokenAddress, inputValue } = useUnstakeState()
  const { onUserInput, onResetUnstakeState } = useUnstakeActionHandlers()

  const { onNavigateToPool, onNavigateToPools } = useNavigateRoutes()
  const handleReturnToPool = (poolAddress: string) => onNavigateToPool(poolAddress)
  useNetworkChangeRedirect(onNavigateToPools)

  const pool = getPoolDataByAddress(poolAddress as string, poolsDataByAddress)
  const lpTokenSymbol = useErc20TokenSymbol(pool?.poolAddress as Address)
  const { balances: userStakedBalance, refetch: refetchStakedBalance } =
    useUserStakedPoolBalance([pool as PoolData], address)
  const userStakedLpBalance = userStakedBalance[pool?.stakePool as Address]
  const {
    token0: poolToken0,
    token1: poolToken1,
    maintenance,
    decimals: poolDecimals,
  } = (pool as PoolData) || {}
  const token0 = getTokenByAddress(poolToken0?.address, chainId) ?? poolToken0
  const token1 = getTokenByAddress(poolToken1?.address, chainId) ?? poolToken1

  const [percentage, setPercentage] = useState<number>(0)
  const [percentError, setPercentError] = useState(false)
  const leverageMax = convertMaintenanceToLeverage(maintenance)

  const formattedInput = !isBlank(inputValue)
    ? formatStringToBigInt(inputValue, poolDecimals)
    : 0n

  const { allowance, fetchAllowance } = useErc20TokenAllowance(
    pool?.poolAddress as Address,
    Number(pool?.decimals),
    address,
    pool?.stakePool as Address,
    chainId,
  )

  useEffect(() => {
    if (unstakeTokenAddress && address) {
      if (!isOnlyZeroes(inputValue)) {
        ;(async () => {
          await fetchAllowance()
        })()
      }
    }
  }, [chainId, unstakeTokenAddress, address, fetchAllowance, inputValue])

  useEffect(() => {
    onResetUnstakeState()
  }, [chainId, onResetUnstakeState])

  const isApproved = useMemo(() => {
    if (_.isUndefined(allowance) || _.isUndefined(formattedInput)) return true
    return allowance >= formattedInput
  }, [allowance, formattedInput])

  const isBalanceSufficient = useMemo(() => {
    if (_.isUndefined(userStakedLpBalance?.balance)) return false
    if (_.isUndefined(formattedInput)) return true

    return userStakedLpBalance?.balance >= formattedInput
  }, [userStakedLpBalance, formattedInput])

  const {
    state: { isPendingWallet, isPendingApprove },
    openConfirmModal,
    resetState: resetTransactionState,
    setPendingWallet,
    setPendingApprove,
    setTxSubmitted,
  } = useTransactionModal()

  const onSuccessReset = () => {
    resetTransactionState()
    onResetUnstakeState()
    setPercentage(0)
  }

  const approveToken = async (
    amount: bigint | undefined,
    spenderAddress: Address,
    tokenAddress: Address,
  ) => {
    if (!amount) {
      throw new Error("Require amount to approve")
    }
    if (!spenderAddress) {
      throw new Error("Require spender address to approve")
    }
    if (!tokenAddress) {
      throw new Error("Require token address to approve")
    }

    try {
      openConfirmModal()
      setPendingWallet(true)
      const txHash = await approveErc20Token({
        chainId,
        amount: maxUint256,
        spenderAddress,
        tokenAddress,
      })
      setPendingApprove(true)
      await waitForTransactionReceipt(wagmiConfig, {
        hash: txHash,
      })
      setPendingApprove(false)
      setTxSubmitted(true, txHash)
      fetchAllowance()
    } catch (error) {
      console.error("Error approving token: ", error)
      if (isTransactionReceiptError(error)) {
        resetTransactionState()
        fetchAllowance()
      } else {
        toast(
          <Toast type="error" header="Transaction Error">
            <p className="text-sm text-marginalGray-600">
              There was an issue with your transaction.
            </p>
          </Toast>,
        )
      }
    } finally {
      setPendingApprove(false)
      setPendingWallet(false)
    }
  }

  const handleUnstakeCallback = async () => {
    try {
      if (!isApproved) {
        return new Error("Unstake not approved")
      }
      if (!pool?.stakePool || !formattedInput) {
        return new Error("Missing required parameters for unstake")
      }
      setPendingWallet(true)
      const transaction = await unstake(
        pool?.stakePool as Address,
        formattedInput,
        chainId,
      )
      setPendingWallet(false)
      setTxSubmitted(true, transaction.transactionHash)
      onSuccessReset()
      refetchStakedBalance()
      toast(
        <Toast type="success" header={`Unstake Success`}>
          <p className="text-sm text-marginalGray-600">Unstaked {inputValue} LP</p>
          <Link
            className="flex w-fit items-center justify-center text-sm text-marginalGray-200 hover:underline focus:outline-none"
            to={getExplorerLink(
              chainId,
              transaction.transactionHash,
              ExplorerDataType.TRANSACTION,
            )}
            target="_blank"
          >
            View on explorer
            <ArrowOutgoingIcon className="ml-2" />
          </Link>
        </Toast>,
        {
          autoClose: 10000,
        },
      )

      return transaction.transactionHash
    } catch (error) {
      console.error("Error executing unstake: ", error)
      toast(
        <Toast type="error" header="Transaction Error">
          <p className="text-sm text-marginalGray-600">
            There was an issue with your transaction.
          </p>
        </Toast>,
      )
    } finally {
      resetTransactionState()
      onSuccessReset()
    }
  }

  const handleApplyMaxBalance = () => {
    if (!_.isUndefined(userStakedLpBalance)) {
      const maxBalance = trimTrailingZeroes(userStakedLpBalance?.parsedBalance)

      if (!_.isUndefined(maxBalance)) {
        onUserInput(maxBalance)
      }
    }
  }

  return (
    <Card>
      <div className="m-auto flex max-w-7xl">
        <div className="flex flex-col justify-between space-y-4">
          <div className="flex items-center gap-4">
            <span>
              <button
                onClick={() => handleReturnToPool(pool?.poolAddress as Address)}
                className="flex items-center gap-2 rounded border border-marginalGray-800 p-3 px-2.5 py-2"
              >
                <ArrowLeftIcon width={16} />
                <span className="whitespace-nowrap text-marginalGray-200">Go Back</span>
              </button>
            </span>
            <div className="flex flex-wrap items-center gap-2">
              <span className="flex items-center gap-2 font-bold text-white">
                <img
                  src={MarginalLogo}
                  alt="Marginal Logo"
                  width={24}
                  height={24}
                  className=""
                />
                <span>Liquidity Pools</span>
              </span>
              <span className="rounded-full border border-marginalOrange-500 bg-marginalOrange-500 px-2 py-1 text-xs text-white">
                Version 1
              </span>
            </div>
          </div>
          <div className="flex flex-wrap gap-4 md:gap-8">
            <div className="flex">
              <div className="flex items-center space-x-2 text-2xl font-bold text-white">
                <DoubleTokenLogo
                  token0={token0 as Token}
                  token1={token1 as Token}
                  size={8}
                />
                <div>
                  <div>
                    {token0?.symbol}/{token1?.symbol}
                  </div>
                  <a
                    href={
                      getExplorerLink(
                        chainId,
                        poolAddress as string,
                        ExplorerDataType.CONTRACT,
                      ) ?? "#"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center gap-1 text-xs text-marginalGray-600 hover:text-marginalOrange-500"
                  >
                    <span>View contract</span>
                    <ArrowTopRightOnSquareIcon className="h-3 w-3" />
                  </a>
                </div>
              </div>
            </div>
            <div className="w-fit">
              <div className="flex flex-wrap gap-4">
                <section className="hidden divide-x divide-marginalGray-600 rounded bg-marginalGray-900 md:flex">
                  <div>
                    <div className="flex items-center gap-2 p-3">
                      <TokenLogo
                        symbol={token0?.symbol}
                        imgUrl={token0?.imgUrl}
                        size={7}
                      />
                      <div>
                        <div className="flex items-center space-x-3">
                          <div className="text-xs text-marginalGray-200">
                            {token0?.symbol}
                          </div>
                          <div className="flex items-center space-x-1 rounded-full border border-marginalGray-600 px-1">
                            <button
                              onClick={() => {
                                if (token0?.address) {
                                  navigator.clipboard.writeText(token0.address)
                                  // Optional: You could add a toast notification here
                                }
                              }}
                              className="flex cursor-pointer items-center gap-1 transition-colors hover:text-marginalOrange-500"
                            >
                              <span className="text-xs text-marginalGray-200">
                                {shortenAddress(token0?.address)}
                              </span>
                              <DocumentDuplicateIcon className="h-3 w-3" />
                            </button>
                          </div>
                        </div>
                        <div className="text-sm font-bold text-white">{token0?.name}</div>
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-2 p-3">
                    <TokenLogo symbol={token1?.symbol} imgUrl={token1?.imgUrl} size={7} />
                    <div>
                      <div className="flex items-center space-x-3">
                        <div className="text-xs text-marginalGray-200">
                          {token1?.symbol}
                        </div>
                        <div className="flex items-center space-x-1 rounded-full border border-marginalGray-600 px-1">
                          <button
                            onClick={() => {
                              if (token1?.address) {
                                navigator.clipboard.writeText(token1.address)
                                // Optional: You could add a toast notification here
                              }
                            }}
                            className="flex cursor-pointer items-center gap-1 transition-colors hover:text-marginalOrange-500"
                          >
                            <span className="text-xs text-marginalGray-200">
                              {shortenAddress(token1?.address)}
                            </span>
                            <DocumentDuplicateIcon className="h-3 w-3" />
                          </button>
                        </div>
                      </div>
                      <div className="text-sm font-bold text-white">{token1?.name}</div>
                    </div>
                  </div>
                </section>

                <section className="flex flex-col justify-center space-y-2 rounded bg-marginalGray-900 p-2">
                  <div className="flex items-center space-x-1">
                    <DocumentTextIcon className="h-3 w-3" />
                    <span className="text-xs">Contract: </span>
                    <button
                      onClick={() => {
                        if (poolAddress) {
                          navigator.clipboard.writeText(poolAddress)
                          // Optional: You could add a toast notification here
                        }
                      }}
                      className="flex cursor-pointer items-center gap-1 text-xs transition-colors hover:text-marginalOrange-500"
                    >
                      {shortenAddress(poolAddress)}
                      <DocumentDuplicateIcon className="h-3 w-3" />
                    </button>
                  </div>

                  <div className="flex items-center space-x-1">
                    <ShieldCheckIcon className="h-3 w-3" />
                    <span className="text-xs">Security audit:</span>
                    <Link
                      to="https://github.com/MarginalProtocol/v1-core/blob/main/audits/spearbit.pdf"
                      target="_blank"
                      className="flex items-center gap-1 text-xs hover:text-marginalOrange-500"
                    >
                      Spearbit Review
                      <ArrowTopRightOnSquareIcon className="h-3 w-3" />
                    </Link>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative mx-auto mt-12 w-full shadow-outerBlack sm:max-w-lg">
        <div className="rounded-t-3xl border border-marginalGray-800 bg-marginalGray-900">
          <div className="flex items-center justify-between p-4">
            <div className="relative text-lg text-marginalGray-200 md:text-xl">
              Unstake
            </div>
          </div>

          <div className="px-4 pb-4 pt-0">
            <div className="rounded-xl border border-marginalGray-800 bg-marginalGray-950 p-4">
              <div className="flex items-center space-x-2">
                <DoubleTokenLogo
                  token0={token0 as Token}
                  token1={token1 as Token}
                  size={8}
                />

                <div className="flex flex-col overflow-x-hidden">
                  <div className="flex items-center space-x-1 text-lg text-marginalGray-200">
                    <pre>{token0?.symbol}</pre>
                    <div className="my-auto">/</div>
                    <pre>{token1?.symbol}</pre>
                    <div className="ml-3">{leverageMax}x</div>
                  </div>

                  <div className="flex flex-nowrap items-center text-xs text-marginalGray-600 md:text-sm">
                    <pre>{token0?.name}</pre>

                    <div className="my-auto px-0.5">∙</div>
                    <pre>{token1?.name}</pre>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="space-y-2 rounded-b-3xl border border-t-0 border-marginalGray-800 bg-marginalGray-900 p-4">
            <InputContainer id="add-liquidity-input-a-container">
              <div className="flex items-center space-x-1">
                <StakeInput inputValue={inputValue} onChange={onUserInput} />
              </div>

              <div className="flex space-x-2 text-sm">
                <div
                  className="cursor-pointer rounded-sm bg-[#4C2D1E] px-0.5 text-marginalOrange-500"
                  onClick={handleApplyMaxBalance}
                >
                  Max
                </div>
              </div>
            </InputContainer>

            <PercentRangeSlider
              userInput={inputValue}
              isInputValid={true}
              formattedBalance={userStakedLpBalance?.parsedBalance}
              margin={userStakedLpBalance?.balance}
              decimals={Number(poolDecimals)}
              onUserInput={onUserInput}
              selectedPercentage={percentage}
              setPercentage={setPercentage}
              setPercentError={setPercentError}
              percentError={percentError}
            />

            <div className="space-y-2 py-2 text-sm text-marginalGray-600">
              <ListRow
                item="Staked balance"
                value={
                  <div className="flex items-baseline space-x-1 text-marginalGray-200">
                    <span>
                      {userStakedLpBalance
                        ? formatNumberAmount(userStakedLpBalance?.parsedBalance, true)
                        : "0.0"}
                    </span>
                    <span>{lpTokenSymbol}</span>
                  </div>
                }
              />
            </div>
            <ConfirmUnstakeButton
              chainId={chainId}
              isInputValid={!_.isUndefined(formattedInput) && formattedInput !== 0n}
              isTokenValid={true}
              isTokenApproved={isApproved}
              isBalanceSufficient={isBalanceSufficient}
              isPendingWallet={isPendingWallet}
              isPendingApprove={isPendingApprove}
              tokenSymbol={lpTokenSymbol}
              onApproveToken={() =>
                approveToken(
                  formattedInput,
                  unstakePool?.stakePool as Address,
                  unstakeTokenAddress as Address,
                )
              }
              onConfirm={handleUnstakeCallback}
              unstakeCallback={handleUnstakeCallback}
              error={null}
            />
          </div>
        </div>
      </div>

      {/* <TransactionProgressModal
        chainId={chainId}
        open={showConfirm}
        onOpen={handleOpenConfirmModal}
        onClose={handleCloseConfirmModal}
        onReset={onSuccessReset}
        onCallback={handleUnstakeCallback}
        isPendingWallet={isPendingWallet}
        isPendingApprove={isPendingApprove}
        isPendingTx={isPendingTx}
        isTxSubmitted={isTxSubmitted}
        txHash={txHash}
        txError={txError}
        hasConfirmModal={false}
        onSuccessText="Unstaking Success"
      /> */}
    </Card>
  )
}

export default Unstake
